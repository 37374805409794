import {
  type PropsWithChildren,
  createContext,
  useRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { create } from "zustand";
import { variableSchema } from "../../../lib/api-validators";
import { clusterSchema } from "../../../lib/api-schema/cluster";
import { AnomalyLevelEnum } from "../../../types/api/Anomaly";
import { DriView, views } from "../constants";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { subscribeWithSelector } from "zustand/middleware";

export type ClustersState = Record<string, clusterSchema>;
export type VariablesState = Record<string, variableSchema>;

export type DriStore = {
  // clusters: ClustersState | undefined;
  // variables: VariablesState | undefined;
  viewMode: DriView;
  setViewMode: (view: DriView) => void;

  showAnomalies: boolean;
  setShowAnomalies: (show: boolean) => void;
  globalShutdownToggle: boolean;
  setGlobalShutdownToggle: (_: boolean) => void;
  globalModeTransparencyToggle: boolean;
  setGlobalModeTransparencyToggle: (_: boolean) => void;

  showAllMode: boolean;

  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;

  listOpen: {
    watch: boolean;
  } & Record<AnomalyLevelEnum, boolean>;
};

export function getViewPreference(): DriView {
  const view = localStorage.getItem("dri-view");

  const parsed = DriView.safeParse(view);

  if (parsed.success) return parsed.data;
  return views.plain;
}

function setViewPreference(view: (typeof views)[keyof typeof views]) {
  localStorage.setItem("dri-view", view);
}

export function createUseDriStore() {
  const useDriStore = create<DriStore>()(
    subscribeWithSelector((set, get) => {
      return {
        showAnomalies: true,
        setShowAnomalies: (show) => {
          set({ showAnomalies: show });
        },
        globalShutdownToggle: false,
        setGlobalShutdownToggle: (globalShutdownToggle) => {
          set({ globalShutdownToggle });
        },
        globalModeTransparencyToggle: true,
        setGlobalModeTransparencyToggle: (globalModeTransparencyToggle) => {
          set({ globalModeTransparencyToggle });
        },
        sidebarOpen: true,
        setSidebarOpen: (open) => {
          set({ sidebarOpen: open });
        },
        viewMode: getViewPreference(),
        setViewMode(view) {
          setViewPreference(view);
          set({ viewMode: view });
        },
        showAllMode: false,
        listOpen: {
          watch: true,
          0: true,
          1: true,
          2: true,
          3: true,
        },
      };
    })
  );

  return useDriStore;
}

export type UseDriStore = ReturnType<typeof createUseDriStore>;

const DriStoreContext = createContext<UseDriStore | undefined>(undefined);

/**
 * Wrap the whole DRI page in this provider.
 */
export const DriStoreProvider = ({ children }: PropsWithChildren) => {
  const [useDriStore] = useState(() => createUseDriStore());
  const currentUnit = useCurrentUnitObject();

  return (
    <DriStoreContext.Provider key={currentUnit?.url} value={useDriStore}>
      {children}
    </DriStoreContext.Provider>
  );
};

export function useGetUseDriStore() {
  const useDriStore = useContext(DriStoreContext);

  if (!useDriStore) throw new Error("Have you wrapped with DriStoreProvider?");

  return useDriStore;
}

export function useGetUseDriStoreNotRequired() {
  return useContext(DriStoreContext);
}
