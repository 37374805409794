import Comment from "../../../../types/api/Comment";
import * as CommentComponents from "./common";
import { DateTime } from "luxon";
import { useUserRequired } from "../../../../zustand/auth/useAuthStore";
import { Button } from "../../../../shared-ui/frontend/button";
import { Pencil, Trash2, User, XCircle } from "lucide-react";
import { cn, type PropsWithCn } from "../../../../shared-ui/frontend/cn";
import { useAtomValue, useSetAtom } from "jotai";
import { Atoms } from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import React, { ComponentProps, useState } from "react";
import { EditCommentForm } from "./edit-comment-form";
import { Comments } from "../../../../hooks/tanstack-query";
import { useTimezone } from "../../../../zustand/config/useConfigStore";
import { useSearchParams } from "react-router-dom";
import { DRAParamsMap } from "../../../boundaries/hooks/useDRAParams2";
import { FaHashtag, FaTag } from "react-icons/fa";
import { Badge } from "../../../../shared-ui/frontend/badge";
import { getFullName } from "../../../../shared-ui/lib/user-utils";
import { GenericDeleteTriggerWithConfirmationPopup } from "../../../../shared-ui/frontend/generic-delete-confirm";
import { useMutation } from "@tanstack/react-query";
import { CommentEndpoints } from "../../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../../zustand/useBaseUrl";
import { addUnknownErrorToast } from "../../../toast/use-toast-store";
import { TimeFormat } from "../../../../shared-ui/lib/date-utils";
import { iife } from "../../../../lib/utils";
import { CreateCommentReplyForm } from "./create-reply-form";

function CommentThread({
  commentId,
  noPortalMultiSelects,
}: {
  commentId: string;
  noPortalMultiSelects?: true;
}) {
  const cQuery = Comments.single.useQuery(commentId);

  if (cQuery.isLoading) return <div>Loading...</div>;

  const c = cQuery.data;

  if (!c) return <div>Comment not found</div>;

  return (
    <div
      className={cn(
        c.type === "Issue" && !c.issue_resolved && "bg-xred-2",
        c.private && "bg-xamber-2"
      )}
    >
      <SingleComment
        comment={c}
        closeThread
        noPortalMultiSelects={noPortalMultiSelects}
      />
      {c.replies.map((r) => (
        <SingleComment
          key={r._id}
          comment={r}
          noPortalMultiSelects={noPortalMultiSelects}
        />
      ))}
      <CreateCommentReplyForm
        parentCommentId={commentId}
        noPortalMultiSelects={noPortalMultiSelects}
      />
    </div>
  );
}

function SingleComment({
  comment,
  closeThread,
  noPortalMultiSelects,
}: {
  comment: Comment;
  closeThread?: boolean;
  noPortalMultiSelects?: true;
}) {
  const showCommentDomain = comment.is_root;

  const selectedCommentId = useAtomValue(Atoms.selectedCommentIdAtom);

  const { text, author, updated_at } = comment;
  const [isEditing, setEditing] = useState(false);

  const [tryingToDelete, setTryingToDelete] = useState(false);

  const zone = useTimezone();

  const b = useBaseUrlExperimental();

  const invalidateCommentsList = Comments.list.useInvalidate();
  const invalidateSingleCommentQueries = Comments.single.useInvalidate();

  const close = useCloseThread();

  const deleteMut = useMutation({
    mutationFn: () => CommentEndpoints.delete(b, comment._id),
    onSuccess: () => {
      /**
       * If the thread we're currently showing in the chart
       * is the one that's being deleted, close it upon
       * success
       */
      const deletedThisThread = selectedCommentId === comment._id;

      deletedThisThread && close();

      invalidateCommentsList();
      invalidateSingleCommentQueries();
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
  });

  const setBrushStore = useSetAtom(Atoms.brushStoreAtom);

  if (isEditing)
    return (
      <EditCommentForm
        comment={comment}
        close={() => {
          setBrushStore(undefined);
          setEditing(false);
        }}
        noPortalMultiSelects={noPortalMultiSelects}
      />
    );

  const domainLabel = iife(() => {
    if (!showCommentDomain) return;

    const toUtcLabel = (s: string) =>
      DateTime.fromISO(s, {
        zone: "utc",
      }).toFormat("dd-LLL-yyyy hh:mm a" satisfies TimeFormat);

    const start = toUtcLabel(comment.start_date);

    const end =
      comment.type === "Issue" && !comment.issue_resolved
        ? "Ongoing"
        : toUtcLabel(comment.end_date);

    return (
      <span className="text-xs text-zinc-400">
        {start} — {end}
      </span>
    );
  });

  return (
    <div className="flex flex-col border-t border-xslate-6 p-4 animate-in slide-in-from-top-10">
      <div className="flex gap-2">
        <CommentComponents.Avatar user={author} />
        <div className="inline-flex flex-col">
          <CommentComponents.UserName user={author} />
          <span className="text-xs text-zinc-400">
            {DateTime.fromISO(updated_at, { zone }).toFormat(
              "dd-LLL-yyyy hh:mm a" satisfies TimeFormat
            )}
          </span>
        </div>

        <div className="ml-auto inline-flex flex-col items-end gap-0.5">
          <div className="inline-flex gap-0.5">
            <EditButton
              comment={comment}
              onClick={() => {
                // only allow range editing if root
                comment.is_root &&
                  setBrushStore({
                    range: [
                      DateTime.fromISO(comment.start_date, { zone: "utc" })
                        .setZone(zone, { keepLocalTime: true })
                        .toMillis(),
                      comment.type === "Issue" && !comment.issue_resolved
                        ? undefined
                        : DateTime.fromISO(comment.end_date, { zone: "utc" })
                            .setZone(zone, { keepLocalTime: true })
                            .toMillis(),
                    ],
                    mode: "comment-edit",
                  });
                setEditing(true);
              }}
            />
            <TrashButton
              comment={comment}
              disabled={deleteMut.isLoading}
              onClick={() => setTryingToDelete(true)}
            />
            {closeThread && <CloseThreadButton />}
          </div>
          {domainLabel}
        </div>
      </div>

      <div className="inline-flex flex-col gap-1 p-2">
        <p className="my-3">{<LinkifyText text={text} />}</p>
        <TaggedVariablesPills comment={comment} />
        <TaggedUsersPills comment={comment} />
        <TaggedLabelsPills comment={comment} />
      </div>

      <div className="ml-auto inline-flex flex-col items-end gap-2">
        {comment.type === "Issue" && (
          <Badge
            size="xs"
            variant={comment.issue_resolved ? "outline" : "danger"}
          >
            {comment.issue_resolved ? "Closed" : "Open"} Issue
          </Badge>
        )}
      </div>
      <GenericDeleteTriggerWithConfirmationPopup
        open={tryingToDelete}
        confirmDisabled={deleteMut.isLoading}
        onConfirm={() => {
          setTryingToDelete(false);
          deleteMut.mutate();
        }}
        title={"Are you sure you want to delete this comment?"}
        description={`${getFullName(comment.author)}: ${comment.text}`}
        onOpenChange={(open) => {
          if (!open) setTryingToDelete(false);
        }}
      />
    </div>
  );
}

function TaggedVariablesPills({ comment }: { comment: Comment }) {
  const shouldShow = comment.variables.length > 1;

  if (!shouldShow) return;

  return (
    <div className="flex flex-wrap items-center gap-2 p-0.5">
      <FaHashtag className="size-3 text-xslate-11" />
      {comment.variables.map((v) => {
        return (
          <Badge key={v._id} variant={"outline"} className="text-xslate-11">
            {v.name}
          </Badge>
        );
      })}
    </div>
  );
}

function TaggedUsersPills({ comment }: { comment: Comment }) {
  const shouldShow = comment.tagged_users.length > 0;

  if (!shouldShow) return;

  return (
    <div className="flex flex-wrap items-center gap-2 p-0.5">
      <User className="size-3 text-xslate-11" />
      {comment.tagged_users.map((v) => {
        return (
          <Badge key={v._id} variant={"outline"} className="text-xslate-11">
            {getFullName(v)}
          </Badge>
        );
      })}
    </div>
  );
}

function TaggedLabelsPills({ comment }: { comment: Comment }) {
  const shouldShow = comment.labels.length > 0;

  if (!shouldShow) return;

  return (
    <div className="flex flex-wrap items-center gap-2 p-0.5">
      <FaTag className="size-3 text-xslate-11" />
      {comment.labels.map((label) => {
        return (
          <Badge
            key={label._id}
            variant={"outline"}
            style={{
              color: label.color,
              borderColor: label.color,
            }}
          >
            {label.name}
          </Badge>
        );
      })}
    </div>
  );
}

function useCanEditComment(comment: Comment) {
  const me = useUserRequired();
  return me._id === comment.author._id;
}

function useCloseThread() {
  const resetBrush = useSetAtom(Atoms.resetBrushAtom);

  const [, setSp] = useSearchParams();

  const setSelectedCommentId = useSetAtom(Atoms.selectedCommentIdAtom);

  return () => {
    resetBrush();
    setSelectedCommentId(undefined);
    setSp((curr) => {
      const out = new URLSearchParams(curr);
      out.delete(DRAParamsMap.selectedCommentId);
      return out;
    });
  };
}

function CloseThreadButton({ className }: PropsWithCn) {
  const closeThread = useCloseThread();
  return (
    <Button
      className={className}
      variant={"ghost"}
      size={"icon-sm"}
      onClick={closeThread}
    >
      <XCircle className="size-3.5" />
    </Button>
  );
}

function EditButton({
  comment,
  className,
  onClick,
}: { comment: Comment } & PropsWithCn &
  Pick<ComponentProps<"button">, "onClick">) {
  const show = useCanEditComment(comment);

  if (!show) return;

  return (
    <Button
      className={className}
      variant={"ghost"}
      size={"icon-sm"}
      onClick={onClick}
    >
      <Pencil className="size-3.5" />
    </Button>
  );
}

function TrashButton({
  comment,
  ...props
}: { comment: Comment } & PropsWithCn<
  Pick<ComponentProps<typeof Button>, "onClick" | "disabled">
>) {
  const show = useCanEditComment(comment);

  if (!show) return;

  return (
    <Button variant={"ghost"} size={"icon-sm"} {...props}>
      <Trash2 className="size-3.5" />
    </Button>
  );
}

interface LinkifyTextProps {
  text: string;
}
export const LinkifyText: React.FC<LinkifyTextProps> = ({ text }) => {
  // This regular expression matches URLs that start with "http://", "https://", or "www."
  const urlRegex = /((https?:\/\/|www\.)[^\s]+)/gi;
  // Helper function to ensure that URLs have a protocol.
  const getLink = (url: string): string =>
    url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `http://${url}`;
  // This array will hold our text fragments and link elements.
  const elements: (string | JSX.Element)[] = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null;
  // Loop through all matches of the regex in the text.
  while ((match = urlRegex.exec(text)) !== null) {
    const url = match[0];
    const index = match.index;
    // Push the text before the URL.
    if (index > lastIndex) {
      elements.push(text.substring(lastIndex, index));
    }
    // Push the link element.
    elements.push(
      <a
        key={index}
        href={getLink(url)}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        {url}
      </a>
    );
    // Update the last index to the end of the current match.
    lastIndex = index + url.length;
  }
  // Push any remaining text after the last URL.
  if (lastIndex < text.length) {
    elements.push(text.substring(lastIndex));
  }
  // Render the array of text and links inside a fragment.
  return <>{elements}</>;
};

export { CommentThread, useCloseThread };
