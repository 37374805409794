import React, { useEffect, useState, useMemo, Fragment } from "react";
import BarChart from "../../charts/BarChart";
import Footer from "../../nav/Footer";
import { produce } from "immer";
import _ from "lodash";
import moment from "moment";
import {
  ALL_LIMIT_LEVELS_ORDERED,
  LEVEL_TYPES,
  LEVEL_MAGNITUDES,
  limitLevelNumSchema,
} from "../constants";
import { every, findIndex } from "lodash";
import { OperatingFitnessLevelsToggler } from "./OperatingFitnessLevelsToggler";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import useAPI from "../../../lib/useAPI";
import "./OperatingLimitsOverview.scss";
import { Loader } from "@mantine/core";
import MainLayout from "../../layouts/MainLayout";
import { useDateState } from "../../../zustand/useDateState";
import { LinkWithQuery } from "../../nav/LinkWithQuery2";
import { addToast } from "../../toast/use-toast-store";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { ViewModeSelectors } from "../../common/view-mode-selectors";
import {
  useGetUseViewModeStore,
  UseViewModeStoreProvider,
} from "../../../shared-ui/time-series-2/grid-view-store";
import { useGroupsWithSections } from "../../groups/manager/use-groups-with-sections";
import { iife, minLen1 } from "../../../shared-ui/lib/utils";
import { Provider } from "jotai";
import {
  CollapseAllButton,
  ExpandAllButton,
  SectionContainer,
} from "../../ov/unit-ov/unit-overview";
import { cn } from "../../../lib/utils";
import { Switch } from "../../../shared-ui/frontend/switch";
import { useGroupsWithLimitsQuery } from "../../../hooks/tanstack-query";

function OperatingLimitsOverview() {
  const [groups, setGroups] = useState();

  const [charts, setCharts] = useState();
  const api = useAPI();

  const {
    selectedDateStart,
    axisRangeFrom: { dateString: axisFrom },
    axisRangeTo: { dateString: axisTo },
  } = useDateState();

  const [pageError, setPageError] = useState();

  const [excludeLevels, setExcludeLevels] = useState({});
  const [showType, setShowType] = useState(LEVEL_TYPES.map(() => true));
  const [showEmptyCharts, setShowEmptyCharts] = useState(false);

  useDocumentTitle("Operating Fitness > DRA");

  const useViewModeStore = useGetUseViewModeStore();
  const viewMode = useViewModeStore((s) => s.viewMode);
  const setViewMode = useViewModeStore((s) => s.setViewMode);
  const numCols = useViewModeStore((s) => s.numCols);
  const setNumCols = useViewModeStore((s) => s.setNumCols);

  // dynamically set number of columns based on number of groups and screen width
  useEffect(() => {
    const numGroups = groups?.length || 0;
    const dynamicallySetNumCols = (screenWidth, numGroups) => {
      if (screenWidth < 768) {
        setNumCols(2);
      } else if (screenWidth < 1024) {
        setNumCols(3);
      } else if (numGroups < 20) {
        setNumCols(4);
      } else {
        setNumCols(5);
      }
    };

    const handleResize = () => {
      dynamicallySetNumCols(window.innerWidth, numGroups);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [groups, setNumCols]);

  const groupsAndSections = useGroupsWithSections();

  useEffect(() => {
    async function init() {
      setGroups(
        _.map(await api.getGroups(), (group) => ({
          _id: group._id,
          data: group,
          chart: null,
          raw_chart: null,
        }))
      );
    }

    init();
  }, []);

  useEffect(() => {
    if (!groups) {
      return;
    }

    Promise.all(
      _.map(groups, (group) =>
        api.getExceedanceGroupCounts(group._id, axisFrom, axisTo)
      )
    )
      .then((charts) => {
        setCharts(
          _.reduce(
            charts,
            (o, chart, idx) => {
              o[groups[idx]._id] = chart;
              return o;
            },
            []
          )
        );
      })
      .catch(() => setPageError(true));
  }, [axisFrom, axisTo, groups]);

  const processedCharts = useMemo(() => {
    if (!(groups && charts)) {
      return null;
    }

    function processSingleChart(chart) {
      return _.map(chart, (day) => {
        let count = 0;
        let processed_day = {
          key: new Date(day.date),
          originalValues: _.reduce(
            ALL_LIMIT_LEVELS_ORDERED,
            (result, level) => {
              result[level] = day.levels[level] || 0;
              return result;
            },
            {}
          ),
          value: _.map(LEVEL_MAGNITUDES, (levels, numLevelAsStr) => {
            const magnitude = limitLevelNumSchema.parse(
              parseInt(numLevelAsStr)
            );

            if (excludeLevels[magnitude]) {
              return 0;
            }

            if (every(showType, (b) => !!b)) {
              let sum = _.sumBy(levels, (level) => day.levels[level] || 0);
              count += sum;
              return sum;
            } else {
              const trueIndex = findIndex(showType, (b) => !!b);
              count += day.levels[levels[trueIndex]] || 0;
              return day.levels[levels[trueIndex]] || 0;
            }
          }),
        };

        processed_day.count = count;
        return processed_day;
      });
    }

    return _.map(groups, (g) => {
      const chart = processSingleChart(charts[g._id]);
      return { ...g, chart };
    });
  }, [groups, excludeLevels, showType, charts]);

  const selectedIndexStart = moment
    .utc(selectedDateStart)
    .diff(moment.utc(axisFrom), "days");

  const selectedIndexEnd = moment
    .utc(axisTo)
    .diff(moment.utc(axisFrom), "days");

  const renderGroups =
    processedCharts &&
    _.map(processedCharts, (group) => {
      return {
        error: !!group.error,
        loaded: !!group.chart,

        _id: group._id,
        name: group.data.name,
        url: `details/${group._id}`,
        chart: group.chart,
        total: (() => {
          let total = 0;
          if (!group.chart) {
            return 0;
          } /* still loading */

          /* More efficient than looping over the entire array and creating
           * moments for each index. */
          for (let i = selectedIndexStart; i <= selectedIndexEnd; i++) {
            let day = group.chart[i];
            if (!day) {
              continue;
            }
            total += day.count || 0;
          }

          return total;
        })(),
      };
    });

  const trues = showType.reduce((trueCount, v) => {
    return trueCount + +v;
  }, 0);

  const levels = [1, 2, 3, 4, 5]
    .map((level) => ({
      level,
      label: `L${level}`,
      selected: !excludeLevels[level],
      onChange: () => {
        setExcludeLevels(
          produce((levels) => {
            levels[level] = !levels[level];
          })
        );
      },
    }))
    .concat(
      LEVEL_TYPES.map((l, idx) => ({
        level: l,
        label: l,
        selected: showType[idx],
        onChange: (e) => {
          if (trues === 1 && !e.target.checked) {
            // do nothing because at least one must be on
            addToast({
              title: "At least one of High or Low must remain on",
              variant: "danger",
            });
            return;
          }
          setShowType(
            produce((showType) => {
              showType[idx] = !showType[idx];
            })
          );
        },
      }))
    );

  const groupsWithLimitsQuery = useGroupsWithLimitsQuery();
  const groupsWithLimits = groupsWithLimitsQuery.data || [];
  const remainingGroupsWithLimits = showEmptyCharts
    ? groupsAndSections?.remainingGroups
    : groupsAndSections?.remainingGroups.filter((g) =>
        groupsWithLimits.includes(g._id)
      );
  const sectionsWithGroupsWithLimits = showEmptyCharts
    ? groupsAndSections?.sectionsWithGroups
    : groupsAndSections?.sectionsWithGroups.map((s) => ({
        ...s,
        groups: s.groups.filter((g) => groupsWithLimits.includes(g._id)),
      }));

  const oneChart = (group) => {
    // initially we just checked if the data was empty but now we have an API request (useGroupsWithLimitsQuery) to tell us which groups don't have limits
    // because a chart can be legitimately empty and still have limits
    // if (!showEmpty && group.chart.every((x) => x.count === 0)) {
    //   return null;
    // }
    return (
      <div className="rounded-md border border-xslate-5 bg-white shadow-md">
        <LinkWithQuery
          to={group.url}
          className="OperatingLimitsOverview__group__header flex overflow-hidden text-ellipsis whitespace-nowrap px-[1em] py-[0.5em] text-[0.85em] uppercase"
          pick={{
            d: true,
            mo: true,
            y: true,
            z: true,
            cd: true,
          }}
        >
          <div className="overflow-hidden overflow-ellipsis">{group.name}</div>
          <div className="ml-auto capitalize">
            {group.loaded && `${group.total} Exceedances`}
          </div>
        </LinkWithQuery>
        <div className="box-border h-[15em] w-full">
          <BarChart
            chartTitle={`Exceedances for ${group.name} group`}
            xAxisLabel={"Date"}
            yAxisLabel={"Exceedances"}
            chartKeys={_.map(ALL_LIMIT_LEVELS_ORDERED, (level) =>
              level.toUpperCase()
            )}
            /* selectedIndex={selectedDay} */
            selectedRange={[selectedDateStart, axisTo]}
            data={group.chart}
          />
        </div>
      </div>
    );
  };

  const showExpandCollapseAll =
    sectionsWithGroupsWithLimits && sectionsWithGroupsWithLimits.length > 0;

  return (
    <MainLayout allowRangeCalendar showDateNav>
      <div className="flex min-h-[60vh] flex-col bg-bggrey">
        {/* Grid of groups + chart for each group. */}
        <div className="mb-1 flex items-center gap-2 px-4 py-2">
          <h2 className="text-2xl font-semibold tracking-tight">
            Operating Fitness
          </h2>

          <ViewModeSelectors
            className={"ml-auto"}
            withLabels
            variant={"default"}
            enabledModes={["grid"]}
            viewMode={viewMode}
            setViewMode={setViewMode}
            numCols={numCols}
            setNumCols={setNumCols}
          />
          <div className="flex items-center gap-2 rounded-md border border-xslate-5 bg-white px-3 py-1">
            <OperatingFitnessLevelsToggler levels={levels} />
          </div>
        </div>

        <div
          className="absolute left-0 right-0 top-40 w-fit bg-bggrey px-8"
          style={{ marginInline: "auto" }}
        >
          <h3 className="text-2xl font-semibold text-xslate-11">
            No limits to display
          </h3>
        </div>
        <div className="z-10 flex flex-col gap-3 bg-bggrey px-8">
          {groupsAndSections && renderGroups ? (
            <>
              {iife(() => {
                const remainingSorted = remainingGroupsWithLimits
                  .filter((x) => !x.hidden_overview)
                  .map((x) => renderGroups.find((y) => y._id === x._id))
                  .filter((x) => x !== undefined);

                if (!remainingSorted.length) return null;

                return (
                  <>
                    <div
                      className={`grid gap-8 grid-cols-${viewMode === "grid" ? numCols : "2"} my-4`}
                    >
                      {remainingSorted.map((group) => (
                        <Fragment key={group._id}>{oneChart(group)}</Fragment>
                      ))}
                    </div>
                    <div className="h-[1px] bg-xslate-6" />
                  </>
                );
              })}

              {sectionsWithGroupsWithLimits.map((s, i) => {
                const toRender = s.groups
                  .filter((x) => x.hidden_overview === false)
                  .map((x) => renderGroups.find((y) => y._id === x._id))
                  .filter((x) => x !== undefined);

                if (!minLen1(toRender)) return null;

                return (
                  <SectionContainer
                    key={s.section._id}
                    section={s.section}
                    hasHorizontalLine={
                      i !== sectionsWithGroupsWithLimits.length - 1
                    }
                  >
                    <div
                      className={`grid gap-8 grid-cols-${viewMode === "grid" ? numCols : "2"} my-4`}
                    >
                      {toRender.map((group) => {
                        return (
                          <Fragment key={group._id}>{oneChart(group)}</Fragment>
                        );
                      })}
                    </div>
                  </SectionContainer>
                );
              })}
            </>
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="mb-4 mt-8 flex flex-row place-items-center gap-3 px-8">
        {showExpandCollapseAll && (
          <>
            <ExpandAllButton />
            <CollapseAllButton />
          </>
        )}
        <label htmlFor="of-empty-chart-toggle" className="ml-2 text-sm">
          Show Groups without Limits
        </label>
        <Switch
          id="of-empty-chart-toggle"
          checked={showEmptyCharts}
          onCheckedChange={() => setShowEmptyCharts((prev) => !prev)}
        />
      </div>
      <Footer className="mt-auto" />
    </MainLayout>
  );
}

function Skeleton() {
  return (
    <>
      <div className="relative m-[0.7em] flex h-[28vh] items-end gap-3 rounded-md border-[1.5px] border-zinc-300 bg-white p-4 shadow-md">
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
          <Loader color="dark" size="sm" />
        </div>
        {}
        <div className="animate-pulse h-[20%] grow rounded-lg bg-zinc-300" />
        <div className="animate-pulse h-[76%] grow rounded-lg bg-zinc-300" />
        <div className="animate-pulse h-[10%] grow rounded-lg bg-zinc-300" />
        <div className="animate-pulse h-[30%] grow rounded-lg bg-zinc-300" />
        <div className="animate-pulse h-[40%] grow rounded-lg bg-zinc-300" />
      </div>
      <div className="relative m-[0.7em] flex h-[28vh] items-end gap-3 rounded-md border-[1.5px] border-zinc-300 bg-white p-4 shadow-md">
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
          <Loader color="dark" size="sm" />
        </div>
        <div className="animate-pulse h-[65%] grow rounded-lg bg-zinc-300" />
        <div className="animate-pulse h-[30%] grow rounded-lg bg-zinc-300" />
        <div className="animate-pulse h-[8%] grow rounded-lg bg-zinc-300" />
        <div className="animate-pulse h-[86%] grow rounded-lg bg-zinc-300" />
        <div className="animate-pulse h-[3%] grow rounded-lg bg-zinc-300" />
      </div>
    </>
  );
}

export default function Wrapped() {
  const baseUrl = useBaseUrlExperimental();

  return (
    <Provider>
      <UseViewModeStoreProvider
        key={baseUrl}
        init={{ initialViewMode: "grid" }}
      >
        <OperatingLimitsOverview />
      </UseViewModeStoreProvider>
    </Provider>
  );
}
