export function formatTime(seconds: number) {
  if (seconds >= 3600) {
    const tmp = seconds / 3600;
    return (tmp % 1 !== 0 ? Math.round(tmp * 10) / 10 : tmp) + "h";
  } else if (seconds >= 60) {
    const tmp = seconds / 60;
    return (tmp % 1 !== 0 ? Math.round(tmp) : tmp) + "m";
  } else {
    return seconds + "s";
  }
}
