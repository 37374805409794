import {
  Atoms,
  EditMyFitnessLimitAtom,
  isVariableTrendLine,
  MyFitnessLimitType,
  MyLimitsAtom,
  MyLimitsAtomAtom,
  MyLimitsListAtom,
  MyLimitsSingleAtom,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { Button } from "../../../shared-ui/frontend/button";
import { Pencil, Trash2 } from "lucide-react";
import {
  atom,
  ExtractAtomValue,
  useAtom,
  useAtomValue,
  useSetAtom,
  useStore,
} from "jotai";
import { cn, PropsWithCn } from "../../../shared-ui/frontend/cn";
import { useMemo } from "use-memo-one";
import { focusAtom } from "jotai-optics";
import {
  forwardRef,
  memo,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { splitAtom } from "jotai/utils";
import { Input } from "../../../shared-ui/frontend/input";
import { useMutation } from "@tanstack/react-query";
import {
  createMyFitnessLimit,
  deleteMyFitnessLimitById,
  updateMyFitnessLimitById,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { GenericDeleteTriggerWithConfirmationPopup } from "../../../shared-ui/frontend/generic-delete-confirm";
import {
  MyFitnessLimit,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { Tabs, TabsList, TabsTrigger } from "../../../shared-ui/frontend/tabs";
import { Switch } from "../../../shared-ui/frontend/switch";
import { FaBell, FaPlus, FaRegBell } from "react-icons/fa";
import { SelectedVariableAtomContext } from "./control-buttons";
import { ColorPicker } from "@mantine/core";
import { produce } from "immer";
import { useIsMyFitnessLimitsOnStore } from "../use-is-my-fitness-limits-on";
import useNotificationsQuery, {
  useNotificationPostMutation,
  useNotificationToggleMutation,
} from "../../notifications/tanstack/useNotificationsQuery";
import {
  CustomNotification,
  NotificationType,
} from "../../../types/api/Notification";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";

export function MyFitnessLimitsPopover({
  myLimitsAtomAtom,
  children,
}: {
  myLimitsAtomAtom: MyLimitsAtomAtom;
} & { children: (is: boolean, numLimits: number) => ReactNode }) {
  const currentUnit = useCurrentUnitObject();
  const myLimitsAtom: MyLimitsAtom | undefined = useAtomValue(myLimitsAtomAtom);

  if (!myLimitsAtom || !currentUnit?.addons?.["operatingLimits"])
    return undefined;

  return (
    <MyFitnessLimitsPopoverInner
      myLimitsAtom={myLimitsAtom}
      children={children}
    />
  );
}

function MyFitnessLimitsPopoverInner({
  myLimitsAtom,
  children,
}: {
  myLimitsAtom: MyLimitsAtom;
} & { children: (is: boolean, numLimits: number) => ReactNode }) {
  const [isCreating, setIsCreating] = useState(false);

  const setOn = useIsMyFitnessLimitsOnStore((s) => s.setOn);

  const on = useIsMyFitnessLimitsOnStore((s) => s.on);

  const limitsArrayAtom = useMemo(() => {
    return focusAtom(myLimitsAtom, (x) => x.prop("limits"));
  }, [myLimitsAtom]);

  const numLimits = useAtomValue(
    useMemo(() => atom((get) => get(limitsArrayAtom).length), [limitsArrayAtom])
  );

  return (
    <Tooltip>
      <TooltipContent>My Fitness</TooltipContent>
      <Popover>
        <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
          <TooltipTrigger asChild>{children(on, numLimits)}</TooltipTrigger>
        </PopoverTrigger>
        <PopoverContent
          onDrag={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          className="w-[325px] p-3.5 text-xs"
          align="end"
        >
          <div className="mb-4 flex flex-wrap items-center gap-2">
            <span className="whitespace-nowrap font-bold">
              My Fitness Limits
            </span>
            {!isCreating && (
              <Button
                className=""
                variant={"default"}
                size={"icon-sm"}
                onClick={() => setIsCreating(true)}
              >
                <FaPlus className="size-3" />
              </Button>
            )}
            <Switch
              className="ml-auto"
              size={"md"}
              checked={on}
              onCheckedChange={() => setOn(!on)}
            />
          </div>

          <div className={cn("relative flex flex-col gap-3")}>
            {isCreating && (
              <AddNewMyFitnessLimitForm close={() => setIsCreating(false)} />
            )}
            <MyFitnessLimitsList atom={limitsArrayAtom} />
          </div>
        </PopoverContent>
      </Popover>
    </Tooltip>
  );
}

function MyFitnessLimitsList({ atom }: { atom: MyLimitsListAtom }) {
  const atoms = useAtomValue(
    useMemo(() => splitAtom(atom, (x) => x._id), [atom])
  );

  if (!atoms.length)
    return (
      <>
        <div className="flex justify-center">
          <span className="uppercase">No limits set</span>
        </div>
      </>
    );

  return (
    <>
      {atoms.map((x) => {
        return <SingleMyFitnessLimit limitAtom={x} key={`${x}`} />;
      })}
    </>
  );
}

function EditMyLimitInput({ editAtom }: { editAtom: EditMyFitnessLimitAtom }) {
  const [inputValue, setValue] = useAtom(
    useMemo(() => focusAtom(editAtom, (o) => o.prop("editedValue")), [editAtom])
  );

  const focusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    focusRef.current?.focus();
  }, []);

  return (
    <BaseMyFitnessLimitValueInput
      ref={focusRef}
      value={inputValue}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}

export const BaseMyFitnessLimitValueInput = forwardRef<
  HTMLInputElement,
  {
    value: string;
    onChange: (newValue: string) => void;
  }
>(({ onChange, value }, ref) => {
  const invalid = isNaN(parseFloat(value));

  return (
    <Input
      ref={ref}
      className={cn(
        "h-6",
        invalid && "ring-2 ring-xred-9 ring-offset-2 focus-visible:ring-xred-9"
      )}
      type="number"
      step={1}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
});

export function DeleteMyFitnessLimitButton({
  limitId,
  className,
  value,
  size,
}: { limitId: string; value: string; size: "icon" | "icon-xs" } & PropsWithCn) {
  const [tryingToDelete, setTryingToDelete] = useState(false);

  const b = useBaseUrlExperimental();
  const refresh = MyFitnessLimit.useInvalidateListAndStatsQuery();

  const mut = useMutation({
    mutationFn: () => {
      return deleteMyFitnessLimitById(b, limitId);
    },
    onSuccess: ({ variableId }) => {
      refresh(variableId);
      addSuccessToast("Limit deleted");
    },
    onError: (e) => addUnknownErrorToast(e),
  });

  return (
    <>
      <GenericDeleteTriggerWithConfirmationPopup
        open={tryingToDelete}
        onConfirm={() => mut.mutate()}
        confirmDisabled={mut.isLoading}
        title={`Are you sure you want to delete this limit (${value})?`}
        onOpenChange={(open) => !open && setTryingToDelete(false)}
      />
      <Button
        size={size}
        variant={"ghost"}
        className={className}
        onClick={() => setTryingToDelete(true)}
      >
        <Trash2 className={size === "icon-xs" ? "size-3" : "size-4"} />
      </Button>
    </>
  );
}

function EditHighLowTabs({ editAtom }: { editAtom: EditMyFitnessLimitAtom }) {
  const [type, setType] = useAtom(
    useMemo(() => focusAtom(editAtom, (o) => o.prop("editedType")), [editAtom])
  );

  return <BaseHighLowTabs value={type} setValue={setType} />;
}

const MyFitnessLimitTypeStrs = [
  "High",
  "Low",
] as const satisfies (keyof typeof MyFitnessLimitType)[];

export function BaseHighLowTabs({
  setValue,
  value,
}: {
  value: MyFitnessLimitType;
  setValue: (newValue: MyFitnessLimitType) => void;
}) {
  return (
    <Tabs
      value={MyFitnessLimitType[value]}
      onValueChange={(newValue) => {
        const tab = newValue as keyof typeof MyFitnessLimitType;
        const num = MyFitnessLimitType[tab];
        setValue(num);
      }}
    >
      <TabsList className="h-6 rounded-md bg-xslate-4">
        {MyFitnessLimitTypeStrs.map((tab) => {
          return (
            <TabsTrigger className="h-4 py-0 text-xs" value={tab} key={tab}>
              {tab}
            </TabsTrigger>
          );
        })}
      </TabsList>
    </Tabs>
  );
}

function AddNewMyFitnessLimitForm({ close }: { close: () => void }) {
  const jot = useStore();
  const svAtom = useContext(SelectedVariableAtomContext);

  if (!svAtom) throw new Error("no atom");

  const [color, setColor] = useState(() => {
    const sv = jot.get(svAtom);
    return sv.color;
  });

  const [type, setType] = useState(MyFitnessLimitType.High);
  const [value, setValue] = useState("0");

  const atom = useContext(SelectedVariableAtomContext);
  const refresh = MyFitnessLimit.useInvalidateListAndStatsQuery();

  if (!atom) throw new Error("no atom");

  const base = useBaseUrlExperimental();

  const bv = useAtomValue(
    useMemo(
      () => focusAtom(atom, (o) => o.guard(isVariableTrendLine).prop("bv")),
      [atom]
    )
  );

  const mut = useMutation({
    mutationFn: async () => {
      if (!bv) {
        throw new Error("should be set up in such a way this can't be called");
      }

      if (value === undefined) throw new Error("A value is required");

      const y = parseFloat(value);

      if (isNaN(y)) throw new Error("Invalid number");

      return await createMyFitnessLimit(base, {
        variableId: bv.slice(24),
        value: y,
        type: type,
        color: color,
      });
    },
    onSuccess: ({ _id, type, value, color, variableId }) => {
      const sv = jot.get(svAtom);

      if (isVariableTrendLine(sv)) {
        const myLimits = jot.get(sv.myLimits);

        if (myLimits) {
          jot.set(myLimits, (curr) => {
            const newLimits = [
              ...curr.limits,
              { value, type, color, _id: _id },
            ].sort((a, b) => a.value - b.value);

            return {
              ...curr,
              limits: newLimits,
            };
          });
        }
      }

      refresh(variableId);
      close();
      addSuccessToast(`Limit of ${value} created`);
    },
    onError: (err) => addUnknownErrorToast(err),
  });

  if (!bv) return undefined;

  return container(
    false,
    <>
      <div className="flex items-center gap-2">
        <MyColorPicker value={color} onChange={setColor}>
          <div className="inline-flex">
            <button
              className="aspect-square h-6 rounded-md"
              style={{
                backgroundColor: color,
              }}
            />
          </div>
        </MyColorPicker>
        <BaseHighLowTabs value={type} setValue={setType} />
        <BaseMyFitnessLimitValueInput
          value={value}
          onChange={(newValue) => setValue(newValue)}
        />
      </div>
      <div className="flex">
        <Button variant="ghost" size={"xxs"} onClick={close}>
          Cancel
        </Button>
        <Button className="ml-auto" size={"xxs"} onClick={() => mut.mutate()}>
          Save
        </Button>
      </div>
    </>
  );
}

function container(showDisabledStyling: boolean, x: ReactNode) {
  return (
    <div
      className={cn(
        "flex flex-col gap-2.5 rounded-md border-2 border-xslate-6 p-2 animate-in fade-in-10 slide-in-from-top-2",
        showDisabledStyling && "bg-xslate-3"
      )}
    >
      {x}
    </div>
  );
}

export function MyColorPicker({
  onChange,
  value,
  children,
}: {
  value: string;
  onChange: (color: string) => void;
} & PropsWithChildren) {
  return (
    <Popover>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="w-[300px]">
        <ColorPicker
          className="w-full"
          value={value}
          onChange={onChange}
          format="hex"
        />
      </PopoverContent>
    </Popover>
  );
}

function EditSingleMyFitnessLimit({
  editAtom,
  limitAtom,
}: {
  editAtom: EditMyFitnessLimitAtom;
  limitAtom: MyLimitsSingleAtom;
}) {
  const refresh = MyFitnessLimit.useInvalidateListAndStatsQuery();
  const editedColorAtom = useMemo(
    () => focusAtom(editAtom, (o) => o.prop("editedColor")),
    [editAtom]
  );
  const [editedColor, setEditedColor] = useAtom(editedColorAtom);

  const setEditAtom = useSetAtom(
    useMemo(() => focusAtom(limitAtom, (o) => o.prop("editAtom")), [limitAtom])
  );

  const limitId = useAtomValue(
    useMemo(() => focusAtom(limitAtom, (o) => o.prop("_id")), [limitAtom])
  );

  const closeEditForm = () => setEditAtom(undefined);

  const b = useBaseUrlExperimental();

  const jot = useStore();

  const mut = useMutation({
    mutationFn: async () => {
      const edits = jot.get(editAtom);

      const newValue = parseFloat(edits.editedValue);

      if (isNaN(newValue)) throw new Error("Invalid number");

      return await updateMyFitnessLimitById(b, limitId, {
        value: newValue,
        type: edits.editedType,
        color: editedColor,
      });
    },
    onSuccess: ({ _id, color, type, value, variableId }) => {
      jot.set(
        limitAtom,
        produce((curr) => {
          curr.color = color;
          curr._id = _id;
          curr.type = type;
          curr.value = value;
          curr.editAtom = undefined;
        })
      );
      closeEditForm();
      refresh(variableId);
      addSuccessToast("Limit updated");
    },
    onError: (e) => addUnknownErrorToast(e),
  });

  return (
    <>
      <div className="flex items-center gap-2">
        <MyColorPicker value={editedColor} onChange={setEditedColor}>
          <div className="inline-flex">
            <button
              className="aspect-square h-6 rounded-md"
              style={{
                backgroundColor: editedColor,
              }}
            />
          </div>
        </MyColorPicker>
        <EditHighLowTabs editAtom={editAtom} />
        <EditMyLimitInput editAtom={editAtom} />
      </div>

      <div className="flex">
        <Button
          size={"xxs"}
          variant={"ghost"}
          onClick={closeEditForm}
          disabled={mut.isLoading}
        >
          Cancel
        </Button>
        <Button
          className="ml-auto"
          size={"xxs"}
          disabled={mut.isLoading}
          onClick={() => mut.mutate()}
        >
          Save
        </Button>
      </div>
    </>
  );
}

const SingleMyFitnessLimit = memo(
  ({ limitAtom }: { limitAtom: MyLimitsSingleAtom }) => {
    const enabled = useIsMyFitnessLimitsOnStore((s) => s.on);
    const [myLimit, setLimit] = useAtom(limitAtom);

    const { value, editAtom, _id, type, color } = myLimit;

    const a = useContext(SelectedVariableAtomContext);
    if (!a) throw new Error("no atom");
    const bv = useAtomValue(
      useMemo(
        () => focusAtom(a, (o) => o.guard(isVariableTrendLine).prop("bv")),
        [a]
      )
    );

    if (editAtom) {
      return container(
        false,
        <EditSingleMyFitnessLimit editAtom={editAtom} limitAtom={limitAtom} />
      );
    }

    return container(
      !enabled,
      <div className="flex items-center gap-1 pl-2">
        <div
          className="aspect-square h-3 rounded-full"
          style={{
            backgroundColor: color,
          }}
        />
        <span className="ml-2 font-medium uppercase">
          {MyFitnessLimitType[type]}
        </span>
        <span className="ml-8 font-medium">
          {Math.round(value * 10000) / 10000}
        </span>
        <MyLimitNotificationToggle
          variableId={bv?.slice(24) || ""}
          type={type}
          value={value}
          size="icon-xs"
        />
        <Button size={"icon-xs"} variant={"ghost"}>
          <Pencil
            className="size-3"
            onClick={() => {
              setLimit(
                produce((curr) => {
                  const initialValue: ExtractAtomValue<
                    NonNullable<typeof curr.editAtom>
                  > = {
                    editedType: curr.type,
                    editedValue: curr.value.toString(),
                    editedColor: curr.color,
                  };

                  curr.editAtom = atom(initialValue);
                })
              );
            }}
          />
        </Button>
        <DeleteMyFitnessLimitButton
          limitId={_id}
          value={value.toFixed(2)}
          size="icon-xs"
        />
      </div>
    );
  }
);

export function MyLimitNotificationToggle({
  variableId,
  type,
  value,
  size,
}: {
  variableId: string;
  type: MyFitnessLimitType;
  value: number;
  size: "icon-xs" | "icon";
}) {
  const variable = useVariablesArrayQuery()?.data?.find(
    (x) => x._id === variableId
  );
  const notifs =
    useNotificationsQuery()?.data?.filter(
      (n) =>
        n.type === NotificationType.CUSTOM &&
        (n as CustomNotification).customExpression ===
          `"${variable?.name}" ${MyFitnessLimitType[type] === "High" ? ">" : "<"} ${value}`
    ) || [];
  const enabledNotifs = notifs.filter((n) => n.enabled);
  const hasEnabledNotification = enabledNotifs.length > 0;
  const hasNotification = notifs.length > 0;
  const notifMut = useNotificationPostMutation();
  const notifToggleMut = useNotificationToggleMutation(notifs[0]?._id || "");

  return (
    <Button
      size={size}
      className="ml-auto"
      variant={hasEnabledNotification ? "default" : "ghost"}
      onClick={() =>
        hasNotification
          ? notifToggleMut.mutate()
          : notifMut.mutate({
              schedule: { type: "Once" },
              customExpression: `"${variable?.name}" ${MyFitnessLimitType[type] === "High" ? ">" : "<"} ${value}`,
              type: NotificationType.CUSTOM,
              value: 10,
            })
      }
    >
      {hasEnabledNotification ? (
        <FaBell className={size === "icon" ? "size-4" : "size-3"} />
      ) : (
        <FaRegBell className={size === "icon" ? "size-4" : "size-3"} />
      )}
    </Button>
  );
}
