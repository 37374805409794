import assets from "../assets";
import type { HelpPage } from "./types";

const ReleaseNotes: HelpPage = {
  "color": "#38bdff",
  "sectiontitle": "Release Notes",
  "url": "releasenotes",
  "subtitle": "Stay informed of our latest features and releases",
  "subsections": [
      {
          "id": "subsection-190",
          "title": "2024.3",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-191",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "My Fitness (New DRA Module)",
                          "id": "content-list-192",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Relationship Heatmaps for Clusters",
                          "id": "content-list-193",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Fault Tree Improvements",
                          "id": "content-list-1556",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Session Analytics",
                          "id": "content-list-1557",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Enhanced Reports",
                          "id": "content-list-1568",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Updated Information Button",
                          "id": "content-list-1579",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Option to Hide Specific Groups on Overview Pages",
                          "id": "content-list-1590",
                          "marginLeft": "0"
                      },
                      {
                          "value": "New Mathematical Functions",
                          "id": "content-list-1601",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General System Enhancements and Bug Fixes",
                          "id": "content-list-3503",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-194",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below.",
                  "id": "content-3914",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2024.3.pdf",
                  "displayname": "Download DRA Release Notes 2024.3",
                  "codename": "rn20243",
                  "id": "content-4935",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.rn20243
              }
          ]
      },
      {
          "id": "subsection-21",
          "title": "2024.2",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features: ",
                  "id": "content-22",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "ARIA (New DRA Module) ",
                          "id": "content-list-24",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Dynamic Clusters (Autonomous) ",
                          "id": "content-list-25",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Correlated Tags (Autonomous) ",
                          "id": "content-list-26",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Improved Instant Calculator ",
                          "id": "content-list-27",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Redesigned and Improved Trendcharts ",
                          "id": "content-list-28",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Simplified Editing of Dates ",
                          "id": "content-list-29",
                          "marginLeft": "0"
                      },
                      {
                          "value": "New Grid and Overlay Views  ",
                          "id": "content-list-30",
                          "marginLeft": "0"
                      },
                      {
                          "value": "New Filter Options on DRI and Profile Book Pages ",
                          "id": "content-list-31",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Improved Personal Folders ",
                          "id": "content-list-32",
                          "marginLeft": "0"
                      },
                      {
                          "value": "New Hierarchical Level in DRA: Section   ",
                          "id": "content-list-33",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General System Enhancements and Bug Fixes ",
                          "id": "content-list-34",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-23",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below: ",
                  "id": "content-35",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2024.2.pdf",
                  "displayname": "Download DRA Release Notes 2024.2",
                  "codename": "rn20242",
                  "id": "content-36",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.rn20242
              }
          ]
      },
      {
          "id": "subsection-37",
          "title": "2024.1",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-38",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Instant Calculator",
                          "id": "content-list-40",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Box Plots in Variability Dynamics",
                          "id": "content-list-41",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Standard ‘Process Capability Analysis’ integrated with DRA’s Variability Dynamics ",
                          "id": "content-list-42",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Clusters and Groups folders on Profile Book ",
                          "id": "content-list-43",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Color Indicators on Operating Fitness List ",
                          "id": "content-list-44",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Smart Expression Builder ",
                          "id": "content-list-45",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Microsoft Entra ID Integration/single sign on",
                          "id": "content-list-46",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General System Enhancements and Bug Fixes ",
                          "id": "content-list-47",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-39",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below: ",
                  "id": "content-48",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2024.1.pdf",
                  "displayname": "Download DRA Release Notes 2024.1",
                  "codename": "rn20241",
                  "id": "content-49",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.rn20241
              }
          ]
      },
      {
          "id": "subsection-50",
          "title": "2023.3.3",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-51",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "'Variability Dynamics' - New Risk Identification Module",
                          "id": "content-list-53",
                          "marginLeft": "0"
                      },
                      {
                          "value": "'Bad Tags' Page",
                          "id": "content-list-54",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Option to Re-order Groups",
                          "id": "content-list-55",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Shutdown Rules and Operating Modes for Clusters",
                          "id": "content-list-56",
                          "marginLeft": "0"
                      },
                      {
                          "value": "New Operators",
                          "id": "content-list-57",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General System Enhancements and Bug Fixes",
                          "id": "content-list-58",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-52",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-59",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2023.3.3.pdf",
                  "displayname": "Download DRA Release Notes 2023.3.3",
                  "codename": "rn202333",
                  "id": "content-60",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.rn202333
              }
          ]
      },
      {
          "id": "subsection-61",
          "title": "2023.3",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-62",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Sloping Trend: Track Changes in Slopes over Time",
                          "id": "content-list-64",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Pattern Notifications (With Easy Click and Drag functionality)",
                          "id": "content-list-65",
                          "marginLeft": "0"
                      },
                      {
                          "value": "File Attachments for Fault Tree Nodes",
                          "id": "content-list-66",
                          "marginLeft": "0"
                      },
                      {
                          "value": "‘Custom’ Option in Date Ranges",
                          "id": "content-list-67",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Option to Turn Off Anomaly Calculations for Operating Modes",
                          "id": "content-list-68",
                          "marginLeft": "0"
                      },
                      {
                          "value": "New Operators",
                          "id": "content-list-69",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General System Enhancements and Bug Fixes",
                          "id": "content-list-70",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-63",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-71",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2023.3.pdf",
                  "displayname": "Download DRA Release Notes 2023.3",
                  "codename": "rn20233",
                  "id": "content-72",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.rn20233
              }
          ]
      },
      {
          "id": "subsection-73",
          "title": "2023.2",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-74",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "User Teams",
                          "id": "content-list-76",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Fault Tree Improvements",
                          "id": "content-list-77",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Acknowledgement Improvements",
                          "id": "content-list-78",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General System Enhancements and Bug Fixes",
                          "id": "content-list-79",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-75",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-80",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2023.2.pdf",
                  "displayname": "Download DRA Release Notes 2023.2",
                  "codename": "rn20232",
                  "id": "content-81",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.rn20232
              }
          ]
      },
      {
          "id": "subsection-82",
          "title": "2023.1",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-83",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "A Contemporary Look and Complementing Capabilities",
                          "id": "content-list-85",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Fault Trees – Revised and Redesigned",
                          "id": "content-list-86",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Pattern Matching",
                          "id": "content-list-87",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Periodicity Monitoring",
                          "id": "content-list-88",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General Enhancements and Bug Fixes",
                          "id": "content-list-89",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-84",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-90",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2023.1.pdf",
                  "displayname": "Download DRA Release Notes 2023.1",
                  "codename": "rn20231",
                  "id": "content-91",
                  "content": assets.rn20231,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-92",
          "title": "2022.1b",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-93",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Help Page",
                          "id": "content-list-95",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-94",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-96",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2022.1b.pdf",
                  "displayname": "Download DRA Release Notes 2022.1b",
                  "codename": "rn20221b",
                  "id": "content-97",
                  "content": assets.rn20221b,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-98",
          "title": "2022.1",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-99",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Dynamic Trendcharts on Fault Trees and Operating Fitness",
                          "id": "content-list-101",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General Enhancements and Bug Fixes",
                          "id": "content-list-102",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-100",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-103",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2022.1.pdf",
                  "displayname": "Download DRA Release Notes 2022.1",
                  "codename": "rn20221",
                  "id": "content-104",
                  "content": assets.rn20221,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-105",
          "title": "2021.2b",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-106",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Shift Reports",
                          "id": "content-list-108",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Fresh Anomaly Folders",
                          "id": "content-list-109",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Shutdown Buffer",
                          "id": "content-list-110",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Full-width view for Fault Trees",
                          "id": "content-list-111",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Public API supports Fault Trees results",
                          "id": "content-list-112",
                          "marginLeft": "0"
                      },
                      {
                          "value": "New Summary Options for Acknowledgments",
                          "id": "content-list-113",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Option to View Select Limits in Operating Fitness Report",
                          "id": "content-list-114",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Security Enhancements and Bug Fixes",
                          "id": "content-list-115",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-107",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-116",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2021.2b.pdf",
                  "displayname": "Download DRA Release Notes 2021.2b",
                  "codename": "rn20212b",
                  "id": "content-117",
                  "content": assets.rn20212b,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-118",
          "title": "2021.1b",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-119",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Improvements in Analytics Algorithms",
                          "id": "content-list-121",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Export Acknowledgment Summary",
                          "id": "content-list-122",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Export Personal Notifications",
                          "id": "content-list-123",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Security Enhancements and Bug Fixes",
                          "id": "content-list-124",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-120",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-125",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2021.1b.pdf",
                  "displayname": "Download DRA Release Notes 2021.1b",
                  "codename": "rn20211b",
                  "id": "content-126",
                  "content": assets.rn20211b,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-127",
          "title": "2021.1",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:\n",
                  "id": "content-128",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Personal Notifications",
                          "id": "content-list-130",
                          "marginLeft": "0"
                      },
                      {
                          "value": "New Operators",
                          "id": "content-list-131",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Fresh Anomaly Report",
                          "id": "content-list-132",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Add Specific Time Periods in Shutdown Rules",
                          "id": "content-list-133",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Option to Turn Off Positive or Negative Slope Calculations (Sloping Trend)",
                          "id": "content-list-134",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Option to Download Operating Limits (Operating Fitness)",
                          "id": "content-list-135",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Speed Improvements and Bug Fixes",
                          "id": "content-list-136",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-129",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-137",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2021.1.pdf",
                  "displayname": "Download DRA Release Notes 2021.1",
                  "codename": "rn20211",
                  "id": "content-138",
                  "content": assets.rn20211,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-139",
          "title": "2020.3",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-140",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "DRA Realtime (Add-On)",
                          "id": "content-list-142",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Operating Fitness Report",
                          "id": "content-list-143",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Improved and Faster Analytics",
                          "id": "content-list-144",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Speed Improvements and Bug Fixes",
                          "id": "content-list-145",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-141",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-146",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2020.3.pdf",
                  "displayname": "Download DRA Release Notes 2020.3",
                  "codename": "rn20203",
                  "id": "content-147",
                  "content": assets.rn20203,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-148",
          "title": "2020.2",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:\n",
                  "id": "content-149",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Operating Fitness (Add-On)",
                          "id": "content-list-151",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Sloping Trend Report",
                          "id": "content-list-152",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Improved Analytics Engine",
                          "id": "content-list-153",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General Improvements and Bug Fixes",
                          "id": "content-list-154",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-150",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-155",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2020.2.pdf",
                  "displayname": "Download DRA Release Notes 2020.2",
                  "codename": "rn20202",
                  "id": "content-156",
                  "content": assets.rn20202,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-157",
          "title": "2020.1a, 2020.1b",
          "content": [
              {
                  "type": "paragraph",
                  "content": "This release includes the following new features:",
                  "id": "content-158",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Fault Tree Heatmap",
                          "id": "content-list-160",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Create Open Issues",
                          "id": "content-list-161",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Tag Indexing and Validation (Applicable for Customers with PI and CTC historians)",
                          "id": "content-list-162",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Condensed Reports",
                          "id": "content-list-163",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Support for Unicode Characters in Comments",
                          "id": "content-list-164",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Faster Loading of DRA Webpages during (a) Slow Network Connectivity and (b) Back-Processing",
                          "id": "content-list-165",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Better Memory Usage and Faster Processing",
                          "id": "content-list-166",
                          "marginLeft": "0"
                      },
                      {
                          "value": "General Improvements and Bug Fixes",
                          "id": "content-list-167",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-159",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "For more details, download the full Release Notes (PDF) below:",
                  "id": "content-168",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "pdf",
                  "filename": "DRA Release Notes 2020.1a and 2020.1b.pdf",
                  "displayname": "Download DRA Release Notes 2020.1a and 2020.1b",
                  "codename": "rn20201a1b",
                  "id": "content-169",
                  "content": assets.rn20201a1b,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      }
  ]
};

export default ReleaseNotes;
