/* eslint no-use-before-define: 0 */

import trainingintro from "../../assets/help/DRA Training Intro.mp4";
import trainingintrocc from "../../assets/help/DRA Training Intro CC.vtt";
import trainingpart1 from "../../assets/help/DRA Training Part 1.mp4";
import trainingpart1cc from "../../assets/help/DRA Training Part 1 CC.vtt";
import trainingpart2 from "../../assets/help/DRA Training Part 2.mp4";
import trainingpart2cc from "../../assets/help/DRA Training Part 2 CC.vtt";
import trainingpart3 from "../../assets/help/DRA Training Part 3.mp4";
import trainingpart3cc from "../../assets/help/DRA Training Part 3 CC.vtt";
import trainingpart4 from "../../assets/help/DRA Training Part 4.mp4";
import trainingpart4cc from "../../assets/help/DRA Training Part 4 CC.vtt";
import trainingpart5 from "../../assets/help/DRA Training Part 5.mp4";
import trainingpart5cc from "../../assets/help/DRA Training Part 5 CC.vtt";
import ftTraining from "../../assets/help/FT Training.mp4";
import ftTrainingVTT from "../../assets/help/FT Training.vtt";
import ofTraining from "../../assets/help/OF Training.mp4";
import ofTrainingVTT from "../../assets/help/OF Training.vtt";

import login from "../../assets/help/login.png";
import navigation from "../../assets/help/navigation.png";
import overviewdashboard from "../../assets/help/overviewdashboard.png";
import dridashboard1 from "../../assets/help/dridashboard1.png";
import dridashboard2 from "../../assets/help/dridashboard2.png";
import profilebook from "../../assets/help/profilebook.png";
import comments1 from "../../assets/help/comments.png";
import comments2 from "../../assets/help/comments2.png";
import comments3 from "../../assets/help/comments3.png";
import notifications1 from "../../assets/help/Notification1.png";
import notifications2 from "../../assets/help/Notification2.png";
import notifications3 from "../../assets/help/Notification3.png";
import custom1 from "../../assets/help/Custom1.png";
import custom2 from "../../assets/help/Custom2.png";
import custom3 from "../../assets/help/Custom3.png";
import notifications4 from "../../assets/help/notifications4.png";
import settings1 from "../../assets/help/settings1.png";
import settings2 from "../../assets/help/settings2.png";
import settings3 from "../../assets/help/settings3.png";
import settings4 from "../../assets/help/settings4.png";
import settings5 from "../../assets/help/settings5.png";
import settings6 from "../../assets/help/settings6.png";
import settings7 from "../../assets/help/settings7.png";
import settings8 from "../../assets/help/settings8.png";
import settings9 from "../../assets/help/settings9.png";
import settings10 from "../../assets/help/settings10.png";
import drasteps1 from "../../assets/help/drasteps1.png";
import drasteps2 from "../../assets/help/drasteps2.png";
import drasteps3 from "../../assets/help/drasteps3.png";
import drasteps4 from "../../assets/help/drasteps4.png";
import pattern1 from "../../assets/help/Pattern1.png";
import pattern2 from "../../assets/help/Pattern2.png";
import slopingtrend from "../../assets/help/slopingtrend.png";
import variability from "../../assets/help/variability.png";
import capability from "../../assets/help/capability.png";
import instantcalc from "../../assets/help/instantcalc.png";
import faulttree1 from "../../assets/help/faulttree1.png";
import operatingfitness from "../../assets/help/operatingfitness.png";
import advancedsettings from "../../assets/help/advancedsettings.png";
import ARIA from "../../assets/help/ARIA.png";
import tags1 from "../../assets/help/tags1.png";
import tags2 from "../../assets/help/tags2.png";
import tags3 from "../../assets/help/tags3.png";
import groups1 from "../../assets/help/groups1.png";
import groups2 from "../../assets/help/groups2.png";
import groups3 from "../../assets/help/groups3.png";
import shutdownrules from "../../assets/help/shutdownrules.png";
import operatingmodes from "../../assets/help/operatingmodes.png";
import clusters from "../../assets/help/clusters.png";
import faulttrees from "../../assets/help/faulttrees.png";
import operatingfitness2 from "../../assets/help/operatingfitness2.png";
import myfitness1 from "../../assets/help/MyFitness1.png";
import specialtyreport from "../../assets/help/specialtyreport.png";
import specialtyreport2 from "../../assets/help/specialtyreport2.png";
import specialtyreport3 from "../../assets/help/specialtyreport3.png";
import specialtyreport4 from "../../assets/help/specialtyreport4.png";
import teams1 from "../../assets/help/Teams1.png";
import teams2 from "../../assets/help/Teams2.png";
import users from "../../assets/help/users.png";
import users2 from "../../assets/help/users2.png";
import rn20243 from "../../assets/help/DRA Release Notes 2024.3.pdf";
import rn20242 from "../../assets/help/DRA Release Notes 2024.2.pdf";
import rn20241 from "../../assets/help/DRA Release Notes 2024.1.pdf";
import rn202333 from "../../assets/help/DRA Release Notes 2023.3.3.pdf";
import rn20233 from "../../assets/help/DRA Release Notes 2023.3.pdf";
import rn20232 from "../../assets/help/DRA Release Notes 2023.2.pdf";
import rn20231 from "../../assets/help/DRA Release Notes 2023.1.pdf";
import rn20221b from "../../assets/help/DRA Release Notes 2022.1b.pdf";
import rn20221 from "../../assets/help/DRA Release Notes 2022.1.pdf";
import rn20212b from "../../assets/help/DRA Release Notes 2021.2b.pdf";
import rn20211b from "../../assets/help/DRA Release Notes 2021.1b.pdf";
import rn20211 from "../../assets/help/DRA Release Notes 2021.1.pdf";
import rn20203 from "../../assets/help/DRA Release Notes 2020.3.pdf";
import rn20202 from "../../assets/help/DRA Release Notes 2020.2.pdf";
import rn20201a1b from "../../assets/help/DRA Release Notes 2020.1a and 2020.1b.pdf";

type Assets = {
  trainingintro: any;
  trainingintrocc: any;
  trainingpart1: any;
  trainingpart1cc: any;
  trainingpart2: any;
  trainingpart2cc: any;
  trainingpart3: any;
  trainingpart3cc: any;
  trainingpart4: any;
  trainingpart4cc: any;
  trainingpart5: any;
  trainingpart5cc: any;
  ftTraining: any;
  ftTrainingVTT: any;
  ofTraining: any;
  ofTrainingVTT: any;

  login: any;
  navigation: any;
  overviewdashboard: any;
  dridashboard1: any;
  dridashboard2: any;
  profilebook: any;
  comments1: any;
  comments2: any;
  comments3: any;
  notifications1: any;
  notifications2: any;
  notifications3: any;
  custom1: any;
  custom2: any;
  custom3: any;
  notifications4: any;
  settings1: any;
  settings2: any;
  settings3: any;
  settings4: any;
  settings5: any;
  settings6: any;
  settings7: any;
  settings8: any;
  settings9: any;
  settings10: any;
  drasteps1: any;
  drasteps2: any;
  drasteps3: any;
  drasteps4: any;
  pattern1: any;
  pattern2: any;
  slopingtrend: any;
  variability: any;
  instantcalc: any;
  faulttree1: any;
  capability: any;
  operatingfitness: any;
  myfitness1: any;
  ARIA: any;

  advancedsettings: any;
  tags1: any;
  tags2: any;
  tags3: any;
  groups1: any;
  groups2: any;
  groups3: any;
  shutdownrules: any;
  operatingmodes: any;
  clusters: any;
  faulttrees: any;
  operatingfitness2: any;
  specialtyreport: any;
  specialtyreport2: any;
  specialtyreport3: any;
  specialtyreport4: any;
  teams1: any;
  teams2: any;
  users: any;
  users2: any;

  rn20243: any;
  rn20242: any;
  rn20241: any;
  rn202333: any;
  rn20233: any;
  rn20232: any;
  rn20231: any;
  rn20221b: any;
  rn20221: any;
  rn20212b: any;
  rn20211b: any;
  rn20211: any;
  rn20203: any;
  rn20202: any;
  rn20201a1b: any;
};

const assets: Assets = {
  trainingintro,
  trainingintrocc,
  trainingpart1,
  trainingpart1cc,
  trainingpart2,
  trainingpart2cc,
  trainingpart3,
  trainingpart3cc,
  trainingpart4,
  trainingpart4cc,
  trainingpart5,
  trainingpart5cc,
  ftTraining,
  ftTrainingVTT,
  ofTraining,
  ofTrainingVTT,

  advancedsettings,
  clusters,
  comments1,
  comments2,
  comments3,
  custom1,
  custom2,
  custom3,
  drasteps1,
  drasteps2,
  drasteps3,
  drasteps4,
  dridashboard1,
  dridashboard2,
  faulttree1,
  faulttrees,
  groups1,
  groups2,
  groups3,
  login,
  navigation,
  notifications1,
  notifications2,
  notifications3,
  notifications4,
  operatingfitness,
  operatingfitness2,
  myfitness1,
  operatingmodes,
  overviewdashboard,
  pattern1,
  pattern2,
  profilebook,
  rn20201a1b,
  rn20202,
  rn20203,
  rn20211,
  rn20211b,
  rn20212b,
  rn20221,
  rn20221b,
  rn20231,
  rn20232,
  rn20233,
  rn202333,
  rn20241,
  rn20242,
  rn20243,
  settings1,
  settings10,
  settings2,
  settings3,
  settings4,
  settings5,
  settings6,
  settings7,
  settings8,
  settings9,
  shutdownrules,
  specialtyreport,
  specialtyreport2,
  specialtyreport3,
  specialtyreport4,
  slopingtrend,
  variability,
  instantcalc,
  capability,
  tags1,
  tags2,
  tags3,
  teams1,
  teams2,
  users,
  users2,
  ARIA,
};

export default assets;
