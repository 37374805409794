import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import * as R from "remeda";
import { BaseUrl, useBaseUrlExperimental } from "../zustand/useBaseUrl";
import {
  APIRouteParamsWithoutBaseUrl,
  getComment_TODO_VALIDATE,
  getExceedanceGroupCounts,
  getFaultTrees,
  getGroups,
  getLimitsForVariable,
  getOperatingLimitProbabilities,
  getProbabilityOrExceedanceVariables,
  getRisks,
  getShutdownRules,
  getSpecialtyReports,
  getTeams,
  getUsers,
  getVariables,
  setWatchlist,
  getSlopes,
  saveExpression,
  getSavedExpressions,
  getInstantCalculation,
  getTimeseries,
  getAcknowledgements,
  getLabels,
  GetCommentsListOpts,
  getCommentsList,
  getSlopeById,
  getAriaClusters,
  getClusterDriftScore,
  toggleAria,
  saveCluster,
  deleteCluster,
  getAriaTimeseries,
  getAnomaly,
  deleteSavedExpression,
  getVariablesSlopes,
  getFreshAnomalies,
  getFolders,
  getCommentedVariables,
  getAcknowledgedVariables,
  getSectionForUnit,
  getAnomalySummariesForDay,
  getFaultTree,
  getFaultTreeNodes,
  getAllFaultTreeNodes,
  postFaultTreeNode,
  deleteFaultTreeNode,
  nodePayload,
  editFaultTreeNode,
  deleteFaultTree,
  editFaultTree,
  copyFaultTree,
  getActiveFTNodeIds,
  getFTSectionForUnit,
  updateUser,
  getFtSortOrder,
  getPovSortOrder,
  getFaultTreeStatus,
  getGroupsWithLimits,
  getGroupsWithAriaClusters,
  updateSortOrder,
  getMyFitnessLimits,
  getMyFitnessLimitsStatsForVariableId,
  getAnalyticsCsv,
  getAllAnomaliesForDayDRIPage,
  getCommentsSummary,
} from "../frameworks/fetcher/api-routes-experimental";
import {
  anomalySchema,
  commentsSummarySchema,
  groupSchema,
  variableSchema,
} from "../lib/api-validators";
import { clusterSchema } from "../lib/api-schema/cluster";
import { sortedLevels } from "../lib/api-schema/operating-limit";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../components/toast/use-toast-store";
import {
  minutesToMilliseconds,
  secondsToMilliseconds,
  hoursToMilliseconds,
} from "date-fns";
import { YYYY_MM_DD } from "../lib/validators";
import {
  mergeChangepoints,
  type DRATimeseries,
} from "../shared-ui/time-series-2/fetcher-utils";
import { SavedExpressionVariable } from "../components/instant-calculator/types";
import { OVERALL_GROUP_NAME } from "../lib/api-schema/misc";
import { faultTreeSchema } from "../lib/api-schema/ft/fault-tree";
import { UserRoleString } from "../zustand/auth/types/UserRole";
import { SortOrder } from "../lib/api-schema/user";
import { useUserRequired } from "../zustand/auth/useAuthStore";
import { useCallback, useMemo } from "use-memo-one";
import moment from "moment";
import { z } from "zod";

export type InferQueryResultData<T> =
  T extends UseQueryResult<infer R> ? R : never;

/**
 * getVariables can look different depending on what incldue options you pass it.
 */
const getRawVariables = (unitName: BaseUrl, filter?: string[]) =>
  getVariables(unitName).then((variables) => {
    return variableSchema
      .array()
      .parse(variables)
      .filter((v) => {
        if (filter) {
          return filter.includes(v._id);
        }
        return true;
      });
  });

export function useSpecialtyReportsQuery(b: BaseUrl) {
  return useQuery({
    queryFn: () => getSpecialtyReports(b),
    queryKey: [b, "specialtyReports"],
    refetchOnWindowFocus: false,
  });
}

export function useLabelsQuery() {
  return useQuery({
    queryKey: ["labels"],
    queryFn: getLabels,
    staleTime: Infinity,
  });
}

export function useGroupsWithLimitsQuery() {
  const b = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["groups-with-limits", b],
    queryFn: () => getGroupsWithLimits(b),
  });
}

export function useGroupsWithAriaClustersQuery() {
  const b = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["groups-with-aria-clusters", b],
    queryFn: () => getGroupsWithAriaClusters(b),
  });
}

export function useFaultTreeQuery(treeId: string) {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["fault-tree", baseUrl, treeId],
    queryFn: () => getFaultTree(baseUrl, treeId),
  });
}

export function useFaultTreesQuery() {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["fault-tree", baseUrl],
    queryFn: () => getFaultTrees(baseUrl),
  });
}

export function useFaultTreeMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (tree: faultTreeSchema) => {
      return editFaultTree(baseUrl, tree);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      addSuccessToast("Tree updated");
      cli.invalidateQueries(["fault-tree", baseUrl]);
    },
  });
}

export function useFaultTreeCopyMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (tree: { name: string; id: string }) => {
      const name = tree.name;
      addToast({
        title: `Building ${name}...`,
        variant: "primary",
      });
      return copyFaultTree(baseUrl, tree.id, { name });
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      addSuccessToast("Tree copied");
      cli.invalidateQueries(["fault-tree", baseUrl]);
    },
  });
}

export function useFaultTreeDeleteMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (treeId: string) => {
      return deleteFaultTree(baseUrl, treeId);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      addSuccessToast("Tree deleted");
      cli.invalidateQueries(["fault-tree", baseUrl]);
    },
  });
}

export function useFaultTreeNodesQuery(treeId: string) {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["fault-tree", baseUrl, treeId, "nodes"],
    queryFn: () => getFaultTreeNodes(baseUrl, treeId),
  });
}

export function useAllFaultTreeNodesQuery() {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["fault-tree", baseUrl, "all"],
    queryFn: () => getAllFaultTreeNodes(baseUrl),
  });
}

export function useActiveFaultTreeNodesQuery(date: YYYY_MM_DD) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["fault-tree", baseUrl, "active", date],
    // todo this is also called in src/components/ack/manager/use-ack-man-anomalies-query.ts which should call this to avoid duplicate requests
    queryFn: () => getActiveFTNodeIds(baseUrl, date), // a map whose keys are active node ids and values are names of fts each node belongs to
  });
}

export function useFaultTreeStatusQuery(treeId: string, date: YYYY_MM_DD) {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["fault-tree", baseUrl, treeId, "status", date],
    queryFn: () => getFaultTreeStatus(baseUrl, { treeId, date }),
    enabled: treeId.length > 0,
  });
}

export function useFaultTreeNodeMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (payload: { nodeId: string; node: nodePayload }) => {
      return editFaultTreeNode(baseUrl, payload.nodeId, payload.node);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      addSuccessToast("Node updated");
      cli.invalidateQueries(["fault-tree", baseUrl]);
    },
  });
}

export function useFaultTreeNodeCreateMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (
      payload: nodePayload & { parentId: string; faultTreeId: string }
    ) => {
      return postFaultTreeNode(baseUrl, payload);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      addSuccessToast("Node created");
      cli.invalidateQueries(["fault-tree", baseUrl]);
    },
  });
}

export function useFaultTreeNodeDeleteMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (nodeId: string) => {
      return deleteFaultTreeNode(baseUrl, nodeId);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      addSuccessToast("Node deleted");
      cli.invalidateQueries(["fault-tree", baseUrl]);
    },
  });
}

function useGetVariableQueriesKeys() {
  const unitName = useBaseUrlExperimental();

  return {
    array: ["variables", unitName],
    mappedById: ["variablesMappedById", unitName],
  } as const;
}

export function useInvalidateVariablesQuery() {
  const cli = useQueryClient();
  const keys = useGetVariableQueriesKeys();

  return () => {
    cli.invalidateQueries(keys.array);
    cli.invalidateQueries(keys.mappedById);
  };
}

export function useRisksQuery({
  end,
  start,
  groupId,
  opts,
}: {
  start: YYYY_MM_DD;
  end: YYYY_MM_DD;
  groupId: string;
  opts?: BaseQueryOptions;
}) {
  const baseUrl = useBaseUrlExperimental();

  const risksQuery = useQuery({
    queryKey: [
      "risks",
      baseUrl,
      {
        end,
        start,
        groupId,
      },
    ],
    queryFn: () =>
      getRisks(baseUrl, {
        end,
        start,
        groupId,
      }),
    ...opts,
  });

  return risksQuery;
}

export function useAllAnomaliesForDayQuery(
  d: YYYY_MM_DD,
  opts?: BaseQueryOptions
) {
  const b = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["all-anomalies-for-day", b, d],
    queryFn: () => getAllAnomaliesForDayDRIPage(b, d),
    ...opts,
  });
}

export type BaseQueryOptions = Pick<
  UseQueryOptions,
  "staleTime" | "enabled" | "keepPreviousData" | "cacheTime"
> & { refetchOnMount?: boolean };

export function useAnomalousVariablesQuery(
  payload: {
    varIds?: string[];
    groupShortId?: string;
  },
  dateWithoutTime: YYYY_MM_DD,
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: [
      baseUrl,
      "anomalous-variables",
      dateWithoutTime,
      payload.varIds?.slice().sort(),
      payload.groupShortId,
    ],
    queryFn: async () => {
      const data = await getAnomaly(baseUrl, payload, dateWithoutTime);
      return anomalySchema.array().parse(data);
    },
    staleTime: minutesToMilliseconds(5),
    ...opts,
  });
}

export function useFreshAnomaliesQuery(
  payload: Partial<{
    deg1array: string[];
    deg2array: string[];
    deg3array: string[];
  }> & {
    date: YYYY_MM_DD;
  },
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  const allVariables = (payload.deg1array ?? [])
    .concat(payload.deg2array ?? [], payload.deg3array ?? [])
    .sort();

  return useQuery({
    queryKey: [baseUrl, "fresh-anomalies", payload.date, allVariables],
    queryFn: async () => {
      const out = await getFreshAnomalies(baseUrl, {
        date: payload.date,
        deg1array: (payload.deg1array ?? []).slice().sort(),
        deg2array: (payload.deg2array ?? []).slice().sort(),
        deg3array: (payload.deg3array ?? []).slice().sort(),
      });

      return out;
    },
    staleTime: minutesToMilliseconds(5),
    ...opts,
  });
}

export function useSlopesForGroupQuery({
  date,
  groupId,
  opts,
}: {
  date: YYYY_MM_DD;
  groupId: string;
  opts?: BaseQueryOptions;
}) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: [baseUrl, "group-slopes", groupId, date],
    queryFn: () =>
      getVariablesSlopes(baseUrl, {
        date,
        groupId,
      }),
    staleTime: minutesToMilliseconds(15),
    ...opts,
  });
}

export function useWatchlistMutation() {
  const baseUrl = useBaseUrlExperimental();
  const onSuccess = useInvalidateVariablesQuery();
  return useMutation({
    mutationFn: async (payload: { _id: string; watchlist: boolean }) => {
      return setWatchlist(baseUrl, payload._id, payload.watchlist);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      onSuccess();
    },
  });
}

export function usePublishedFaultTreesQuery() {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["fault-trees", "published-only", baseUrl],
    queryFn: () =>
      getFaultTrees(baseUrl).then((trees) => {
        return trees.filter((t) => t.published);
      }),
    refetchOnWindowFocus: false,
  });
}

export function useOperatingLimitsProbabilityQuery(
  payload: Parameters<typeof getOperatingLimitProbabilities>[1],
  opts?: BaseQueryOptions
) {
  const b = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["operating-limits-probabilities", b, payload],
    queryFn: () => {
      return getOperatingLimitProbabilities(b, payload);
    },
    refetchOnWindowFocus: false,
    ...opts,
  });
}

export function useVariablesArrayQuery(opts?: BaseQueryOptions) {
  const unitName = useBaseUrlExperimental();
  const key = useGetVariableQueriesKeys().array;

  return useQuery({
    queryKey: key,
    queryFn: () => getRawVariables(unitName),
    staleTime: minutesToMilliseconds(2),
    refetchOnMount: false,
    ...opts,
  });
}

export function useVariablesMappedByIdQuery(opts?: BaseQueryOptions) {
  const unitName = useBaseUrlExperimental();
  const key = useGetVariableQueriesKeys().mappedById;

  return useQuery({
    queryKey: key,
    queryFn: () =>
      getRawVariables(unitName).then((variables) =>
        Object.fromEntries(variables.map((v) => [v._id, v]))
      ),
    ...opts,
  });
}

export function useGroupsQueryKey() {
  const baseUrlSlash = useBaseUrlExperimental();
  return ["groups", baseUrlSlash] as const;
}

export function useInvalidateGroupsQuery() {
  const cli = useQueryClient();
  const key = useGroupsQueryKey();
  return () => {
    cli.invalidateQueries(key);
  };
}

export function useGroupsQuery(opts?: BaseQueryOptions) {
  const key = useGroupsQueryKey();

  return useQuery({
    queryKey: key,
    queryFn: async () => {
      const groups = await getGroups(key[1]);
      const groups_1 = groupSchema.array().parse(groups);
      return groups_1.filter((g) => !g.deleted);
    },
    staleTime: minutesToMilliseconds(3),
    ...opts,
  });
}

export function useGroupsMappedByIdQuery() {
  const baseUrlSlash = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["groupsMap", baseUrlSlash],
    queryFn: async () => {
      const groups = await getGroups(baseUrlSlash);
      const parsed = groupSchema.array().parse(groups);
      return R.pipe(
        parsed,
        R.filter((g) => !g.deleted),
        R.mapToObj((g) => {
          const { deleted, ...rest } = g;
          if (deleted) throw new Error("shouldn't be deleted");
          return [g._id, { ...rest, deleted: false as const }];
        })
      );
    },
  });
}

export const Comments = {
  list: {
    useKey: (b: BaseUrl) => {
      return [b, "comments-list"] as const;
    },
    useQuery: function (
      opts: GetCommentsListOpts,
      queryOpts?: BaseQueryOptions
    ) {
      const b = useBaseUrlExperimental();
      return useQuery({
        queryKey: [...this.useKey(b), opts],
        queryFn: () => getCommentsList(b, opts),
        refetchOnWindowFocus: false,
        ...queryOpts,
      });
    },
    useInvalidate: function () {
      const key = this.useKey(useBaseUrlExperimental());
      const cli = useQueryClient();
      return () => {
        cli.invalidateQueries(key);
      };
    },
  },
  single: {
    _baseKey: "singleComment",
    _getKey: function (b: BaseUrl, _id: string | undefined) {
      return [this._baseKey, b ?? "dummy", _id] as const;
    },
    useQuery: function (_id: string | undefined) {
      const b = useBaseUrlExperimental();

      return useQuery({
        queryKey: this._getKey(b, _id),
        queryFn: () =>
          getComment_TODO_VALIDATE(
            b,
            _id ?? "ensure you make use of enabled flag in useQuery"
          ),
        enabled: !!_id,
      });
    },
    useInvalidate: function () {
      const cli = useQueryClient();
      return () => cli.invalidateQueries([this._baseKey]);
    },
  },
  commentedVariables: {
    _baseKey: "commentedVariables",
    _getKey: function (b: BaseUrl, start: number, end: number) {
      return [this._baseKey, b, start, end] as const;
    },
    useQuery: function (start: number, end: number, opts?: BaseQueryOptions) {
      const b = useBaseUrlExperimental();

      return useQuery({
        queryKey: this._getKey(b, start, end),
        queryFn: () => getCommentedVariables(b, start, end),
        staleTime: minutesToMilliseconds(15),
        ...opts,
      });
    },
    useInvalidate: function () {
      const cli = useQueryClient();
      return () => cli.invalidateQueries([this._baseKey]);
    },
  },
};

export function useCommentsSummaryQuery(
  start: YYYY_MM_DD,
  end: YYYY_MM_DD,
  vids: string[],
  opts?: BaseQueryOptions
) {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  const payload = {
    start_date: moment.utc(start).startOf("day").toISOString(),
    end_date: moment.utc(end).endOf("day").toISOString(),
    variableIds: vids.slice().sort(),
  };

  return useQuery({
    queryKey: ["comments-summary", baseUrl, payload],
    queryFn: async () => {
      const cached = cli.getQueriesData({
        predicate(query) {
          if (!query.state.data) return false;
          if (!commentsSummarySchema.safeParse(query.state.data).success)
            return false;

          const key = query.queryKey;
          if (key[0] !== "comments-summary" || key[1] !== baseUrl) {
            return false;
          }

          const parsed = z
            .object({
              start_date: z.string(),
              end_date: z.string(),
              variableIds: z.array(z.string()),
            })
            .safeParse(key[2]);

          if (!parsed.success) return false;

          const { start_date, end_date, variableIds } = parsed.data;

          if (
            start_date !== payload.start_date ||
            end_date !== payload.end_date
          ) {
            return false;
          }

          if (variableIds.some((v) => payload.variableIds.includes(v)))
            return true;

          return false;
        },
      }) as [
        ["comments-summary", BaseUrl, typeof payload],
        commentsSummarySchema,
      ][];

      const vidsDoneAlready = new Set(
        cached
          .filter((x) => x[1])
          .map((x) => x[0][2].variableIds)
          .flat()
      );

      const allCached = cached.reduce(
        (acc, x) => {
          for (const [k, v] of Object.entries(x[1].variables_counts)) {
            acc[k] = v;
          }
          return acc;
        },
        {} as Record<string, number>
      );

      const need = payload.variableIds.filter((v) => !vidsDoneAlready.has(v));

      if (need.length === 0) {
        const out = Object.fromEntries(
          Object.entries(allCached).filter(([vid]) => {
            return payload.variableIds.includes(vid);
          })
        );

        return {
          variables_counts: out,
          total: Object.values(out).reduce((a, b) => a + b, 0),
        } satisfies commentsSummarySchema;
      }


      return await getCommentsSummary(baseUrl, {
        ...payload,
        variableIds: need,
      }).then((d) => {
        const needed = commentsSummarySchema.parse(d);

        for (const [k, v] of Object.entries(allCached)) {
          needed.variables_counts[k] = v;
          needed.total += v;
        }

        return needed;
      });
    },
    ...opts,
  });
}

export function useClustersQuery(opts?: BaseQueryOptions) {
  // todo naming
  return useAriaClustersQuery(opts);
  // const baseUrlSlash = useBaseUrlExperimental();
  // return useQuery({
  //   queryKey: ["clusters", baseUrlSlash],
  //   queryFn: async () => {
  //     const clusters = await getClusters(baseUrlSlash);
  //     return clusterSchema.array().parse(clusters);
  //   },
  // });
}

function useGetUsersQueryKey() {
  const baseUrlSlash = useBaseUrlExperimental();
  return ["users", baseUrlSlash] as const;
}

function useInvalidateUsersQuery() {
  const key = useGetUsersQueryKey();
  const cli = useQueryClient();
  return () => cli.invalidateQueries(key);
}

export function useUsersQuery() {
  const baseUrlSlash = useBaseUrlExperimental();
  return useQuery({
    queryKey: useGetUsersQueryKey(),
    queryFn: () => getUsers(baseUrlSlash),
  });
}

export function useTeamsQuery() {
  const baseUrlSlash = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["teams", baseUrlSlash],
    queryFn: () => getTeams(baseUrlSlash),
  });
}

const createOperatingLimitsQueryOptions = (b: BaseUrl, variableId: string) => {
  return {
    queryKey: ["operatingLimits", b, variableId],
    queryFn: async () => {
      const limits = await getLimitsForVariable(b, variableId);

      const out = limits
        .map(({ data, ...rest }) => ({
          ...rest,
          data: data.map(({ end, start, ...rest2 }) => ({
            ...rest2,
            end: end ? new Date(end).getTime() : null, // all this just to convert to Date
            start: new Date(start).getTime(), // all this just to convert to Date
          })),
        }))
        .sort((a, b) => {
          // I want them to be in the order of sortedLevels
          const aLevelIndex = sortedLevels.indexOf(a.level);
          const bLevelIndex = sortedLevels.indexOf(b.level);

          if (aLevelIndex === -1 || bLevelIndex === -1) {
            throw new Error("Unrecognized level");
          }

          return aLevelIndex - bLevelIndex;
        });

      return out;
    },
  } as const;
};

export function useOperatingLimitsQueries(
  vids: string[],
  opts?: BaseQueryOptions
) {
  const b = useBaseUrlExperimental();

  const queryOptions = useMemo(() => {
    const stale = minutesToMilliseconds(2);

    return vids.map((vid) => {
      const singleOptions = createOperatingLimitsQueryOptions(b, vid);

      return { ...singleOptions, staleTime: stale, ...opts };
    });
  }, [vids, b, opts]);

  return useQueries({
    queries: queryOptions,
  });
}

export function useOperatingLimitsQuery(
  variableId: string,
  opts?: BaseQueryOptions
) {
  const baseUrlSlash = useBaseUrlExperimental();

  const queryOptions = createOperatingLimitsQueryOptions(
    baseUrlSlash,
    variableId
  );

  return useQuery({
    ...queryOptions,
    staleTime: minutesToMilliseconds(2),
    ...opts,
  });
}

export function useOperatingLimitsProbabilityOrExceedanceVariablesQuery(
  enabled: boolean,
  ...args: APIRouteParamsWithoutBaseUrl<
    typeof getProbabilityOrExceedanceVariables
  >
) {
  const unitName = useBaseUrlExperimental();
  const [query] = args;
  return useQuery({
    queryKey: ["ol-probability-or-exceedance-variables", unitName, query],
    queryFn: () => getProbabilityOrExceedanceVariables(unitName, query),
    enabled,
    refetchOnWindowFocus: false,
    staleTime: minutesToMilliseconds(5),
    keepPreviousData: true, // we're using this data to render d3 chart, so while a new request fires off, keep the old data so the chart doesn't flicker (draws empty data then draws new data when it's avaialble)
  });
}

export function useExceedanceGroupCountsQuery(
  opts: BaseQueryOptions | undefined,
  ...args: APIRouteParamsWithoutBaseUrl<typeof getExceedanceGroupCounts>
) {
  const unitName = useBaseUrlExperimental();
  const [query] = args;
  return useQuery({
    queryKey: ["exceedance-group-counts", unitName, query],
    queryFn: () => getExceedanceGroupCounts(unitName, query),
    keepPreviousData: true, // we're using this data to render d3 chart, so while a new request fires off, keep the old data so the chart doesn't flicker (draws empty data then draws new data when it's avaialble)
    ...opts,
  });
}

function getShutdownRulesQueryKey(unitName: BaseUrl) {
  return ["shutdown-rules", unitName] as const;
}

export function useInvalidateShutdownRulesQuery() {
  const cli = useQueryClient();
  const unitName = useBaseUrlExperimental();
  const key = getShutdownRulesQueryKey(unitName);

  return () => {
    cli.invalidateQueries(key);
  };
}

export function useShutdownRulesQuery() {
  const unitName = useBaseUrlExperimental();
  const queryKey = getShutdownRulesQueryKey(unitName);

  return useQuery({
    queryKey: queryKey,
    queryFn: () => getShutdownRules(unitName),
  });
}

export function useAriaQuery(
  variables: string[],
  cluster: string,
  start: string,
  end: string,
  enabled: boolean
) {
  const unitName = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["aria", unitName, start, end, variables.slice().sort(), cluster],
    queryFn: () => {
      return getAriaTimeseries(unitName, variables, cluster, start, end);
    },
    enabled,
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
    keepPreviousData: true,
  });
}

export function useAnomalySummariesForDay(
  variables: string[],
  start: YYYY_MM_DD,
  end: YYYY_MM_DD,
  opts?: BaseQueryOptions
) {
  const unitName = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["anomaly-summaries", unitName, start, end, variables.sort()],
    queryFn: () => {
      return getAnomalySummariesForDay(unitName, {
        varIds: variables,
        start,
        end,
      });
    },
    ...opts,
  });
}

function usePersonalFoldersQueryKey() {
  const baseUrl = useBaseUrlExperimental();
  return [baseUrl, "personal-folders"] as const;
}

export const PersonalFoldersQuery = {
  useQuery: usePersonalFoldersQuery,
  useKey: usePersonalFoldersQueryKey,
  useInvalidate: () => {
    const key = usePersonalFoldersQueryKey();
    const cli = useQueryClient();

    return () => {
      cli.invalidateQueries(key);
    };
  },
};

export function usePersonalFoldersQuery(opts?: BaseQueryOptions) {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: usePersonalFoldersQueryKey(),
    queryFn: () => getFolders(baseUrl),
    staleTime: minutesToMilliseconds(5),
    ...opts,
  });
}

export function useClusterDriftScoreQuery(start: YYYY_MM_DD, end: YYYY_MM_DD) {
  const baseUrl = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["cluster-drift-score", baseUrl, start, end],
    queryFn: () => {
      return getClusterDriftScore(baseUrl, { start, end });
    },
  });
}

export function useAriaClustersQuery(
  opts: {
    at?: Date;
    filterType?: clusterSchema["type"];
    enabled?: clusterSchema["aria_enabled"];
  } = {}
) {
  const baseUrl = useBaseUrlExperimental();
  const { at, filterType, enabled } = opts;
  return useQuery({
    queryKey: ["aria-clusters", baseUrl, at?.getTime(), filterType, enabled],
    queryFn: () => {
      return getAriaClusters(
        baseUrl,
        at?.getTime() ?? undefined,
        filterType,
        enabled
      );
    },
    staleTime: minutesToMilliseconds(3),
  });
}

export function useToggleAria() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (clusterId: string) => {
      return toggleAria(baseUrl, clusterId);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["aria-clusters", baseUrl]);
    },
  });
}

export function useClusterMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (payload: {
      clusterId?: string;
      cluster: Partial<clusterSchema>;
    }) => {
      return saveCluster(baseUrl, payload.cluster, payload.clusterId);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["aria-clusters", baseUrl]);
    },
  });
}

export function useDeleteClusterMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (clusterId: string) => {
      return deleteCluster(baseUrl, clusterId);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["aria-clusters", baseUrl]);
    },
  });
}

export function useInstantCalculatorQuery(
  expressions: { expression: string; id: string }[],
  start: number,
  end: number,
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: [
      "instant-calculator",
      baseUrl,
      expressions.map((x) => `${x.id}${x.expression}`).sort(),
      start,
      end,
    ],
    queryFn: async () => {
      const data = await getInstantCalculation(
        baseUrl,
        expressions,
        start,
        end
      );
      return data;
    },
    refetchOnWindowFocus: false,
    ...opts,
  });
}

export function useSlopes(
  variableId: string,
  start: YYYY_MM_DD,
  end: YYYY_MM_DD,
  opts?: BaseQueryOptions
) {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["slopes", baseUrl, variableId, start, end],
    queryFn: async () => {
      return await getSlopes(baseUrl, variableId, start, end);
    },
    ...opts,
  });
}

export function useSlopeQuery(slopeId: string, enabled?: boolean) {
  const b = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["slope", b, slopeId],
    queryFn: () => getSlopeById(b, slopeId),
    enabled,
    staleTime: minutesToMilliseconds(20),
    refetchOnMount: false,
  });
}

export function useSaveExpressionMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (payload: {
      _id?: string;
      name: string;
      variables: SavedExpressionVariable[];
    }) => {
      return saveExpression(
        baseUrl,
        payload.name,
        payload.variables,
        payload._id
      );
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["saved-expressions", baseUrl]);
    },
  });
}

export function useUserMutation() {
  const baseUrl = useBaseUrlExperimental();
  const user = useUserRequired();
  // const cli = useQueryClient();
  return useMutation({
    mutationFn: (p: {
      email?: string;
      first?: string;
      last?: string;
      reports?: string[];
      role?: UserRoleString;
      preferred_order?: SortOrder;
    }) => updateUser(baseUrl, user._id, p),
    onError: (e) => addUnknownErrorToast(e),
    // orders saved as local state so don't actually need to update
    // onSuccess: () => {
    //   cli.invalidateQueries(["ft-order"]);
    //   cli.invalidateQueries(["pov-order"]);
    // },
  });
}

export function useFtOrder() {
  return useQuery({
    queryKey: ["ft-order"],
    queryFn: () => getFtSortOrder(),
  });
}

export function usePovOrder() {
  return useQuery({
    queryKey: ["pov-order"],
    queryFn: () => getPovSortOrder(),
  });
}

export function useSortOrderMutation() {
  // const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();
  const user = useUserRequired();

  return useMutation({
    mutationFn: async (p: { preferred_order: SortOrder }) => {
      return updateSortOrder(baseUrl, user._id, p);
    },
    onError: (e) => addUnknownErrorToast(e),
    // we don't wait for the mutation to finish to update the local state
    // onSuccess: () => {
    //   cli.invalidateQueries(["ft-order"]);
    //   cli.invalidateQueries(["pov-order"]);
    // },
  });
}

export function useDeleteSavedExpressionMutation() {
  const cli = useQueryClient();
  const baseUrl = useBaseUrlExperimental();

  return useMutation({
    mutationFn: async (_id: string) => {
      await deleteSavedExpression(baseUrl, _id);
    },
    onError: (e) => addUnknownErrorToast(e),
    onSuccess: () => {
      cli.invalidateQueries(["saved-expressions", baseUrl]);
      addToast({
        title: "Expression deleted",
        variant: "success",
      });
    },
  });
}

export function useSavedExpressionsQuery() {
  const baseUrl = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["saved-expressions", baseUrl],
    queryFn: async () => {
      const data = await getSavedExpressions(baseUrl);
      return data;
    },
  });
}

export enum TrendChartQueryType {
  Zoomed,
  Regular,
}

const minuteInMs = minutesToMilliseconds(1);
const oneDayMs = hoursToMilliseconds(24);
const hourlyThreshold = oneDayMs * 6;
const dailyThreshold = oneDayMs * 181;
// filter changepoints based on range of request
const resolution = (start: number, end: number): 1 | 60 | 1440 => {
  const range = end - start;
  return range >= dailyThreshold - minuteInMs
    ? 1440
    : range >= hourlyThreshold - minuteInMs
      ? 60
      : 1;
};

export function useSectionsQuery(opts?: BaseQueryOptions) {
  const b = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["sections", b, "groups"],
    queryFn: () => getSectionForUnit(b),
    // enabled: false, // flag because this feature isn't ready yet
    ...opts,
  });
}

export function useFtSectionsQuery(opts?: BaseQueryOptions) {
  const b = useBaseUrlExperimental();

  return useQuery({
    queryKey: ["sections", b, "trees"],
    queryFn: () => getFTSectionForUnit(b),
    // enabled: false, // flag because this feature isn't ready yet
    ...opts,
  });
}

export function useInvalidateSectionsQuery() {
  const cli = useQueryClient();
  const key = ["sections", useBaseUrlExperimental()];

  return () => {
    cli.invalidateQueries(key);
  };
}

function useTrendChartQuery(
  {
    end,
    start,
    variables,
    idk,
  }: {
    variables: string[];
    start: number;
    end: number;
    idk:
      | {
          type: TrendChartQueryType.Regular;
        }
      | {
          type: TrendChartQueryType.Zoomed;
          originalStart: number;
          originalEnd: number;
        };
  },
  opts?: BaseQueryOptions
) {
  const qc = useQueryClient();
  const b = useBaseUrlExperimental();

  const getTrendChartQueryKey = (start: number, end: number) => {
    const r = resolution(start, end);
    return [
      "trend-chart-query",
      b,
      variables.slice().sort(),
      r,
      start,
      end,
    ] as const;
  };

  const queryKey = getTrendChartQueryKey(start, end);

  return useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ signal }): Promise<DRATimeseries> => {
      /**
       * This was an optimization made to request less data if you're zooming
       * and the result of the zoom is a subset of what you already have in the
       * cache. I am commenting it out because it conflicts with auto-updating
       * that we wanna do every 1 minute, but we can revisit this later.
       */
      // for (const [key, data] of qc.getQueriesData<DRATimeseries>([
      //   queryKey[0],
      //   queryKey[1],
      //   queryKey[2],
      //   queryKey[3],
      // ])) {
      //   if (!data) continue;

      //   const thisDataStart = key[4];
      //   const thisDataEnd = key[5];

      //   if (
      //     typeof thisDataStart !== "number" ||
      //     typeof thisDataEnd !== "number"
      //   )
      //     continue;

      //   /**
      //    * If this data has a range that fully encompasses the range
      //    * we're looking for, then we can use this data. This is an
      //    * optimization to avoid making a new request.
      //    */
      //   const shouldUseThisData = thisDataStart <= start && thisDataEnd >= end;

      //   if (shouldUseThisData) return data;
      // }

      const { data: _data, changepoints: _changepoints } = await getTimeseries(
        b,
        {
          end: queryKey[5],
          start: queryKey[4],
          variables: queryKey[2],
          resolution: queryKey[3],
        },
        signal
      );

      const out = mergeChangepoints(_data, _changepoints);

      if (!idk) return out;
      if (idk.type !== TrendChartQueryType.Zoomed) return out;

      const originalData = qc.getQueryData<DRATimeseries>(
        getTrendChartQueryKey(idk.originalStart, idk.originalEnd)
      );

      if (!originalData) return out;

      for (const nonDownsampled of originalData) {
        const notWithinZoomedRange =
          nonDownsampled.timestamp < start || nonDownsampled.timestamp > end;

        if (notWithinZoomedRange) {
          out.push(nonDownsampled);
        }
      }

      return out;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: secondsToMilliseconds(30),
    refetchInterval: minutesToMilliseconds(1),
    ...opts,
  });
}

function useAcknowledgementsQuery(
  {
    end,
    start,
    variableId,
  }: {
    variableId: string;
    end: YYYY_MM_DD;
    start: YYYY_MM_DD;
  },
  opts?: BaseQueryOptions
) {
  const b = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["acks", b, variableId, end, start],
    queryFn: () =>
      getAcknowledgements(b, {
        end,
        start,
        varId: variableId,
      }),
    ...opts,
  });
}

export function useAllAcknowledgmentsForDayQuery(
  d: YYYY_MM_DD,
  opts?: BaseQueryOptions
) {
  const b = useBaseUrlExperimental();
  return useQuery({
    queryKey: ["all-acks-for-day", b, d],
    queryFn: () =>
      getAcknowledgements(b, {
        end: d,
        start: d,
      }),
    ...opts,
  });
}

const Acknowledgements = {
  list: {
    useQuery: ({
      end,
      start,
      variableId,
    }: {
      variableId: string;
      end: YYYY_MM_DD;
      start: YYYY_MM_DD;
    }) => {
      const b = useBaseUrlExperimental();
      return useQuery({
        queryKey: ["acks", b, variableId, end, start],
        queryFn: () =>
          getAcknowledgements(b, {
            end,
            start,
            varId: variableId,
          }),
      });
    },
    useInvalidate: () => {
      const cli = useQueryClient();
      return () => cli.invalidateQueries(["acks"]);
    },
  },
  acknowledgedVariables: {
    useQuery: (start: number, end: number, opts?: BaseQueryOptions) => {
      const b = useBaseUrlExperimental();
      return useQuery({
        queryKey: ["acknowledged-variables", b, start, end],
        queryFn: () => getAcknowledgedVariables(b, start, end),
        staleTime: minutesToMilliseconds(5),
        ...opts,
      });
    },
    useInvalidate: () => {
      const cli = useQueryClient();
      return () => cli.invalidateQueries(["acknowledged-variables"]);
    },
  },
};

namespace MyFitnessLimit {
  const listKey = "my-fitness-limits";
  const statsKey = "my-fitness-limits-stats";

  export function useStatsQuery(
    payload: {
      variableId: string;
      end: number;
      start: number;
    },
    opts?: BaseQueryOptions
  ) {
    const b = useBaseUrlExperimental();
    return useQuery({
      queryKey: [statsKey, b, payload.variableId, payload.start, payload.end],
      queryFn: () => {
        return getMyFitnessLimitsStatsForVariableId(b, payload);
      },
      refetchOnMount: false,
      ...opts,
    });
  }

  export function useListQuery(opts?: BaseQueryOptions) {
    const b = useBaseUrlExperimental();

    return useQuery({
      queryKey: [listKey, b],
      queryFn: () => getMyFitnessLimits(b),
      ...opts,
    });
  }

  export function useInvalidateListAndStatsQuery() {
    const cli = useQueryClient();
    const b = useBaseUrlExperimental();

    return useCallback(
      (variableId: string) => {
        cli.invalidateQueries([listKey]); // this is cheap to invalidate

        // stats are expensive to calculate, so we only want to invalidate specific ones
        cli.invalidateQueries([statsKey, b, variableId]);
      },
      [cli, b]
    );
  }
}

export {
  useInvalidateUsersQuery,
  useTrendChartQuery,
  useAcknowledgementsQuery,
  Acknowledgements,
  MyFitnessLimit,
};
