import assets from "../assets";
import type { HelpPage } from "./types";

const UserGuide: HelpPage = {
  "color": "#be4bdb",
  "sectiontitle": "User Guide",
  "url": "userguide",
  "subtitle": "Step-by-step instructions on how to use DRA",
  "subsections": [
      {
          "id": "subsection-5346",
          "title": "What is DRA?",
          "content": [
              {
                  "type": "paragraph",
                  "content": "**Dynamic Risk Analyzer** or **DRA** is an early risk detection and advanced warning software that identifies process problems at their initiation stage $$autonomously$$ – enabling operating personnel to take proactive corrective actions and prevent losses.",
                  "id": "content-5347",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "DRA is designed to analyze thousands of Variables (or Tags) every day. Process data for Variables is pulled from the Historian (also referred as Plant Information Management System or **PIMS**), a software program that records and retrieves process data (sensor data) by time. The information is then stored in a time series database in DRA that efficiently stores data with minimal disk space and fast retrieval.",
                          "id": "content-list-5349",
                          "marginLeft": "0"
                      },
                      {
                          "value": "DRA takes data from the Historian and compares it with historical data to compute the following key metrics:",
                          "id": "content-list-5350",
                          "marginLeft": "0"
                      },
                      {
                          "value": "**Degree of Anomaly (DA)**, calculated for each Variable, quantifies how a Variable’s profile differs from its historical profile. Categorized in 4 severity levels, the 3rd degree anomaly indicates a major change with respect to the long-term behavior, 2nd degree is moderate change, 1st degree is minor change, and 0 degree is normal behavior. The Variable profiles are not fixed – they update dynamically and autonomously each day using proprietary machine learning algorithms. The Anomaly information is shown in a combined **Trendchart**, which enables process improvement teams to identify changes over time.",
                          "marginLeft": "3",
                          "id": "content-list-5351"
                      },
                      {
                          "value": "**Dynamic Risk Index (DRI)**, calculated for each Group, is a composite index that signifies risk performance of a Group. It is calculated using a weighted average of Degree of Anomaly values for the Variables in that Group. The DRI values are shown using a **Petal Chart**.",
                          "marginLeft": "3",
                          "id": "content-list-5352"
                      }
                  ],
                  "id": "content-5348",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5353",
          "title": "DRA Reports",
          "content": [
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "All risk results are available on DRA dashboards (web-based) that are accessible to users easily via modern browsers like Edge, Chrome, Firefox, etc. In addition, reports summarizing risk activity for Yesterday are emailed to users automatically, typically between 2 am to 4 am local time. These reports show the list of Anomalies for Yesterday and by default are set to show the past 30 days of data (X-axis). DRA Admins can customize additional **Specialty Reports**, which can contain different sets of results and can be sent to specific users. Please see **Specialty Reports** section of the **Admin Guide** for additional information. These reports can be set to be sent Daily, Weekly, Biweekly, Monthly, on Weekends or at the end of Shifts during the day.",
                          "id": "content-list-5355",
                          "marginLeft": "0"
                      },
                      {
                          "value": "An example of a useful Specialty Report would be a report designed for operations engineers to review 3rd and 2nd degree anomalies daily, highlighting issues related to specific process areas.",
                          "id": "content-list-5356",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Due to email inbox size limitations, occasionally reports may be too large for email inboxes. If this occurs, users can download reports directly from the Reports Library section of the **Settings** page. From here, users can also download all past reports.",
                          "id": "content-list-5357",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Reports may appear blank during shutdowns. If users receive a blank report, please confirm that there are no shutdowns occurring. If there are no ongoing shutdowns, please contact NMM Support.",
                          "id": "content-list-5358",
                          "marginLeft": "0"
                      },
                      {
                          "value": "During **Back-Calculations**, DRA pulls historical data from the Historian and re-calculates DRI and Degree of Anomaly values. **$$Please note that modifying shutdown rules or operating modes, adding tags, or publishing fault trees will kick off back-calculations, which may lead to delays in daily processing and report generation.$$**",
                          "id": "content-list-5359",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5354",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5360",
          "title": "Accessing DRA",
          "content": [
              {
                  "type": "paragraph",
                  "content": "Open Edge or Google Chrome (or any modern browser) and type DRA website URL in the address bar at the top. You will arrive at the DRA Log-in screen. You can also bookmark this link or create a shortcut on your desktop for easy access to DRA.",
                  "id": "content-5361",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Enter your email and password for authentication and click Log In. Note that if the Active Directory integration is enabled for your site, enter your Company ID (e.g. james.smith, do not use your email) and password for authentication and click Log In.",
                          "id": "content-list-5363",
                          "marginLeft": "0"
                      },
                      {
                          "value": "To reset your password, click here. An email would be sent to you right away that includes a link to set a new password. Note that if the Active Directory integration is enabled for your site, this option is not available. ",
                          "id": "content-list-5364",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5362",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "login.png",
                  "codename": "login",
                  "id": "content-5365",
                  "content": assets.login,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5366",
          "title": "Navigation",
          "content": [
              {
                  "type": "paragraph",
                  "content": "**$$Main Navigation Bar$$**",
                  "id": "content-5367",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click the respective tab to switch over to the desired dashboard. Note that the **Overview**, **Dynamic Risk Index**, and **Profile Book** are core dashboards, whereas the **Fault Trees** and **Operating Fitness** are optional dashboards.",
                          "id": "content-list-5369",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to go to **Comments** page to view all the comments at once.",
                          "id": "content-list-5370",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to go to **Notifications** page to view a list of notification rules.",
                          "id": "content-list-5371",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to go to **Settings** page to view Tags, Groups, Shutdown Rules, Operating Modes, Clusters, Acknowledgement Summary, Report Library and Optional Modules (if any). If you are an Admin, an **Advanced Settings** page is available (instead of Settings page) that allows you to configure Tags, Groups, Shutdown Rules, Operating Modes, Clusters, Optional Modules (if any), Specialty Reports, and Users. Please refer to the Admin Guide for details. ",
                          "id": "content-list-5372",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to go to **Help** page to view Training Videos, User Guide, Admin Guide and Release Notes.",
                          "id": "content-list-5373",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to log out.",
                          "id": "content-list-5374",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5368",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**$$Floating Navigation Bar$$**",
                  "id": "content-5375",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to select a new **Group** (available on **Dynamic Risk Index** and **Profile Book** dashboards only).",
                          "id": "content-list-5377",
                          "customIndex": "7",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to select a **Date** for which you would like to see the results. This selected Date is also referred as **Analysis Period** in DRA. By default, the results for Yesterday are shown in **DRA Daily** and for Today in **DRA Real-Time**. Click on the left or right button to change the Date by one day.",
                          "id": "content-list-5378",
                          "customIndex": "8",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Displays the currently selected **Date Range** for charts. This is also referred as **X-axis Range** in DRA.",
                          "id": "content-list-5379",
                          "customIndex": "9",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to select a **Date Range** for charts. Default is 7-day.",
                          "id": "content-list-5380",
                          "customIndex": "10",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5376",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "navigation.png",
                  "codename": "navigation",
                  "id": "content-5381",
                  "content": assets.navigation,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5382",
          "title": "Overview dashboard",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Overview** dashboard presents the **Dynamic Risk Index** values for all Groups in one view.",
                  "id": "content-5383",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "Click on any Group name (highlighted) to view detailed risk information for that Group.",
                  "id": "content-5384",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "image",
                  "filename": "overviewdashboard.png",
                  "codename": "overviewdashboard",
                  "id": "content-5385",
                  "content": assets.overviewdashboard,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5386",
          "title": "Dynamic Risk Index dashboard",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Dynamic Risk Index** dashboard displays the daily composite risk value for a select Group, along with supporting risk information. The Dynamic Risk Index chart shows the daily risk indices (in form of petals) for the selected Date Range (X-axis Range). The **Daily Breakdown** chart presents the number of Variables for each associated Degree of Anomaly for the selected Date Range (X-axis Range). The petal and bar for the selected Date (Analysis Period) are indicated by a grey background.",
                  "id": "content-5387",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "The DRI numerical value for the selected **Date** (Analysis Period) is displayed at the center of the semi-circle.",
                          "id": "content-list-5389",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click on any petal or bar to change the selected **Date** (Analysis Period).",
                          "id": "content-list-5390",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Clicking on the 0°, 1°, 2°, 3° buttons either hides or brings up the respective **Degree of Anomaly** variables.",
                          "id": "content-list-5391",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5388",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "dridashboard1.png",
                  "codename": "dridashboard1",
                  "id": "content-5392",
                  "content": assets.dridashboard1,
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "The List of **Tags** displays the individual Variables based on their Degrees of Anomaly for the selected Date (Analysis Period) and Group. Within each of these sub-lists, Variables are then arranged in descending order of their total time spent in anomaly.",
                  "id": "content-5393",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click any Variable cell within the list to obtain its **Degree of Anomaly** chart and **Trendchart** on the right.",
                          "id": "content-list-5395",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to add the Variable to the **Watchlist**.",
                          "id": "content-list-5396",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to obtain information on the variable: e.g. Groups, Units, Calculated Expression (if any), Shutdown Rules, etc.",
                          "id": "content-list-5397",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to toggle between Expanded and Collapsed views of the chart.",
                          "id": "content-list-5398",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Hover over the Trendchart to view date, time, value, and operating mode (if any), based on the cursor position.",
                          "id": "content-list-5399",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view this Variable in a full-screen view. ",
                          "id": "content-list-5400",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Trendchart displays normal data in blue, anomaly data in magenta, and shutdown data in grey. Zoom in/out by placing your cursor at the desired position and scrolling in/out.",
                          "id": "content-list-5401",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to overlay another Variable.",
                          "id": "content-list-5402",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to adjust Y-axis range (also referred as **Y-clamping**).",
                          "id": "content-list-5403",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view Toggle options that allow you to enable/disable mode transparency and anomalies.",
                          "id": "content-list-5404",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to see **Clusters** associated with the Variable.",
                          "id": "content-list-5405",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to **Acknowledge** the Variable.",
                          "id": "content-list-5406",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to change the **Date Range** of this chart. This option is also referred as **Local Date Range** and allows you to adjust or set the date range of this Variable quickly. ",
                          "id": "content-list-5407",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to leave a **Comment**.",
                          "id": "content-list-5408",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view **Sloping Trends**.",
                          "id": "content-list-5409",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to create a **Personal Notification**.",
                          "id": "content-list-5410",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to reset the Trendchart.",
                          "id": "content-list-5411",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view/hide **Operating Modes** associated with the Variable.",
                          "id": "content-list-5412",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5394",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "dridashboard2.png",
                  "codename": "dridashboard2",
                  "id": "content-5413",
                  "content": assets.dridashboard2,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5414",
          "title": "Profile Book dashboard ",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Profile Book** dashboard allows you to view multiple variables quickly and create your own Folders of Variables.",
                  "id": "content-5415",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to search a specific Variable using any keyword.",
                          "id": "content-list-5417",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a full **List of Variables** displayed on the right.",
                          "id": "content-list-5418",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to switch to Sloping Trend view for all Variables.",
                          "id": "content-list-5419",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to clear all the Variables.",
                          "id": "content-list-5420",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5416",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "Two types of Folders are available to you – Smart Folders and Personal Folders. Smart Folders are populated for a given Date (Analysis Period) and Group and are auto-updated by the system. The current Smart Folders include Watchlist, 3°, 2°, 1° anomaly variables, Fresh 3°, 2°, 1° anomaly variables, and Long-term, Medium-term and Short-term Sloping Trends. ",
                  "id": "content-5421",
                  "marginTop": "-1",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "Note that a Fresh anomaly indicates a newly developing risk in the process. It is defined as follows: when a variable's degree of anomaly for a day is greater than its degree of anomaly for the previous day, it is considered a Fresh or New anomaly. That is, if the degree of anomaly goes down or remains the same compared to the previous day, it is not considered as a Fresh anomaly. Although the Fresh anomalies highlight newly developing risks, they do not include ongoing anomalies that originated earlier, which could be indicative of a worsening issue. To ensure that important risk indicators are not missed, users are strongly recommended to review the full list of anomalies. ",
                  "id": "content-5422",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "You can also define your own Personal Folders for easy access to specific sets of Variables. Unlike the Smart Folders, the Personal Folders are private and are only visible to you. Hence, with Personal Folders, you can monitor your favorite variables quickly.",
                  "id": "content-5423",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to create a new $$Personal Folder$$, which launces a creation form. In the form, (a) type a name for the Folder, (b) search or bring desired Variables to ‘In this folder’ space, and (c) click $$Submit$$.",
                          "id": "content-list-5425",
                          "customIndex": "5",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to edit or delete an existing Personal Folder.",
                          "id": "content-list-5426",
                          "customIndex": "6",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a $$Multiview$$. When you overlay multiple Variables, you can also save them as a $$Multiview$$ for easy access later. Similar to the Personal Folders, the Multiviews are private and are only visible to you.",
                          "id": "content-list-5427",
                          "customIndex": "7",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to edit or delete an existing $$Multiview$$.",
                          "id": "content-list-5428",
                          "customIndex": "8",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5424",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "profilebook.png",
                  "codename": "profilebook",
                  "id": "content-5429",
                  "content": assets.profilebook,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5430",
          "title": "Post Comments and Replies",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Trendcharts** on Dynamic Risk Index and Profile Book dashboards allow you to enter comments. To create a comment, click on **Add a Comment** button in the bottom menu. To adjust its date range (if necessary), simply drag the start and end bar handles. Once a comment entry box opens up, follow the steps below:",
                  "id": "content-5431",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Enter comment text.",
                          "id": "content-list-5433",
                          "marginLeft": "0"
                      },
                      {
                          "value": "If you would like to keep the comment private, check **‘Add to my notes’** – the comment is now only visible to you. Keep it unchecked if you would like the comment to be visible to all the users.",
                          "id": "content-list-5434",
                          "marginLeft": "0"
                      },
                      {
                          "value": "If you would like to create an open-ended process issue, check **Open Issue**. See **Open and Close an Issue** section for details.",
                          "id": "content-list-5435",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Select Users or Teams whom you would like to tag. These tagged users or teams receive an email notification immediately after the comment is posted.",
                          "id": "content-list-5436",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Select additional Variables that you would like to include.",
                          "id": "content-list-5437",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Select Labels that you would like to assign.",
                          "id": "content-list-5438",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click **Post** to publish your comment in DRA.",
                          "id": "content-list-5439",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Once a comment is published, Users can post replies and form a conversation thread.",
                          "id": "content-list-5440",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5432",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "comments.png",
                  "codename": "comments1",
                  "id": "content-5441",
                  "content": assets.comments1,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5442",
          "title": "Open and Close an Issue",
          "content": [
              {
                  "type": "paragraph",
                  "content": "You can highlight a process problem that has not been addressed by creating an **Open Issue** in DRA. This capability helps you to highlight ongoing or known problems in your unit. Once the problem is resolved, you can easily close the issue. Both open and closed issues are visible to all users via the **Comments** page. This provides an excellent way to capture process issues as they occur and keep them visible until they get resolved.",
                  "id": "content-5443",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Once an **Open Issue** is created, the associated time frame is marked with a pink background on the Trendchart.",
                          "id": "content-list-5445",
                          "marginLeft": "0"
                      },
                      {
                          "value": "The end-date is marked as **Ongoing**.",
                          "id": "content-list-5446",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Users can post replies by clicking on the **Reply** button.",
                          "id": "content-list-5447",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Once the problem is resolved, the Open Issue can be closed by typing a reply and clicking **Reply & Close Issue**.",
                          "id": "content-list-5448",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5444",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "comments2.png",
                  "codename": "comments2",
                  "id": "content-5449",
                  "content": assets.comments2,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5450",
          "title": "Comments Page",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Comments** page shows all comments in DRA, in a chronological order (newest first).",
                  "id": "content-5451",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Search the comments repository using any keyword.",
                          "id": "content-list-5453",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Filter the comments based on Labels.",
                          "id": "content-list-5454",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to change the timeline view – options are Monthly (default) or Yearly views.",
                          "id": "content-list-5455",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Displays the total number of Comments. Click to download them in an Excel format.",
                          "id": "content-list-5456",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Switch between **‘Comments’** and **‘My Notes’** tabs. The comments in **‘Comments’** tab are visible to all users, where the comments in **‘My Notes’** tab are only visible to you.",
                          "id": "content-list-5457",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Displays the chart thumbnail where the comment was entered. Click to open the chart in a full screen view.",
                          "id": "content-list-5458",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to add a **Reply**. ",
                          "id": "content-list-5459",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to delete or edit the Comment. ",
                          "id": "content-list-5460",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to transfer to a dashboard and view the comment.",
                          "id": "content-list-5461",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5452",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "comments3.png",
                  "codename": "comments3",
                  "id": "content-5462",
                  "content": assets.comments3,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5463",
          "title": "Personal Notification",
          "content": [
              {
                  "type": "paragraph",
                  "content": "You can create a **Personal Notification** and get notified when its notification rule is satisfied. Three types of personal notifications are available: **Pattern, Standard,** and **Custom.** These notifications can be created for DRA, Sloping Trend, Fault Trees, and Operating Fitness modules. \nStandard Notifications allow you to subscribe to existing standard criteria in DRA. For example, you can get notified when a tag experiences a 3rd degree anomaly or a long-term trend. The standard notifications can be created for DRA, Sloping Trend, Fault Trees, and Operating Fitness modules.",
                  "id": "content-5464",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "To create a personal notification in DRA, click **Notification Bell** button on bottom right corner of a Trendchart. This allows you to create a pattern / standard / custom notifications or view existing notifications. The different types of notifications are explained next.",
                  "id": "content-5465",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "image",
                  "filename": "Notification1.png",
                  "codename": "notifications1",
                  "id": "content-5466",
                  "content": assets.notifications1,
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Pattern Notifications** allow you to create a specific pattern and get notified when a match is detected by DRA. ",
                  "id": "content-5467",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "To create a pattern notification, click **Plus (+ Pattern)** button to launch a form. ",
                          "id": "content-list-5469",
                          "marginLeft": "0"
                      },
                      {
                          "value": "To adjust date range of the pattern, simply drag the start and end bar handles on the Trendchart. ",
                          "id": "content-list-5470",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Select a **Pattern Matching Rule.** You can choose to get notified when there is an Identical Match, or when there is an Identical or Probable Match, or when the percentage of match exceeds a threshold (to be specified by the user).",
                          "id": "content-list-5471",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Select a **Notification Frequency.** You can choose to get notified immediately throughout the day or for the first occurrence of the day.",
                          "id": "content-list-5472",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click **Save.** A message will indicate that the **Pattern Notification** has been successfully created.",
                          "id": "content-list-5473",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5468",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "Notification2.png",
                  "codename": "notifications2",
                  "id": "content-5474",
                  "content": assets.notifications2,
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Standard Notifications** allow you to subscribe to standard, pre-defined criteria. For example, you can get notified when a tag experiences a 3rd degree anomaly or a long-term trend. To create a standard notification, click **Plus (+ Standard)** button to launch a form, and select one or more criteria from the dropdown menu to create notification(s).",
                  "id": "content-5475",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "Notification3.png",
                  "codename": "notifications3",
                  "id": "content-5476",
                  "content": assets.notifications3,
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Custom Notifications** allow you to subscribe to your own calculated rules. You can build powerful rules by using different functions from the DRA library. See **‘List of Operators/Functions’** section for a full library of functions. For example, you can get notified when a Variable’s rolling mean (over the last hour) exceeds a limit. ",
                  "id": "content-5477",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "To create a Custom Notification, click **Plus (+ Custom)** button to launch a form.",
                          "id": "content-list-5479",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Enter an expression for the **Notification Rule** – a Boolean Expression, which must be always either True or False. Note that the Tag IDs are case-sensitive and must be referenced in double quotes.",
                          "id": "content-list-5480",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Select a **Notification Frequency.** You can choose to get notified immediately throughout the day or for the first occurrence of the day.",
                          "id": "content-list-5481",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click **Save.** A message will indicate that the **Custom Notification** has been successfully created. Note that the custom notifications can also be created directly from the **Notifications** page.",
                          "id": "content-list-5482",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5478",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "Custom1.png",
                  "codename": "custom1",
                  "id": "content-5483",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.custom1
              },
              {
                  "type": "paragraph",
                  "content": "To create a personal notification in Fault Trees, click **Notification Bell** button and select ‘Node is active’.",
                  "id": "content-5484",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "Custom2.png",
                  "codename": "custom2",
                  "id": "content-5485",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.custom2
              },
              {
                  "type": "paragraph",
                  "content": "To create a personal notification in Operating Fitness, click **Notification Bell** button and follow similar steps described above.",
                  "id": "content-5486",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "Custom3.png",
                  "codename": "custom3",
                  "id": "content-5487",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.custom3
              },
              {
                  "type": "paragraph",
                  "content": "**$$Note that the personal notification capability is available with DRA Real-Time only.$$**",
                  "id": "content-5488",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5489",
          "title": "Notifications page",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Notifications** page shows all your personal notifications, in a chronological order (newest first).",
                  "id": "content-5490",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Displays the total number of Notifications. Click the **Download** button to download them in an Excel format.",
                          "id": "content-list-5492",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Search the Notifications repository using any keyword.",
                          "id": "content-list-5493",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Shows the Notification type. The different types are DRA, Sloping Trend, Fault Trees, Operating Fitness and Custom.",
                          "id": "content-list-5494",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to enable (blue) or disable (grey) the Notification.",
                          "id": "content-list-5495",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to delete the Notification.",
                          "id": "content-list-5496",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to transfer to a dashboard and view the notification.",
                          "id": "content-list-5497",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to launch a form to create a **Custom Notification**. Once a form opens up, follow the following steps.",
                          "id": "content-list-5498",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Enter an expression for the **Notification Rule** – a Boolean Expression, which must be always either True or False. See **‘List of Supported Operators/Functions’** section for a full library of functions. Note that the Tag IDs are case-sensitive and must be referenced in double quotes.",
                          "id": "content-list-5499",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Select a **Notification Frequency**. You can choose to get notified immediately throughout the day or for the first occurrence of the day.",
                          "id": "content-list-5500",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click $$Save$$. A message will indicate that the **Custom Notification** has been successfully created.",
                          "id": "content-list-5501",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5491",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "notifications4.png",
                  "codename": "notifications4",
                  "id": "content-5502",
                  "content": assets.notifications4,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5503",
          "title": "Settings Page",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Settings** page provides information on Tags, Groups, Shutdowns Rules, Operating Modes, Clusters, Acknowledgment Summary, and Reports Library. Note that the information is read-only and is visible to all Users. ",
                  "id": "content-5504",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "If you are an Admin, an **Advanced Settings** page is available (instead of Settings page) that allows you to configure Tags, Groups, Shutdown Rules, Operating Modes, Clusters, Optional Modules (if any), Specialty Reports, and Users. Please refer to the Admin Guide for details. ",
                  "id": "content-5505",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to view a list of **Tags** for the Unit. ",
                          "id": "content-list-5507",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a list of **Groups**.",
                          "id": "content-list-5508",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a list of **Shutdown Rules**.",
                          "id": "content-list-5509",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a list of **Operating Modes**.",
                          "id": "content-list-5510",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a list of **Clusters**.",
                          "id": "content-list-5511",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a summary of **Acknowledgments**.",
                          "id": "content-list-5512",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view and download **Reports**.",
                          "id": "content-list-5513",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a list of published **Fault Trees** (visible only when the Fault Trees module is configured for your Unit).",
                          "id": "content-list-5514",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view a list of **Operating Fitness** limits (visible only when the Operating Fitness module is configured for your Unit).",
                          "id": "content-list-5515",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5506",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "settings1.png",
                  "codename": "settings1",
                  "id": "content-5516",
                  "content": assets.settings1,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5517",
          "title": "Settings – Tags",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Tags** page shows a list of all configured Tags, including calculated Tags, in DRA.",
                  "id": "content-5518",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to download a full list of Tags in an Excel format.",
                          "id": "content-list-5520",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Search the Tag List using any keyword.",
                          "id": "content-list-5521",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Filter the Search results based on a select attribute (Tag ID, description, expression, groups, or units of measurement). ",
                          "id": "content-list-5522",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Sort the Tag list by last updated (default) or alphabetical order.",
                          "id": "content-list-5523",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Filter the Tag list by Calculated Tags, Historian Tags, High Side Anomaly ON/OFF, Low Side Anomaly ON/OFF, Sloping Trends Positive/Negative/OFF.",
                          "id": "content-list-5524",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view information on Tag ID, description, anomaly and sloping trend configuration, unit of measurement, and Group.",
                          "id": "content-list-5525",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5519",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "settings2.png",
                  "codename": "settings2",
                  "id": "content-5526",
                  "content": assets.settings2,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5527",
          "title": "Settings – Groups",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Groups** page shows all the configured Groups, along with the associated number of Tags. ",
                  "id": "content-5528",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Displays total number of Tags in a Group.",
                          "id": "content-list-5530",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view associated Tags in a Group.",
                          "id": "content-list-5531",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5529",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "settings3.png",
                  "codename": "settings3",
                  "id": "content-5532",
                  "content": assets.settings3,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5533",
          "title": "Settings – Shutdown Rules",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Shutdown Rules** page shows a list of configured shutdown rules in DRA. Information on shutdown ID, description, expression, associated Groups/Tags, and buffer time is included.",
                  "id": "content-5534",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to download a list of all shutdown rules in an Excel format.",
                          "id": "content-list-5536",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5535",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "settings4.png",
                  "codename": "settings4",
                  "id": "content-5537",
                  "content": assets.settings4,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5538",
          "title": "Settings – Operating Modes",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Operating Modes** page shows a list of configured operating modes in DRA. Information on operating mode ID, description, expression, and associated Groups/Tags is included.",
                  "id": "content-5539",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "image",
                  "filename": "settings5.png",
                  "codename": "settings5",
                  "id": "content-5540",
                  "content": assets.settings5,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5541",
          "title": "Settings – Custom Clusters",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Custom Clusters** page shows a list of configured custom clusters in DRA. Information on cluster name, description and associated Tags is included.",
                  "id": "content-5542",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "image",
                  "filename": "settings6.png",
                  "codename": "settings6",
                  "id": "content-5543",
                  "content": assets.settings6,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5544",
          "title": "Settings - Teams",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Teams** page shows a list of configured Teams in DRA. These are teams of users, which can easily be tagged in a comment or subscribed to a report. Information on team name and associated members is included.",
                  "id": "content-5545",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "Teams1.png",
                  "codename": "teams1",
                  "id": "content-5546",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.teams1
              }
          ]
      },
      {
          "id": "subsection-5547",
          "title": "Settings – Acknowledgement Summary",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Acknowledgements Summary** page lists which variables were acknowledged for a given date, when and by whom.",
                  "id": "content-5548",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Filter the list based on Degree of Anomaly levels.",
                          "id": "content-list-5550",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to change the Selected Date.",
                          "id": "content-list-5551",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Filter the list based on All Anomalies (default) or Fresh Anomalies.",
                          "id": "content-list-5552",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Filter the list based on a Group.",
                          "id": "content-list-5553",
                          "marginLeft": "0"
                      },
                      {
                          "value": "First choose a summary option from the dropdown and then click **Email** button – an Excel summary will be emailed to you in a few minutes. The following summaries are available: (a) **Acknowledgement Summary**: day-by-day breakdown of total anomalies vs. acknowledged anomalies for the history (note: days with no anomalies are excluded). (b) **List of Anomaly Tags**: list of anomaly tags and their acknowledgement status for a given Date. (c) **Top Acknowledgers**: list of users with highest acknowledgements, summarized on monthly and yearly basis.",
                          "id": "content-list-5554",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Displays total number of anomaly variables (for the Selected Date and Filters).",
                          "id": "content-list-5555",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Displays number of anomaly variables acknowledged. Click to switch to this tab.",
                          "id": "content-list-5556",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Displays number of anomaly variables that remain unacknowledged. Click to switch to this tab.",
                          "id": "content-list-5557",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Search through the list using a keyword.",
                          "id": "content-list-5558",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5549",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "settings7.png",
                  "codename": "settings7",
                  "id": "content-5559",
                  "content": assets.settings7,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5560",
          "title": "Settings – Reports Library",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The **Reports Library** page allows you to view and download past **Reports** (also referred as **Specialty Reports)**.",
                  "id": "content-5561",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to select a Date. Click on the left or right button to change the date selection by one-day.",
                          "id": "content-list-5563",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to download a Report.",
                          "id": "content-list-5564",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5562",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "settings8.png",
                  "codename": "settings8",
                  "id": "content-5565",
                  "content": assets.settings8,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5566",
          "title": "Settings – Fault Trees",
          "content": [
              {
                  "type": "paragraph",
                  "content": "$$Note that this page is available only when the Fault Trees module is configured for your Unit.$$",
                  "id": "content-5567",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "The **Fault Trees** page displays all the published fault trees and the associated number of nodes for each fault tree.",
                  "id": "content-5568",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to view the fault tree and obtain information on individual fault tree nodes.",
                          "id": "content-list-5570",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5569",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "settings9.png",
                  "codename": "settings9",
                  "id": "content-5571",
                  "content": assets.settings9,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5572",
          "title": "Settings – Operating Fitness",
          "content": [
              {
                  "type": "paragraph",
                  "content": "$$Note that this page is available only when the Operating Fitness module is configured for your Unit$$.",
                  "id": "content-5573",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "The **Operating Fitness** page displays the Tags with their configured Limits.",
                  "id": "content-5574",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": false,
                  "content": [
                      {
                          "value": "Click to download a list of configured Limits in an Excel format.",
                          "id": "content-list-5576",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to search the list by Tag ID or Description.",
                          "id": "content-list-5577",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Click to view information on Limits for a specific Tag. ",
                          "id": "content-list-5578",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5575",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "settings10.png",
                  "codename": "settings10",
                  "id": "content-5579",
                  "content": assets.settings10,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5580",
          "title": "How to Use DRA in 4 Steps",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The following scenario illustrates how DRA may be used for early detection and diagnosis of process issues. Outlined is a potential flow of events and information – with decisions that a DRA user could take to identify the problem proactively.",
                  "id": "content-5581",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Step 1: Review Overview Dashboard**",
                  "id": "content-5582",
                  "marginTop": "0.5",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "It is a typical morning: the user looks at the Overview Dashboard (7-day view) to check the status of the plant operations and notes the changes. Note: It is recommended that the users look at the results at least daily.",
                          "id": "content-list-5584",
                          "marginLeft": "0"
                      },
                      {
                          "value": "The user notices that the petal chart for Furnace Group displays an escalation in Dynamic Risk Index value from moderate to high in the last day.",
                          "id": "content-list-5585",
                          "marginLeft": "0"
                      },
                      {
                          "value": "The user switches to the 14-day view to see how this high-risk level compares to the behavior observed in the previous week.",
                          "id": "content-list-5586",
                          "marginLeft": "0"
                      },
                      {
                          "value": "As the user scans its full range, the chart indicates that there was little or no abnormal activity in the previous week, whereas there was an escalation in the abnormal activity this week.",
                          "id": "content-list-5587",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Given this steady escalation trend, the user decides to investigate activity within the Furnace Group further – by transitioning to its DRI Dashboard.",
                          "id": "content-list-5588",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5583",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "drasteps1.png",
                  "codename": "drasteps1",
                  "id": "content-5589",
                  "content": assets.drasteps1,
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Step 2: Check Daily Breakdown of Anomalies**",
                  "id": "content-5590",
                  "marginTop": "1",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Next, the user looks at the corresponding Daily Breakdown chart. Here, variables for the Furnace Group are listed together according to their Degree of Anomaly.",
                          "id": "content-list-5592",
                          "marginLeft": "0"
                      },
                      {
                          "value": "In this week (Feb. 4th – Feb. 10th), yesterday experienced the highest number of 3°degree of anomaly. Further, in the last 3 days, the total number of Variables experiencing anomalies increased significantly. For the selected day (Feb. 10th), the number of anomaly variables jumped, after seeing a rapid increase in anomalies from the days before.",
                          "id": "content-list-5593",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Given that a sizeable percentage of Variables in the Furnace Group are now indicating a high degree of abnormality, the user considers that something might be wrong since there was no planned change in the operating conditions. If the risk has indeed worsened in the Furnace Group, as indicated by the group’s risk chart, it needs attention to identify the cause of such a trend.",
                          "id": "content-list-5594",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5591",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "drasteps2.png",
                  "codename": "drasteps2",
                  "id": "content-5595",
                  "content": assets.drasteps2,
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Step 3: Examine Anomaly List and Trendcharts**",
                  "id": "content-5596",
                  "marginTop": "1",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "To look for a potential problem in the Furnace Group, the user looks over to the List of Variables (which provides information on anomaly values for all the Variables on the Date Selected).",
                          "id": "content-list-5598",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Going over the List of Variables for Feb. 10th, the user first checks all 3° and 2° anomalies and then looks for the historical performance of each one for the last several weeks.",
                          "id": "content-list-5599",
                          "marginLeft": "0"
                      },
                      {
                          "value": "In doing so, the user notices that the anomaly level of Main Air to Rx Furnace has escalated from 1° to 3° within the last 2 days. To understand when this escalation trend started, the user reviews the 30-day trend and realizes that in the past few weeks, this is the first time this Variable has experienced a 3° anomaly. The user further notices that the readings are getting to be too low– as indicated by purple data points. The user then proceeds to look over other Variables in the list, which also experienced similar anomalies.",
                          "id": "content-list-5600",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5597",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "drasteps3.png",
                  "codename": "drasteps3",
                  "id": "content-5601",
                  "content": assets.drasteps3,
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Step 4: Enter Comments (If Necessary)**",
                  "id": "content-5602",
                  "marginTop": "1",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "After going over the different charts, the user concludes three possible scenarios as root-causes for these anomalies. They are: (a) deterioration of the inlet valve to Furnace, (b) detuning of the control scheme that controls furnace temperature, and (c) irregular heat pockets in the Furnace.",
                          "id": "content-list-5604",
                          "marginLeft": "0"
                      },
                      {
                          "value": "The user checks the maintenance records to see if the above issues were discussed or fixed recently. Upon discussing the issue with the operators, the team discovers that the inlet valve was indeed wearing out, whereas the control scheme and heat flow were up to the mark.",
                          "id": "content-list-5605",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Thus, by using DRA diagnostically, the user was able to drill-down into a problem typically overlooked by existing control systems and the human eye – and pinpoint an issue in its early stage.",
                          "id": "content-list-5606",
                          "marginLeft": "0"
                      },
                      {
                          "value": "As outlined here, a similar 10 to 15 minutes of daily DRA analysis can enable engineers and plant management to remain on top of the process issues and take preventative measures.",
                          "id": "content-list-5607",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5603",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "drasteps4.png",
                  "codename": "drasteps4",
                  "id": "content-5608",
                  "content": assets.drasteps4,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5609",
          "title": "Pattern Matching",
          "content": [
              {
                  "type": "paragraph",
                  "content": "The Pattern Matching capability in DRA enables you to specify a pattern and easily identify instances when it occurred historically, and more importantly, when it occurs in real-time.",
                  "id": "content-5610",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "Let us take an example of a Conductivity tag in a process.",
                  "id": "content-5611",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "image",
                  "filename": "Pattern1.png",
                  "codename": "pattern1",
                  "id": "content-5612",
                  "content": assets.pattern1,
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "Say, the above pattern occurred in January 2022 and you would like to know: (a) when this pattern occurred historically, or (b) when this pattern occurs in real-time. There are a few options available to you:",
                  "id": "content-5613",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**1) If you are a User, you can create a pattern notification in DRA:**",
                  "id": "content-5614",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "Pattern2.png",
                  "codename": "pattern2",
                  "id": "content-5615",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.pattern2
              },
              {
                  "type": "paragraph",
                  "content": "See **‘Create a Personal Notification’** section for details.",
                  "id": "content-5616",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**2) If you are an Admin, you can create a calculated tag in DRA with the following expression:** %%pattern_match(\"QI100\", \"2022-01-01 11:10\", \"2022-01-01 13:00\")%%\nOnce a calculated tag has been created in DRA, it indicates the following:\n",
                  "id": "content-5617",
                  "marginTop": "0",
                  "marginBottom": "1"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": " A value of 2 indicates that there is an **Identical Match** at that time",
                          "id": "content-list-5619",
                          "marginLeft": "0"
                      },
                      {
                          "value": "A value of 1 indicates that there is a **Probable Match** at that time",
                          "id": "content-list-5620",
                          "marginLeft": "0"
                      },
                      {
                          "value": "A value of 0 indicates that there is **No Match** at that time",
                          "id": "content-list-5621",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5618",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**3) If you are an Admin, you can also create a Fault Tree node with the following expression:**",
                  "id": "content-5622",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Fault Tree node becomes Active when there is an Identical Match: %%pattern_match(\"QI100\", \"2022-01-01 11:10\", \"2022-01-01 13:00\") == 2%%",
                          "id": "content-list-5624",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Fault Tree node becomes Active when there is an Identical or Probable Match: %%pattern_match(\"QI100\", \"2022-01-01 11:10\", \"2022-01-01 13:00\") >= 1%%",
                          "id": "content-list-5625",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5623",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**$$Note that the pattern matching capability is available with DRA Real-Time only.$$**",
                  "id": "content-5626",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5627",
          "title": "Instant Calculator",
          "content": [
              {
                  "type": "paragraph",
                  "content": "Instant Calculator introduces a robust capability that enables real-time evaluation of mathematical expressions based on tags. You can easily create both simple or complex expressions and receive results immediately. Examples include %%rolling_mean(\"75TI100.PV\", 12)%%, %%mean(\"TI100.PV\", \"TI200.PV\", \"TI300.PV\")%%, etc. Additionally, it allows you to save these expressions as Personal Expressions and access them later, similar to Personal Folders and Personal Multiviews. ",
                  "id": "content-5628",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "Note that DRA supports 50+ functions in mathematical expressions and they can be used seamlessly in the Instant Calculator.  ",
                  "id": "content-5629",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "instantcalc.png",
                  "codename": "instantcalc",
                  "id": "content-5630",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.instantcalc
              }
          ]
      },
      {
          "id": "subsection-5631",
          "title": "Process Capability Analysis",
          "content": [
              {
                  "type": "paragraph",
                  "content": "DRA's Variability Dynamics module enables you to do a comprehensive ‘Process Capability Analysis’ and easily obtain standard capability metrics, such as, **Cp**, **Cpk**, **CCpk**, and **Cpm**. This analysis evaluates the spread of process data relative to specification limits, known as the Upper Specification Limit (USL) and Lower Specification Limit (LSL). A process is considered ‘capable’ when it maintains the process spread within the specification spread. This analysis also indicates whether the process is centered around a specified Target limit.   ",
                  "id": "content-5632",
                  "marginTop": "0",
                  "marginBottom": "1"
              },
              {
                  "type": "paragraph",
                  "content": "After the **USL**, **LSL**, and **Target** values are specified, the system computes the standard capability metrics: Cp, Cpk, CCpk, and Cpm. For example, if Cpk is lower than the industry benchmark of 1.33, the operating team should consider ways to enhance process performance, such as reducing its variability or shifting its mean. Additionally, the system automatically performs power transformations of non-normal data when necessary.  ",
                  "id": "content-5633",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "capability.png",
                  "codename": "capability",
                  "id": "content-5634",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.capability
              },
              {
                  "type": "paragraph",
                  "content": "The capability metrics are defined as follows: ",
                  "id": "content-5635",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Cp = (USL - LSL)/(tolerance*σ) ",
                          "id": "content-list-5637",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Cpk =min(CPL, CPU), where CPL=(µ-LSL)/(σ*tolerance/2) and CPU=(USL- µ)/(σ*tolerance/2) ",
                          "id": "content-list-5638",
                          "marginLeft": "0"
                      },
                      {
                          "value": "CCpk = (USL-Target)/(σ*tolerance/2) when only USL is present, CCpk = (Target-LSL)/(σ*tolerance/2) when only LSL is present, CCpk = (min(USL-Target, Target-LSL))/(σ*tolerance/2) when both USL and LSL are present ",
                          "id": "content-list-5639",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Cpm = (USL-LSL)/(tolerance*((σ^2 + (µ-Target)^2)^0.5)) ",
                          "id": "content-list-5640",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5636",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "where, **µ** is process mean, **σ** is process standard deviation, and **tolerance** is a multiplier, set to 6 by default.  ",
                  "id": "content-5641",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "Reference: NIST/SEMATECH Engineering Statistics Handbook,\nhttps://www.itl.nist.gov/div898/handbook/pmc/section1/pmc16.htm",
                  "id": "content-5642",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5643",
          "title": "Dynamic Clusters",
          "content": [
              {
                  "type": "paragraph",
                  "content": "Dynamic Clusters are collections of variables that are correlated over a long period of time. These clusters are identified and periodically updated autonomously by DRA. They are categorized by different strengths (excellent, very good, and good) – to highlight known or previously unknown relationships in process operations.  ",
                  "id": "content-5644",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "A full list of Dynamic Clusters can be viewed under **Advanced Settings > ARIA + Dynamic Clusters** page.  ",
                  "id": "content-5645",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5646",
          "title": "What is Sloping Trend?",
          "content": [
              {
                  "type": "paragraph",
                  "content": "Sloping Trend is a risk detection system that identifies slowly developing long-term trends in a process. One can view the sloping trend by clicking its global icon at the top right corner or its icon at the bottom of any Trendchart.",
                  "id": "content-5647",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "There are 3 categories of sloping trends: **long-term trends** are shown using a red line, **medium-term trends** are shown using an orange line and **short-term trends** are shown using a yellow line. A sloping trend report is also provided once a week.",
                  "id": "content-5648",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "image",
                  "filename": "slopingtrend.png",
                  "codename": "slopingtrend",
                  "id": "content-5649",
                  "content": assets.slopingtrend,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5650",
          "title": "What is Variability Dynamics?",
          "content": [
              {
                  "type": "paragraph",
                  "content": "Variability Dynamics is a risk detection system that enables you to analyze how Tag values are distributed over different time periods. You can also compare distributions of several Tags easily. It can be viewed by clicking its icon at the bottom right corner of any Trendchart. Useful metrics, such as, mean, median, etc. are also available for analysis. Note that the shape of a distribution points out important characteristics of a process variable and hence, any changes in distributions (over time, or with respect to another variable) can indicate important drifts in the process. ",
                  "id": "content-5651",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "Variability Dynamics also includes a comprehensive ‘Process Capability Analysis’ that allows easy calculation of standard capability metrics, such as, **Cp, Cpk, CCpk, and Cpm**. ",
                  "id": "content-5652",
                  "marginTop": "0",
                  "marginBottom": "1"
              },
              {
                  "type": "image",
                  "filename": "variability.png",
                  "codename": "variability",
                  "id": "content-5653",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.variability
              }
          ]
      },
      {
          "id": "subsection-5654",
          "title": "What is Fault Trees?",
          "content": [
              {
                  "type": "paragraph",
                  "content": "Fault Trees is a platform to identify root causes timely, capture integrated process knowledge effectively, and provide recommendations for mitigation.",
                  "id": "content-5655",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Easy to build interactive cause-and-effect diagrams",
                          "id": "content-list-5657",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Captures SME knowledge for successful corrective actions",
                          "id": "content-list-5658",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Visual, diagnostic overview of the plant performance",
                          "id": "content-list-5659",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5656",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "faulttree1.png",
                  "codename": "faulttree1",
                  "id": "content-5660",
                  "content": assets.faulttree1,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5661",
          "title": "What is Operating Fitness?",
          "content": [
              {
                  "type": "paragraph",
                  "content": "Operating Fitness is a platform for risk assessment based on company’s critical risk limits (not to be mixed with the alarm limits). These are strategic limits, which when crossed, can lead to a significant impact. Hence, the probability of crossing these limits is an important metric to monitor.",
                  "id": "content-5662",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": " The system provides:",
                  "id": "content-5663",
                  "marginTop": "0.5",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "**Probability of crossing the limits in the near future:** These probability results enable operations team to identify developing risks even if the limits have not been crossed recently.",
                          "id": "content-list-5665",
                          "marginLeft": "0"
                      },
                      {
                          "value": "**Exceedance analysis:** The in-depth exceedance analysis based on NMM’s patents provides useful statistical insights on the reliability of operations.",
                          "id": "content-list-5666",
                          "marginLeft": "0"
                      },
                      {
                          "value": "**Seamless integration with DRA –** allowing the user to review if there were anomalies before a limit was crossed.",
                          "id": "content-list-5667",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5664",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "operatingfitness.png",
                  "codename": "operatingfitness",
                  "id": "content-5668",
                  "content": assets.operatingfitness,
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-6061",
          "title": "What is My Fitness?",
          "content": [
              {
                  "type": "paragraph",
                  "content": "‘My Fitness’ is a new platform in DRA that enables users to set personal limits in trendcharts and view exceedances immediately. It features a highly visual interface that updates in real-time, allowing users to easily see exceeded limits in trendcharts, both on their computers and in control rooms on large displays (when configured). ",
                  "id": "content-6062",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "MyFitness1.png",
                  "codename": "myfitness1",
                  "id": "content-6486",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.myfitness1
              },
              {
                  "type": "paragraph",
                  "content": "Note: This module is available with DRA Real-Time only.",
                  "id": "content-6837",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5669",
          "title": "What is ARIA?",
          "content": [
              {
                  "type": "paragraph",
                  "content": "**Autonomous Relationship Analyzer** or **ARIA** is an advanced multivariate risk detection system that uses NMM’s proprietary machine learning (deep learning) algorithms, to autonomously identify early-stage drifts in process variables. ARIA learns inter-variable relationships and detects changes in relationships that may otherwise be difficult to observe until they lead to problems or failures. ",
                  "id": "content-5670",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "**Use Case:** For instance, consider a compressor, heat exchanger, or furnace with multiple variables like A, B, C, etc., that typically show synchronized movements. ARIA can detect when one or more variables begin to drift from expected performance. Because it understands the inter-variable relationships, ARIA alerts you to critical discrepancies in real-time, enabling proactive intervention by the operating teams at the earliest stage. ",
                          "id": "content-list-5672",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5671",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "image",
                  "filename": "ARIA.png",
                  "codename": "ARIA",
                  "id": "content-5673",
                  "marginTop": "0",
                  "marginBottom": "0",
                  "content": assets.ARIA
              }
          ]
      },
      {
          "id": "subsection-5674",
          "title": "DRA Versions: Real-Time Plus, Core",
          "content": [
              {
                  "type": "paragraph",
                  "content": "DRA is available in two versions: **DRA Real-Time Plus** and **DRA Core**. DRA Real-Time Plus processes data throughout the day and shows anomalies and trends developing for Today – allowing you to follow up on important indicators during the day. **DRA Core** performs risk calculations once a day (shortly after midnight) and shows anomalies and trends developing for Yesterday. Both versions are designed to identify developing risks days or weeks in advance. ",
                  "id": "content-5675",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "The following capabilities are available with DRA Real-Time Plus only:",
                  "id": "content-5676",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**1)\tReal-Time Results**",
                  "id": "content-5677",
                  "marginBottom": "0",
                  "marginTop": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Continuous data processing provides real-time results, which facilitate faster problem detection and resolution",
                          "id": "content-list-5679",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5678",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**2) Operating Fitness**",
                  "id": "content-5680",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Operating Fitness is a platform for plant monitoring based on your critical operating limits, such as, integrity, safety, and emission limits, strategic operating envelopes, etc. It provides risk identification results on exceedances and probability of crossing the limits.",
                          "id": "content-list-5682",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5681",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**3) My Fitness**",
                  "id": "content-7438",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "‘My Fitness’ is a platform in DRA that enables users to set personal limits in trendcharts and view exceedances immediately. It features a highly visual interface that updates in real-time, allowing users to easily see exceeded limits in trendcharts, both on their computers and in control rooms on large displays (when configured). ",
                          "id": "content-list-7579",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-7581",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**4) ARIA**",
                  "id": "content-5683",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "ARIA is an advanced multivariate risk detection system that uses NMM’s proprietary machine learning (deep learning) algorithms, to autonomously identify early-stage drifts in process variables. ARIA learns inter-variable relationships and detects discrepancies in relationships that may otherwise be difficult to observe until they lead to problems or failures. ",
                          "id": "content-list-5685",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5684",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**5) Dynamic Clusters (Autonomous)**",
                  "id": "content-5686",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Dynamic Clusters are collections of variables that are correlated over a long period of time. These clusters are identified and periodically updated autonomously by DRA. They are categorized by different strengths (excellent, very good, and good) – to highlight known or previously unknown relationships in process operations.  ",
                          "id": "content-list-5688",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5687",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**6) Correlated Tags (Autonomous) **",
                  "id": "content-5689",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "For any process variable, the associated correlated tags can be easily viewed by the Users on the Trendchart. Similar to dynamic clusters, the correlated tags are categorized by strength (excellent, very good, and good) and are periodically updated by the system. ",
                          "id": "content-list-5691",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5690",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**7) Personal Notifications**",
                  "id": "content-5692",
                  "marginBottom": "0",
                  "marginTop": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Users can create personal notifications and get notified in real-time when a tag experiences an anomaly, a math expression is satisfied, or a specific pattern is matched.",
                          "id": "content-list-5694",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Three (3) types of notifications are available: Pattern Matching Notifications, Standard Notifications, and Custom Notifications.",
                          "id": "content-list-5695",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5693",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**8) Pattern Matching**",
                  "id": "content-5696",
                  "marginBottom": "0",
                  "marginTop": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Pattern Matching capability enables you to specify a pattern and easily identify instances when it occurred historically, and more importantly, when it occurs in real-time.",
                          "id": "content-list-5698",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Users can create personal notifications and get notified in real-time when a specific pattern is followed.",
                          "id": "content-list-5699",
                          "marginLeft": "0"
                      },
                      {
                          "value": " Admins can create calculated tags and fault tree nodes using pattern matching functions and identify whether a specific pattern has occurred historically or in real-time.",
                          "id": "content-list-5700",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5697",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**9) Shift Reports**",
                  "id": "content-5701",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Shift reports provides shift specific information automatically at the stated delivery time. They enable smooth shift transitions, e.g. 1st shift (12am – 8am) report can be reviewed by the incoming 2nd shift to ensure all developing problems are discussed with the outgoing 1st shift before the 2nd shift starts.",
                          "id": "content-list-5703",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5702",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      },
      {
          "id": "subsection-5704",
          "title": "List of Supported Functions",
          "content": [
              {
                  "type": "paragraph",
                  "content": "DRA supports 60+ functions (or operators) that allow you to evaluate powerful mathematical expressions. They can be used to create new calculated tags, fault tree nodes, and personal notifications in DRA. Note that: (a) the syntax is case sensitive, and (b) nesting of functions is permitted, e.g. %%rolling_max(dxdt(\"TI100.PV\"),12)%% is a valid expression.",
                  "id": "content-5705",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**$$1. Advanced Functions$$**",
                  "id": "content-5706",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Periodicity**",
                  "id": "content-5707",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%periodicity(event activation expression, snooze time period in hours)%%",
                          "id": "content-list-5709",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: First identifies when an event occurs based on event activation expression and then reports the time difference (in hours) between consecutive events. Snooze time period consolidates the events that are within the specified time duration. ",
                          "id": "content-list-5710",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%periodicity(\"TI100.PV\" > 100, 1)%%",
                          "id": "content-list-5711",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5708",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Lag**",
                  "id": "content-5712",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%lag(variable, time period in minutes)%%",
                          "id": "content-list-5714",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates lagged value of variable or expression",
                          "id": "content-list-5715",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%lag(\"TI100.PV\", 15)%%  or  %%lag(\"TI100.PV\", -15)%%",
                          "id": "content-list-5716",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5713",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rate of Change**",
                  "id": "content-5717",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rateofchange(variable, time period in minutes)%%",
                          "id": "content-list-5719",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates rate of change for a variable or expression over a time period",
                          "id": "content-list-5720",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rateofchange(\"TI100.PV\", 15)%%",
                          "id": "content-list-5721",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5718",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**First Order Derivative**",
                  "id": "content-5722",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%dxdt(expression)%%",
                          "id": "content-list-5724",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates first order derivative for a variable or expression",
                          "id": "content-list-5725",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%dxdt(\"TI100.PV\")%%",
                          "id": "content-list-5726",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5723",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Minimum value**",
                  "id": "content-5727",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%min(variable1, variable2, ...)%%",
                          "id": "content-list-5729",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates minimum value across multiple variables",
                          "id": "content-list-5730",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%min(\"TI100.PV\", \"TI200.PV\", \"TI300.PV\")%%",
                          "id": "content-list-5731",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5728",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Maximum value**",
                  "id": "content-5732",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%max(variable1, variable2, ...)%%",
                          "id": "content-list-5734",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates maximum value across multiple variables",
                          "id": "content-list-5735",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%max(\"TI100.PV\", \"TI200.PV\", \"TI300.PV\")%%",
                          "id": "content-list-5736",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5733",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Mean value**",
                  "id": "content-5737",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%mean(variable1, variable2, ...)%%",
                          "id": "content-list-5739",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates mean value across multiple variables",
                          "id": "content-list-5740",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%mean(\"TI100.PV\", \"TI200.PV\", \"TI300.PV\")%%",
                          "id": "content-list-5741",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5738",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Median value**",
                  "id": "content-5742",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%median(variable1, variable2, ...)%%",
                          "id": "content-list-5744",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates median value across multiple variables",
                          "id": "content-list-5745",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%median(\"TI100.PV\", \"TI200.PV\", \"TI300.PV\")%%",
                          "id": "content-list-5746",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5743",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**IF statement**",
                  "id": "content-5747",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%if(expression, value when expression is True, value when expression is False)%%",
                          "id": "content-list-5749",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Evaluates IF statement and assigns values based on when the expression is True or False",
                          "id": "content-list-5750",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%if(\"TI100.PV\" > 100, \"TI100.PV\", 100)%%",
                          "id": "content-list-5751",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5748",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Mean**",
                  "id": "content-5752",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_mean(variable, time period in hours)%%",
                          "id": "content-list-5754",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates mean of a variable (or expression) over a rolling time period",
                          "id": "content-list-5755",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_mean(\"AI10.PV\", 1)%%",
                          "id": "content-list-5756",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5753",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Maximum**",
                  "id": "content-5757",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_max(variable, time period in hours)%%",
                          "id": "content-list-5759",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates maximum value of a variable (or expression) over a rolling time period",
                          "id": "content-list-5760",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_max(\"AI10.PV\", 0.25)%%",
                          "id": "content-list-5761",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5758",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Minimum**",
                  "id": "content-5762",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_min(variable, time period in hours)%%",
                          "id": "content-list-5764",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates minimum value of a variable (or expression) over a rolling time period",
                          "id": "content-list-5765",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_min(\"AI10.PV\", 0.25)%%",
                          "id": "content-list-5766",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5763",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Median**",
                  "id": "content-5767",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_median(variable, time period in hours)%%",
                          "id": "content-list-5769",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates median of a variable (or expression) over a rolling time period",
                          "id": "content-list-5770",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_median(\"A1.PV\", 1.5)%%",
                          "id": "content-list-5771",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5768",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Standard Deviation**",
                  "id": "content-5772",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_std(variable, time period in hours)%%",
                          "id": "content-list-5774",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates standard deviation of a variable (or expression) over a rolling time period",
                          "id": "content-list-5775",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_std(\"A1.PV\", 1.5)%%",
                          "id": "content-list-5776",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5773",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Correlation**",
                  "id": "content-5777",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_corr(variable1, variable2, time period in hours)%%",
                          "id": "content-list-5779",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates Pearson correlation between two variables over a rolling time period",
                          "id": "content-list-5780",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_corr(\"AI10.PV\", \"AI20.PV\", 12)%%",
                          "id": "content-list-5781",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5778",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Gradient**",
                  "id": "content-5782",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_gradient(variable, time period in hours)%%",
                          "id": "content-list-5784",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates gradient value of the best fit line for a variable over a rolling time period",
                          "id": "content-list-5785",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_gradient(\"A1.PV\", 1)%%",
                          "id": "content-list-5786",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5783",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Percentile**",
                  "id": "content-5787",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_percentile(variable, percentile value between 0 and 100, time period in hours)%%",
                          "id": "content-list-5789",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates percentile value of a variable (or expression) over a rolling time period",
                          "id": "content-list-5790",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_percentile(\"A1.PV\", 75, 1)%%",
                          "id": "content-list-5791",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5788",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Sum**",
                  "id": "content-5792",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_sum(variable or expression, time period in hours)%%",
                          "id": "content-list-5794",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates sum of a variable (or expression) over a rolling time period",
                          "id": "content-list-5795",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_sum(\"A1.PV\" > 1, 12)%%",
                          "id": "content-list-5796",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5793",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Rolling Root Mean Square**",
                  "id": "content-5797",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%rolling_rms(variable or expression, time period in hours)%%",
                          "id": "content-list-5799",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates root mean square value of a variable (or expression) over a rolling time period",
                          "id": "content-list-5800",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%rolling_rms(\"A1.PV\", 12)%%",
                          "id": "content-list-5801",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5798",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Mean of Absolute Deviations**",
                  "id": "content-5802",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%mean_abs_dev(variable1, variable 2, ...)%%",
                          "id": "content-list-5804",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates mean of absolute deviations between two or more variables",
                          "id": "content-list-5805",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%mean_abs_dev(\"AI10.PV\", \"AI20.PV\", \"AI30.PV\")%%",
                          "id": "content-list-5806",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5803",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Maximum of Absolute Deviations**",
                  "id": "content-5807",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%max_abs_dev(variable1, variable 2, ...)%%",
                          "id": "content-list-5809",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates maximum of absolute deviations between two or more variables",
                          "id": "content-list-5810",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%max_abs_dev(\"AI10.PV\", \"AI20.PV\", \"AI30.PV\")%%",
                          "id": "content-list-5811",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5808",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Duration (Single Expression)**",
                  "id": "content-5812",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%duration(expression)%%",
                          "id": "content-list-5814",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Starts a timer and calculates the duration (in minutes) while the expression is True",
                          "id": "content-list-5815",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%duration(\"TI100.PV\" > 300)%%",
                          "id": "content-list-5816",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5813",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Duration (Start and End Expressions)**",
                  "id": "content-5817",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%duration_event(start expression, end expression)%%",
                          "id": "content-list-5819",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Starts a timer when the start expression is True, calculates the duration (in minutes), and stops when the end expression is True",
                          "id": "content-list-5820",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%duration_event(\"TI100.PV\" > 300, \"TI300.PV\" < 100)%%",
                          "id": "content-list-5821",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5818",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Duration (Start, End, and Pause Expressions)**",
                  "id": "content-5822",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%duration_with_pause(start expression, end expression, pause expression)%%",
                          "id": "content-list-5824",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Starts a timer when the start expression is True, calculates the duration (in minutes), pauses the timer when pause expression is True, and stops when the end expression is True",
                          "id": "content-list-5825",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%duration_with_pause(\"TI100.PV\" > 300, \"TI300.PV\" < 100, \"FI100.PV\" < 5)%%",
                          "id": "content-list-5826",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5823",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Event (Start and End Expressions)**",
                  "id": "content-5827",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%event(start expression, end expression)%%",
                          "id": "content-list-5829",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Starts a timer (and reports a value of 1) when the start expression is True, and stops (and reports a value of 0) when the end expression is True",
                          "id": "content-list-5830",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%event(\"TI100.PV\" > 300, \"TI300.PV\" < 100)%%",
                          "id": "content-list-5831",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5828",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Event - Total Time**",
                  "id": "content-5832",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%event_totaltime(start expression, end expression)%%",
                          "id": "content-list-5834",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Reports the total duration (in minutes) from when the start expression is True to when the end expression is True. Updates after the event has ended.",
                          "id": "content-list-5835",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%event_totaltime(\"TI100.PV\" > 300, \"TI300.PV\" < 100)%%",
                          "id": "content-list-5836",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5833",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Event - Maximum Value**",
                  "id": "content-5837",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%event_max(start expression, end expression, variable or expression)%%",
                          "id": "content-list-5839",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Finds the maximum value of the variable or expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended.",
                          "id": "content-list-5840",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%event_max(\"TI100.PV\" > 300, \"TI300.PV\" < 100, \"TI300.PV\")%%",
                          "id": "content-list-5841",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5838",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Event - Minimum Value**",
                  "id": "content-5842",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%event_min(start expression, end expression, variable or expression)%%",
                          "id": "content-list-5844",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Finds the minimum value of the variable or expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended.",
                          "id": "content-list-5845",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%event_min(\"TI100.PV\" > 300, \"TI300.PV\" < 100, \"TI300.PV\")%%",
                          "id": "content-list-5846",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5843",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Event - Mean Value**",
                  "id": "content-5847",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%event_mean(start expression, end expression, variable or expression)%%",
                          "id": "content-list-5849",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Finds the mean value of the variable or expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended.",
                          "id": "content-list-5850",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%event_mean(\"TI100.PV\" > 300, \"TI300.PV\" < 100, \"TI300.PV\")%%",
                          "id": "content-list-5851",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5848",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Event - Median Value**",
                  "id": "content-5852",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%event_median(start expression, end expression, variable or expression)%%",
                          "id": "content-list-5854",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Finds the median value of the variable or expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended.",
                          "id": "content-list-5855",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%event_median(\"TI100.PV\" > 300, \"TI300.PV\" < 100, \"TI300.PV\")%%",
                          "id": "content-list-5856",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5853",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Event - Standard Deviation Value**",
                  "id": "content-5857",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%event_std(start expression, end expression, variable or expression)%%",
                          "id": "content-list-5859",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Finds the standard deviation value of the variable or expression over the span from when the start expression is True to when the end expression is True. Updates after the event has ended.",
                          "id": "content-list-5860",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%event_std(\"TI100.PV\" > 300, \"TI300.PV\" < 100, \"TI300.PV\")%%",
                          "id": "content-list-5861",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5858",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Gaussian Noise**",
                  "id": "content-5862",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%gaussian_noise(mean, standard deviation)%%",
                          "id": "content-list-5864",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates gaussian noise or random fluctuations, which can be added to a variable or expression",
                          "id": "content-list-5865",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"73FC303.SP\" + gaussian_noise(0, 1)%%",
                          "id": "content-list-5866",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5863",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Time**",
                  "id": "content-5867",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%time('start time', 'end time')%%",
                          "id": "content-list-5869",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Creates a boolean step function which is true from start time until end time and false for other times ",
                          "id": "content-list-5870",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%time('2024-04-25 06:00', '2024-04-25 13:30')%%",
                          "id": "content-list-5871",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5868",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**$$2. Basic Functions$$**",
                  "id": "content-5872",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Addition**",
                  "id": "content-5873",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%+%%",
                          "id": "content-list-5875",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Addition of two of more variables",
                          "id": "content-list-5876",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: \"%%TI100.PV\" + \"TI200.PV\"%%",
                          "id": "content-list-5877",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5874",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Subtraction**",
                  "id": "content-5878",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%-%%",
                          "id": "content-list-5880",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Subtraction of two or more variables",
                          "id": "content-list-5881",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" – \"TI200.PV\"%%",
                          "id": "content-list-5882",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5879",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Multiplication**",
                  "id": "content-5883",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%*%%",
                          "id": "content-list-5885",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Multiplication of two or more variables",
                          "id": "content-list-5886",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\"*\"TI200.PV\"%%",
                          "id": "content-list-5887",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5884",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Division**",
                  "id": "content-5888",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%/%%",
                          "id": "content-list-5890",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Division between two variables",
                          "id": "content-list-5891",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" / \"TI200.PV\"%%",
                          "id": "content-list-5892",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5889",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Greater than**",
                  "id": "content-5893",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%>%%",
                          "id": "content-list-5895",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates if a variable or expression is greater than a value",
                          "id": "content-list-5896",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" > 50%%",
                          "id": "content-list-5897",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5894",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Lesser than**",
                  "id": "content-5898",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%<%%",
                          "id": "content-list-5900",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates if a variable or expression is lesser than a value",
                          "id": "content-list-5901",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" < 5%%",
                          "id": "content-list-5902",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5899",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Greater than equal to**",
                  "id": "content-5903",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%>=%%",
                          "id": "content-list-5905",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates if a variable or expression is greater than equal to a value",
                          "id": "content-list-5906",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" >= 50%%",
                          "id": "content-list-5907",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5904",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Lesser than equal to**",
                  "id": "content-5908",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%<=%%",
                          "id": "content-list-5910",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates if a variable or expression is lesser than equal to a value",
                          "id": "content-list-5911",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" <= 5%%",
                          "id": "content-list-5912",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5909",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Equal to**",
                  "id": "content-5913",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%==%%",
                          "id": "content-list-5915",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates if a variable or expression is equal to a value",
                          "id": "content-list-5916",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" == 10%%",
                          "id": "content-list-5917",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5914",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Not equal to**",
                  "id": "content-5918",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%!=%%",
                          "id": "content-list-5920",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates if a variable or expression is not equal to a value",
                          "id": "content-list-5921",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" != 1%%",
                          "id": "content-list-5922",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5919",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Modulus (Remainder)**",
                  "id": "content-5923",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%%%%",
                          "id": "content-list-5925",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates the modulus or remainder for a division",
                          "id": "content-list-5926",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" % \"TI200.PV\"%%",
                          "id": "content-list-5927",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5924",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**OR**",
                  "id": "content-5928",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%|%%",
                          "id": "content-list-5930",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Evaluates if either statement is satisfied",
                          "id": "content-list-5931",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%(\"TI100.PV\" < 5) | (\"TI200.PV\" < 10)%%",
                          "id": "content-list-5932",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5929",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**AND**",
                  "id": "content-5933",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%&%%",
                          "id": "content-list-5935",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Evaluates if all statements are satisfied",
                          "id": "content-list-5936",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%(\"TI100.PV\" < 5) & (\"TI200.PV\" < 10)%%",
                          "id": "content-list-5937",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5934",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Absolute value**",
                  "id": "content-5938",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%abs(expression)%%",
                          "id": "content-list-5940",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates absolute value of an expression",
                          "id": "content-list-5941",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%abs(\"TI100.PV\" – \"TI200.PV\")%%",
                          "id": "content-list-5942",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5939",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Exponent or Power**",
                  "id": "content-5943",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%expression ^ exponent%%",
                          "id": "content-list-5945",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Variable or expression raised to a power",
                          "id": "content-list-5946",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%\"TI100.PV\" ^ 2%%",
                          "id": "content-list-5947",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5944",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Exponent or Power**",
                  "id": "content-5948",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%pow(expression, exponent)%%",
                          "id": "content-list-5950",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Variable or expression raised to a power",
                          "id": "content-list-5951",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%pow(\"TI100.PV\", 2)%%",
                          "id": "content-list-5952",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5949",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Logarithm**",
                  "id": "content-5953",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%log(expression)%%",
                          "id": "content-list-5955",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates base 10 logarithmic value of a variable or expression",
                          "id": "content-list-5956",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%log(\"5TI100.PV\")%%",
                          "id": "content-list-5957",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5954",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Natural Logarithm**",
                  "id": "content-5958",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%ln(expression)%%",
                          "id": "content-list-5960",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates natural logarithmic value of a variable or expression",
                          "id": "content-list-5961",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%ln(\"5TI100.PV\")%%",
                          "id": "content-list-5962",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5959",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Exponential**",
                  "id": "content-5963",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%exp(expression)%%",
                          "id": "content-list-5965",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates exponential value of a variable or expression",
                          "id": "content-list-5966",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%exp(\"5TI100.PV\")%%",
                          "id": "content-list-5967",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5964",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Ceiling value**",
                  "id": "content-5968",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%ceiling(expression)%%",
                          "id": "content-list-5970",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Rounds the expression to the next integer value",
                          "id": "content-list-5971",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%ceiling(\"5TI100.PV\" – \"5TI200.PV\")%%",
                          "id": "content-list-5972",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5969",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Floor value**",
                  "id": "content-5973",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%floor(expression)%%",
                          "id": "content-list-5975",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Rounds the expression to the previous integer value",
                          "id": "content-list-5976",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%floor(\"5TI100.PV\" – \"5TI200.PV\")%%",
                          "id": "content-list-5977",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5974",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Round value**",
                  "id": "content-5978",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%round(expression)%%",
                          "id": "content-list-5980",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Rounds the expression to the nearest integer value",
                          "id": "content-list-5981",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%round(\"5TI100.PV\")%%",
                          "id": "content-list-5982",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5979",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**$$3. DRA Results Based Functions$$**",
                  "id": "content-5983",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Degree of Anomaly**",
                  "id": "content-5984",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%da(variable)%%",
                          "id": "content-list-5986",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates degree of anomaly for a variable",
                          "id": "content-list-5987",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%da(\"TI100.PV\")%%",
                          "id": "content-list-5988",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5985",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Degree of Anomaly (High Side)**",
                  "id": "content-5989",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%da_hi(variable)%%",
                          "id": "content-list-5991",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates degree of anomaly (high side) for a variable",
                          "id": "content-list-5992",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%da_hi(\"TI100.PV\")%%",
                          "id": "content-list-5993",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5990",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Degree of Anomaly (Low Side)**",
                  "id": "content-5994",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%da_lo(variable)%%",
                          "id": "content-list-5996",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates degree of anomaly (low side) for a variable",
                          "id": "content-list-5997",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%da_lo(\"TI100.PV\")%%",
                          "id": "content-list-5998",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-5995",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Degree of Anomaly (Sensor Flatline)**",
                  "id": "content-5999",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%da_sf(variable)%%",
                          "id": "content-list-6001",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates degree of anomaly (sensor flatline) for a variable",
                          "id": "content-list-6002",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%da_sf(\"TI100.PV\")%%",
                          "id": "content-list-6003",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-6000",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Sloping Trend Level**",
                  "id": "content-6004",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%strend_level(variable)%%",
                          "id": "content-list-6006",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates level of sloping trend for a variable (0: no trend, 1: short-term, 2: medium-trend, 3: long-trend)",
                          "id": "content-list-6007",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%strend_level(\"TI100.PV\")%%",
                          "id": "content-list-6008",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-6005",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Sloping Trend Gradient**",
                  "id": "content-6009",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%strend_gradient(variable)%%",
                          "id": "content-list-6011",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates gradient of sloping trend for a variable (time range measured in days)",
                          "id": "content-list-6012",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%strend_gradient(\"TI100.PV\")%%",
                          "id": "content-list-6013",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-6010",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Sloping Trend Length**",
                  "id": "content-6014",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%strend_length(variable)%%",
                          "id": "content-list-6016",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates length of sloping trend (in days)",
                          "id": "content-list-6017",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%strend_length(\"TI100.PV\")%%",
                          "id": "content-list-6018",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-6015",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Predictive Probability (Operating Fitness)**",
                  "id": "content-6019",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%prob_h1(variable), prob_l1(variable), ..., prob_h5(variable), prob_l5(variable)%%",
                          "id": "content-list-6021",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates a variable’s predictive probability of crossing an operating limit (pre-defined) in the next 7 days",
                          "id": "content-list-6022",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%prob_h1(\"TI100.PV\")%%",
                          "id": "content-list-6023",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-6020",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**$$4. Pattern Matching Based Functions$$**",
                  "id": "content-6024",
                  "marginBottom": "0.5",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "The following functions are available with DRA Real-Time only.",
                  "id": "content-6025",
                  "marginBottom": "1",
                  "marginTop": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Pattern Matching Degree**",
                  "id": "content-6026",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%pattern_match(variable, pattern start time, pattern end time)%%",
                          "id": "content-list-6028",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates degree of match with respect to a specified (golden) pattern. Degree of match can be 0, 1, 2, where 0 indicates No Match, 1 indicates a Probable Match, and 2 indicates an Identical Match. ",
                          "id": "content-list-6029",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%pattern_match(\"TI500.PV\", \"2022-11-25 17:55\", \"2022-11-25 18:05\")%%",
                          "id": "content-list-6030",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-6027",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Pattern Matching Percent**",
                  "id": "content-6031",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%pattern_match_percent(variable, pattern start time, pattern end time)%%",
                          "id": "content-list-6033",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates percentage of match with respect to a specified (golden) pattern. Ranges between 0 and 100.",
                          "id": "content-list-6034",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%pattern_match_percent(\"TI500.PV\", \"2022-11-25 17:55\", \"2022-11-25 18:05\")%%",
                          "id": "content-list-6035",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-6032",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "paragraph",
                  "content": "**Pattern Matching Custom**",
                  "id": "content-6036",
                  "marginTop": "0",
                  "marginBottom": "0"
              },
              {
                  "type": "list",
                  "bullets": true,
                  "content": [
                      {
                          "value": "Expression: %%pattern_match_custom(variable, pattern start time, pattern end time, probable match %, identical match %)%%",
                          "id": "content-list-6038",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Description: Calculates degree of match with respect to a specified (golden) pattern – based on percentage thresholds (for Probably Match and Identical Match) defined by the user. Degree of match can be 0, 1, 2, where 0 indicates No Match, 1 indicates a Probable Match, and 2 indicates an Identical Match.",
                          "id": "content-list-6039",
                          "marginLeft": "0"
                      },
                      {
                          "value": "Example: %%pattern_match_custom(\"TI500.PV\", \"2022-11-25 17:55\", \"2022-11-25 18:05\", 75, 90)%%",
                          "id": "content-list-6040",
                          "marginLeft": "0"
                      }
                  ],
                  "id": "content-6037",
                  "marginTop": "0",
                  "marginBottom": "0"
              }
          ]
      }
  ]
};

export default UserGuide;
