import { AnomalyLevelEnum } from "../../../types/api/Anomaly";
import * as R from "remeda";
import { useDateState } from "../../../zustand/useDateState";
import { cn, iife } from "../../../lib/utils";
import {
  useAllAcknowledgmentsForDayQuery,
  useAllAnomaliesForDayQuery,
  useClustersQuery,
  useCommentsSummaryQuery,
  useVariablesMappedByIdQuery,
} from "../../../hooks/tanstack-query";
import { anomalySchema, variableSchema } from "../../../lib/api-validators";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";
import { minutesToMilliseconds } from "date-fns";
import { useSelectedGroup } from "../use-selected-group";
import { ANOMALY_LEVELS_DESC } from "../../../shared-ui/lib/anomaly-levels";
import useSearchParamsEnhanced from "../../boundaries/hooks/useSearchParamsEnhanced";
import { useGetUseDriStore } from "../hooks/create-use-dri-store";
import { useGetUseViewModeStore } from "../../../shared-ui/time-series-2/grid-view-store";
import {
  BsChevronDoubleRight,
  BsChevronRight,
  BsClock,
  BsFillLightningChargeFill,
} from "react-icons/bs";
import { Collapse, LoadingOverlay, Tooltip } from "@mantine/core";
import { Badge } from "../../ui/badge";
import Button from "../../common/Button/Button";
import { produce } from "immer";
import { useEffect, useId, useRef } from "react";
import { useMemo } from "use-memo-one";
import { FaCheck, FaComment, FaExclamationTriangle } from "react-icons/fa";
import { ellipsify } from "../../utils/stylable";
import { formatTime } from "../utils/utils";
import { clusterSchema } from "../../../lib/api-schema/cluster";

const DEGREE_SYMBOL_UNICODE = "\u00B0";

function useClusterData() {
  const ds = useDateState();
  const x = useAllAnomaliesForDayQuery(ds.axisRangeTo.dateString, {
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(10),
  }).data;

  const variablesMap = useVariablesMappedByIdQuery({
    staleTime: minutesToMilliseconds(5),
    refetchOnMount: false,
  }).data;

  const clusters = useClustersQuery({
    staleTime: minutesToMilliseconds(5),
    refetchOnMount: false,
  }).data;

  const group = useSelectedGroup();

  const m = iife(() => {
    if (!variablesMap) return undefined;
    if (!x) return undefined;
    if (!group) return undefined;
    if (!clusters) return undefined;

    const belongToGroup = x.filter((x) =>
      group.variables.includes(x.variableId)
    );

    type Temp =
      | {
          // in cluster view but this anom is not in a cluster
          // so it will be shown as a regular view
          type: "regular";
          anom: anomalySchema;
        }
      | {
          type: "cluster";
          anom: anomalySchema;
          cluster: clusterSchema;
          descAnoms: [AnomalyLevelEnum, ...AnomalyLevelEnum[]];
        };

    /**
     * First, get all the anomalies that belong to the group
     * (or the group's variables). Then, use the anomalies present
     * to get the clusters that the anomalies belong to.
     *
     * For each of these clusters, choose a representative anomaly.
     */
    const clusterGroupedAnoms = Array.from(
      new Set(
        belongToGroup
          .map((anom) => {
            return variablesMap[anom.variableId]?.families;
          })
          .filter((x) => x !== undefined)
          .flat()
      )
    )
      .map((cid) => {
        const c = clusters.find((x) => x._id === cid);

        if (!c) return undefined;

        /**
         * Anomalies that exist for this day belonging to this
         * cluster.
         */
        const relatedAnoms = assertMinLen1(
          x.filter((a) => c.variables.includes(a.variableId))
        );

        return {
          cluster: c,
          relatedAnoms: relatedAnoms,
        };
      })
      .filter((x) => x !== undefined);

    const out2: { [K in AnomalyLevelEnum]: Temp[] } = {
      0: [],
      1: [],
      2: [],
      3: [],
    };

    for (const x of belongToGroup) {
      // if its in a cluster, skip it because that will be handled
      // using the representative anomaly.
      const isInACluster = clusterGroupedAnoms.some((y) =>
        y.relatedAnoms.includes(x)
      );

      if (!isInACluster) {
        // add it as a plain view because its not in a cluster
        out2[x.level].push({
          type: "regular",
          anom: x,
        });
      }
    }

    for (const { relatedAnoms, cluster } of clusterGroupedAnoms) {
      // the unique levels of anomalies in this cluster descending
      const descAnoms = assertMinLen1(
        R.uniqueBy(
          relatedAnoms.map((x) => x.level),
          (x) => x
        ).sort((a, b) => b - a)
      );

      // the representative anomaly for this cluster has the max level
      const max = descAnoms[0];

      const representativeVariableForCluster = assertMinLen1(
        relatedAnoms
          .filter((x) => x.level === max)
          .sort((a, b) => {
            // if multiple have the max, choose longest duration
            return b.anomalyMilliseconds - a.anomalyMilliseconds;
          })
      )[0];

      out2[max].push({
        type: "cluster",
        anom: representativeVariableForCluster,
        cluster: cluster,
        // use this to know all unique levels of anomalies in this cluster
        descAnoms: descAnoms,
      });
    }

    for (const x of Object.values(out2)) {
      // sort in the way we show it from top to bottom
      x.sort((a, b) => {
        const aTime = a.anom.anomalyMilliseconds;
        const bTime = b.anom.anomalyMilliseconds;

        const diff = bTime - aTime;

        if (diff === 0) {
          switch (a.type) {
            case "regular":
              switch (b.type) {
                case "regular":
                  return variablesMap[
                    a.anom.variableId
                  ]!.trimmedName.localeCompare(
                    variablesMap[b.anom.variableId]!.trimmedName
                  );
                case "cluster":
                  return 1; // put b first
                default:
                  const _: never = b;
              }
              break;
            case "cluster":
              switch (b.type) {
                case "regular":
                  return -1; // put a first
                case "cluster":
                  return (
                    b.descAnoms[0] - a.descAnoms[0] ||
                    b.descAnoms.length - a.descAnoms.length ||
                    R.sum(b.descAnoms) - R.sum(a.descAnoms) ||
                    variablesMap[a.anom.variableId]!.trimmedName.localeCompare(
                      variablesMap[b.anom.variableId]!.trimmedName
                    ) ||
                    b.cluster.variables.length - a.cluster.variables.length
                  );

                default:
                  const _: never = b;
              }
              break;
            default:
              const _: never = a;
          }
        }

        return diff; // descending
      });
    }

    return out2;
  });

  return m;
}

type ClusterViewData = ReturnType<typeof useClusterData>;
type ClusterRow = NonNullable<ClusterViewData>[AnomalyLevelEnum][number];

export function ClusterView({
  canShowVariable,
}: {
  canShowVariable: (x: string) => boolean;
}) {
  const useDriStore = useGetUseDriStore();
  const handledListOpen = useRef(false);

  const m = useClusterData();

  useEffect(
    function autoCloseOrOpenListsOnViewChange() {
      if (!m) return;
      if (handledListOpen.current) return;

      handledListOpen.current = true; // don't handle it anymore

      useDriStore.setState(() => ({
        listOpen: {
          0: m[0].length > 0,
          1: m[1].length > 0,
          2: m[2].length > 0,
          3: m[3].length > 0,
          watch: true,
        },
      }));
    },
    [m, useDriStore]
  );

  if (!m) return null;

  return (
    <>
      {ANOMALY_LEVELS_DESC.map((level) => {
        return (
          <Level
            key={level}
            level={level}
            data={m}
            canShowVariable={canShowVariable}
          />
        );
      })}
    </>
  );
}

function Level({
  level,
  data,
  canShowVariable,
}: {
  level: AnomalyLevelEnum;
  data: ClusterViewData;
  canShowVariable: (x: string) => boolean;
}) {
  const myData = data?.[level];

  const variablesMapQuery = useVariablesMappedByIdQuery({
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
  });
  const variablesMap = variablesMapQuery.data;
  const [, setSearchParams] = useSearchParamsEnhanced();
  const useDriStore = useGetUseDriStore();
  const isOpen = useDriStore((s) => s.listOpen[level]);

  const useViewModeStore = useGetUseViewModeStore();
  const setChartViewMode = useViewModeStore((s) => s.setViewMode);
  const active = isOpen;
  const title = `${level}${DEGREE_SYMBOL_UNICODE} Anomaly`;
  const id = useId();

  const handleOpenListClick = () => {
    useDriStore.setState((s) => {
      return {
        listOpen: produce(s.listOpen, (curr) => {
          curr[level] = !curr[level];
        }),
      };
    });
  };

  if (!variablesMap) return undefined;
  if (!myData) return null;

  const hasData = myData.length > 0;

  return (
    <div key={title}>
      <label
        className={cn(
          "tab-label group flex select-none items-center gap-2 p-1.5 pl-5 tracking-tight",
          hasData
            ? "cursor-pointer hover:bg-xslate-3 hover:font-medium"
            : "cursor-not-allowed",
          active && "bg-zinc-100 text-indigo-800"
        )}
        // watchlist label receives no color on the bottom
        style={{
          borderBottomWidth: "3px",
          borderBottomColor: `rgb(var(--anom${level})`,
        }}
        htmlFor={id}
        onClick={hasData ? handleOpenListClick : undefined}
      >
        <div className={cn("transition-transform", isOpen && "rotate-90")}>
          <BsChevronRight />
        </div>
        <span className="transition-all group-hover:ml-1">{title}</span>

        <Tooltip label="Show All" position="left" withArrow>
          <Button
            disabled={!hasData}
            className="btn-ghost ml-auto text-[18px]"
            icon={BsChevronDoubleRight}
            onClick={(e) => {
              e.stopPropagation(); // prevent the list from expand/collapse

              if (hasData) {
                setSearchParams({
                  v: "",
                  dv: myData
                    .map((x) =>
                      variablesMap[x.anom.variableId]?.short_id.toString()
                    )
                    .filter((x) => x !== undefined),
                });
                useDriStore.setState({
                  showAllMode: true,
                });
                setChartViewMode("grid");
              }
            }}
          />
        </Tooltip>
      </label>
      <Collapse in={isOpen}>
        <div className={cn("tab-content")}>
          <div className="flex flex-col bg-white text-[13px]">
            {myData.map((a) => {
              if (a.type === "regular")
                return (
                  <PlainCard
                    key={a.anom._id}
                    canShowVariable={canShowVariable}
                    anom={a.anom}
                  />
                );
              return (
                <ClusterCard
                  data={a}
                  canShowVariable={canShowVariable}
                  key={a.anom._id + a.cluster._id}
                />
              );
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

function ClusterCard({
  data,
  canShowVariable,
}: {
  data: Extract<ClusterRow, { type: "cluster" }>;
  canShowVariable: (vid: string) => boolean;
}) {
  const { anom, cluster, descAnoms } = data;
  const clusterId = cluster._id;

  const { variableId, anomalyMilliseconds } = anom;

  const ds = useDateState();

  const useDriStore = useGetUseDriStore();
  const useViewModeStore = useGetUseViewModeStore();
  const setChartViewMode = useViewModeStore((s) => s.setViewMode);
  const variablesMap = useVariablesMappedByIdQuery({
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
  }).data;
  const clusters = useClustersQuery({
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
  }).data;
  const [searchParams, setSearchParams] = useSearchParamsEnhanced();

  const clusterObj = useMemo(
    () => clusters?.find((x) => x._id === clusterId),
    [clusterId, clusters]
  );

  const variable = useMemo(
    () => variablesMap?.[variableId],
    [variablesMap, variableId]
  );

  const acksQuery = useAllAcknowledgmentsForDayQuery(
    ds.axisRangeTo.dateString,
    {
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(5),
    }
  );

  const commentsSummaryQuery = useCommentsSummaryQuery(
    ds.axisRangeTo.dateString,
    ds.axisRangeTo.dateString,
    clusterObj?.variables ?? [],
    {
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(5),
      enabled: clusterObj !== undefined,
    }
  );

  const commentsSummary = commentsSummaryQuery.data;

  if (!variable) return null;
  if (!clusterObj) return null;
  if (!variablesMap) return null;

  if (!canShowVariable(variableId)) return null;

  let urlVariableString = variable.short_id.toString() + `-${clusterObj._id}`;

  const isActive = searchParams.get("v") === urlVariableString;

  const ack = acksQuery.data?.find((ack) => ack.variable === variableId);
  const ackType = ack ? ack.type : null;
  const ackTypeIcon =
    ackType === "normal" ? <FaCheck /> : <FaExclamationTriangle />;
  const leftSideCommentOrAckIcons = (
    <div className="absolute left-[-13px] top-1 flex flex-col gap-1 text-[11px] text-zinc-400">
      {ack && ack.unacknowledgment === false ? ackTypeIcon : null}
      {commentsSummary && commentsSummary.total > 0 && <FaComment />}
    </div>
  );

  return (
    <div
      onClick={() => {
        const base = { v: urlVariableString };
        const shorts = clusterObj.variables.reduce((arr, vid) => {
          const variable = variablesMap[vid];
          variable && arr.push(variable.short_id.toString());

          return arr;
        }, [] as string[]);

        setSearchParams({
          ...base,
          dv: shorts,
        });

        setChartViewMode("grid");

        useDriStore.setState({
          showAllMode: false,
        });
      }}
      className={cn(
        "group flex min-h-[3.5rem] items-center gap-3 border-slate-300 px-5 py-2 transition-all last:border-b active:border-transparent not-first:border-t",
        isActive
          ? "bg-xindigo-4"
          : "hover:cursor-pointer hover:bg-xslate-3 hover:text-indigo-800 active:scale-95 active:rounded-lg"
      )}
    >
      <div
        className={cn(
          "relative inline-flex flex-col lowercase",
          isActive && "font-medium"
        )}
      >
        <span className="text-md break-all">{variable.trimmedName}</span>
        <span className="break-all tracking-tight text-xslate-11">
          {ellipsify(variable.description, 80)}
        </span>
        {leftSideCommentOrAckIcons}
        <div className="mt-1 flex max-w-full items-stretch">
          <div className="inline-flex h-5 max-w-max grow items-center gap-2 rounded-l-md border-y border-l border-zinc-300 bg-white px-2">
            <span className="grow text-ellipsis whitespace-nowrap text-xs tracking-tight">
              {clusterObj.type === "dynamic" && (
                <BsFillLightningChargeFill className="mb-[1px] mr-1 inline h-3 w-3" />
              )}
              {ellipsify(clusterObj.name, 30)}
            </span>
            <span className="h-3.5 rounded-full border border-zinc-300 bg-zinc-200 px-1 text-[0.7rem] leading-[0.72rem] text-zinc-500">
              {clusterObj.variables.reduce(
                (sum, vid) => sum + +!!variablesMap[vid],
                0
              )}
            </span>
          </div>
          <div className="inline-flex items-center">
            {descAnoms.map((lvl) => {
              return (
                <div
                  key={`indc-${lvl}`}
                  className="h-full w-4 last:rounded-r-md"
                  style={{
                    backgroundColor: `rgb(var(--anom${lvl}))`,
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      {anomalyMilliseconds > 0 && (
        <span className="ml-auto inline-flex items-center gap-1 text-[0.7rem]">
          <BsClock />
          {formatTime(anomalyMilliseconds / 1000)}
        </span>
      )}
    </div>
  );
}

export const sortAnomsFn = (
  variablesMap: Record<string, variableSchema>,
  a: anomalySchema,
  b: anomalySchema
): number => {
  const diff = b.anomalyMilliseconds - a.anomalyMilliseconds;

  if (diff === 0) {
    return variablesMap![a.variableId]!.trimmedName.localeCompare(
      variablesMap![b.variableId]!.trimmedName
    );
  }

  return diff;
};

export function WatchlistGroup({
  canShowVariable,
}: {
  canShowVariable: (vid: string) => boolean;
}) {
  const variablesMapQuery = useVariablesMappedByIdQuery();
  const variablesMap = variablesMapQuery.data;

  const ds = useDateState();
  const group = useSelectedGroup();

  const all = useAllAnomaliesForDayQuery(ds.axisRangeTo.dateString, {
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(10),
  }).data;

  const watchlistAnoms = useMemo((): typeof all => {
    if (!all) return undefined;
    if (!group) return undefined;
    if (!variablesMap) return undefined;

    const anoms = all.filter((x) => group.variables.includes(x.variableId));

    return anoms
      .filter((x) => variablesMap[x.variableId]?.watchlist)
      .sort((a, b) => {
        return sortAnomsFn(variablesMap, a, b);
      });
  }, [all, variablesMap, group]);

  const [, setSearchParams] = useSearchParamsEnhanced();
  const useDriStore = useGetUseDriStore();
  const isOpen = useDriStore((s) => s.listOpen["watch"]);

  const viewMode = useDriStore((s) => s.viewMode);
  const useViewModeStore = useGetUseViewModeStore();
  const setChartViewMode = useViewModeStore((s) => s.setViewMode);
  const active = isOpen;
  const title = "Watchlist";
  const id = useId();

  const handleOpenListClick = () => {
    useDriStore.setState((s) => {
      return {
        listOpen: produce(s.listOpen, (curr) => {
          curr["watch"] = !curr["watch"];
        }),
      };
    });
  };

  if (!watchlistAnoms) return null;

  const hasData = watchlistAnoms.length > 0;

  if (!variablesMap) return null;

  return (
    <div>
      <label
        className={cn(
          "tab-label group flex select-none items-center gap-2 p-1.5 pl-5 tracking-tight",
          hasData
            ? "cursor-pointer hover:bg-xslate-3 hover:font-medium"
            : "cursor-not-allowed",
          active && "bg-zinc-100 text-indigo-800"
        )}
        // watchlist label receives no color on the bottom
        style={{
          borderBottomWidth: "3px",
          borderBottomColor: "#3730a3",
        }}
        htmlFor={id}
        onClick={hasData ? handleOpenListClick : undefined}
      >
        <div className={cn("transition-transform", isOpen && "rotate-90")}>
          <BsChevronRight />
        </div>
        <span className="transition-all group-hover:ml-1">{title}</span>
        {viewMode === "plain" && (
          <Badge variant={"outline"}>
            {watchlistAnoms.filter((x) => canShowVariable(x.variableId)).length}
          </Badge>
        )}
        <Tooltip label="Show All" position="left" withArrow>
          <Button
            disabled={!hasData}
            className="btn-ghost ml-auto text-[18px]"
            icon={BsChevronDoubleRight}
            onClick={(e) => {
              e.stopPropagation(); // prevent the list from expand/collapse

              if (hasData) {
                setSearchParams({
                  v: "",
                  dv: watchlistAnoms
                    .map((x) => variablesMap[x.variableId]?.short_id.toString())
                    .filter((x) => x !== undefined),
                });
                useDriStore.setState((s) => {
                  return {
                    showAllMode: true,
                  };
                });
                setChartViewMode("grid");
              }
            }}
          />
        </Tooltip>
      </label>
      <Collapse in={isOpen}>
        <div className={cn("tab-content")}>
          <div className="flex flex-col bg-white text-[13px]">
            {watchlistAnoms.map((a) => {
              return (
                <PlainCard
                  anom={a}
                  canShowVariable={canShowVariable}
                  key={a._id}
                />
              );
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export function PlainCard({
  canShowVariable,
  anom: a,
}: {
  anom: anomalySchema;
  canShowVariable: (vid: string) => boolean;
}) {
  const useDriStore = useGetUseDriStore();
  const useViewModeStore = useGetUseViewModeStore();
  const setChartViewMode = useViewModeStore((s) => s.setViewMode);
  const [searchParams, setSearchParams] = useSearchParamsEnhanced();
  const variablesMap = useVariablesMappedByIdQuery({
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
  }).data;
  const ds = useDateState();

  const acksQuery = useAllAcknowledgmentsForDayQuery(
    ds.axisRangeTo.dateString,
    {
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(5),
    }
  );

  const variable = variablesMap?.[a.variableId];

  const commentsSummaryQuery = useCommentsSummaryQuery(
    ds.axisRangeTo.dateString,
    ds.axisRangeTo.dateString,
    [a.variableId],
    {
      refetchOnMount: false,
      staleTime: minutesToMilliseconds(5),
    }
  );

  const commentsSummary = commentsSummaryQuery.data;

  if (!variable) return null;

  if (!canShowVariable(a.variableId)) return null;

  let urlVariableString = variable.short_id.toString();

  const isActive = searchParams.get("v") === urlVariableString;

  const clusterPillWithCountAndColorsMaybe = null;

  const ack = acksQuery.data?.find((ack) => ack.variable === a.variableId);
  const ackType = ack ? ack.type : null;
  const ackTypeIcon =
    ackType === "normal" ? <FaCheck /> : <FaExclamationTriangle />;
  const leftSideCommentOrAckIcons = (
    <div className="absolute left-[-13px] top-1 flex flex-col gap-1 text-[11px] text-zinc-400">
      {ack && ack.unacknowledgment === false ? ackTypeIcon : null}
      {commentsSummary &&
        (commentsSummary.variables_counts[a.variableId] ?? 0) > 0 && (
          <FaComment />
        )}
    </div>
  );

  return (
    <div
      /**
       * This is extremely important. Duplicating keys for this components creates a world of a headache
       * for list items duplicating/missing randomly.
       */
      onClick={() => {
        const base = { v: urlVariableString };
        setSearchParams({
          ...base,
          dv: base.v,
        });
        setChartViewMode("list");
        useDriStore.setState({
          showAllMode: false,
        });
      }}
      className={cn(
        "group flex min-h-[3.5rem] items-center gap-3 border-slate-300 px-5 py-2 transition-all last:border-b active:border-transparent not-first:border-t",
        isActive
          ? "bg-xindigo-4"
          : "hover:cursor-pointer hover:bg-xslate-3 hover:text-indigo-800 active:scale-95 active:rounded-lg"
      )}
    >
      <div
        className={cn(
          "relative inline-flex flex-col lowercase",
          isActive && "font-medium"
        )}
      >
        <span className="text-md break-all">{variable.trimmedName}</span>
        <span className="break-all tracking-tight text-xslate-11">
          {ellipsify(variable.description, 80)}
        </span>
        {leftSideCommentOrAckIcons}
        {clusterPillWithCountAndColorsMaybe}
      </div>
      {a.anomalyMilliseconds ? (
        <span className="ml-auto inline-flex items-center gap-1 text-[0.7rem]">
          <BsClock />
          {formatTime(a.anomalyMilliseconds / 1000)}
        </span>
      ) : null}
    </div>
  );
}
