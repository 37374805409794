import React, { useState } from "react";
import UpdatedAt from "../common/manager/UpdatedAt";
import CommentCreateOrEditForm from "./CommentCreateOrEditForm";
import useAuthStore from "../../zustand/auth/useAuthStore";
import { Tooltip } from "@mantine/core";
import Button from "../common/Button/Button";
import CommentBadgeList from "./CommentBadgeList";
import { LinkifyText } from "../time-series/secondary-variable-view/comments/comment-thread";

export default function CommentReplyCard({
  comment,
  editData,
  updateState,
  // insertNewReply,
  deleteComment,
}) {
  const USER_ID = useAuthStore((s) => s.user._id);
  const editable = USER_ID === comment.author._id;

  const firstName = comment.author.first;
  const lastName = comment.author.last;

  const [editMode, setEditMode] = useState(false);

  // for the replies, we want the style to be a little different if it does not have extra data to show on the right

  return (
    <div className="card card-compact overflow-visible border-bordgrey2 bg-transparent">
      {/* body of card  */}
      <div className="card-body overflow-visible !p-0">
        <div className="flex rounded-md transition-colors hover:bg-xslate-2">
          <div className="flex w-3/4 flex-col pl-4">
            <div className="border-l-2 border-zinc-300 py-2">
              <div className="flex gap-2 pl-3">
                {/* the avatar column  */}
                <div className="avatar placeholder">
                  <div
                    className={`h-7 w-7 rounded-full bg-zinc-300 text-base-100`}
                  >
                    <span className="text-[0.7rem]">
                      {firstName[0].toUpperCase() + lastName[0].toUpperCase()}
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    style={{ marginBottom: 0 }}
                    className="text-[1rem] font-medium"
                  >
                    {firstName} {lastName}
                  </h2>
                  <div className="text-[0.7rem] italic text-textgrey">
                    <UpdatedAt
                      noContainer={true}
                      timestamp={comment.edited_at}
                    />
                  </div>
                </div>
                <div className="ml-auto">
                  {editable ? (
                    <div>
                      <Tooltip label="Delete" withArrow>
                        <Button
                          className="btn-ghost"
                          icon="trash-o"
                          onClick={() => deleteComment(comment)}
                        />
                      </Tooltip>
                      {editMode ? (
                        <Tooltip label="Cancel" withArrow>
                          <Button
                            className="btn-ghost"
                            icon="window-close"
                            onClick={() => setEditMode(false)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip label="Edit" withArrow>
                          <Button
                            className="btn-ghost"
                            icon="pencil"
                            onClick={() => {
                              setEditMode(true);
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              {editMode ? (
                <div className="my-6 px-12">
                  <CommentCreateOrEditForm
                    closeForm={() => setEditMode(false)}
                    comment={comment}
                    {...editData}
                    updateState={(payload) => updateState(comment, payload)}
                  />
                </div>
              ) : (
                <span className="my-2 block px-12 text-[1rem]">
                  {<LinkifyText text={comment.text} />}
                </span>
              )}
            </div>
          </div>
          <CommentBadgeList comment={comment} variant="side" />
        </div>
      </div>
    </div>
  );
}
