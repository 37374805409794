/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from "zod";
import { AnomalyValidator } from "../types/api/Anomaly";
import moment from "moment";

export const groupSchema = z.object({
  created_at: z.string().datetime(),
  deleted: z.boolean(),
  display_id: z.number().int(),
  hidden: z.boolean(),
  hidden_overview: z.boolean(),
  name: z.string(),
  operatingModeIds: z.string().array(),
  short_id: z.number().int(),
  shutdownRuleIds: z.string().array(),
  unit: z.string(),
  updated_at: z.string().datetime(),
  variables: z.string().array(),
  _id: z.string(),
});
export type groupSchema = z.infer<typeof groupSchema>;

export const variableSchema = z.object({
  _id: z.string(),
  created_at: z.string().datetime(),
  deleted: z.boolean(),
  description: z.string(),
  expression: z.string().nullish(),
  families: z.string().array(),
  folders: z.string().array(),
  groups: z.string().array(),
  hidden: z.boolean(),
  hide_negative_slopes: z.boolean().optional(),
  hide_positive_slopes: z.boolean().optional(),
  high_side: z.boolean(),
  low_side: z.boolean(),
  name: z.string(),
  nameWithDescription: z.string(),
  om_groups: z.string().array(),
  om_rule_ids: z.string().array(),
  sd_rule_ids: z.string().array(),
  sensor_flatline: z.boolean(),
  short_id: z.number().int(),
  st_enabled: z.boolean().nullish(),
  trimmedName: z.string(),
  type: z
    .enum([
      "Tag",
      "ShutdownRule",
      "FaultTreeNode",
      "OperatingMode",
      "Notification",
    ])
    .optional(),
  unit: z.string(),
  units_of_measurement: z.string().nullish(),
  updated_at: z.string().datetime(),
  watchlist: z.boolean(),
  opc_time: z.string().nullish(),
  ft_node_fault_tree_id: z
    .string()
    .transform((s) => s || null) // I don't know why but the api returns "" instead of null
    .optional(),
  historian: z.string().nullish(),
  inherit_sd: z.boolean(),
});
export type variableSchema = z.infer<typeof variableSchema>;

export const clusterSchema = z.object({
  // todo this is the old cluster, new one in lib/api-schema/cluster.ts
  created_at: z.string().datetime(),
  deleted: z.boolean(),
  description: z.string(),
  name: z.string(),
  short_id: z.number().int(),
  unit: z.string(),
  updated_at: z.string().datetime(),
  variables: z.string().array(),
  _id: z.string(),
});
export type clusterSchema = z.infer<typeof clusterSchema>;

export const anomalySchema = z.object({
  anomalyMilliseconds: z.number().int(),
  date: z.string().datetime(),
  highMilliseconds: z.number().int(),
  level: AnomalyValidator,
  lowMilliseconds: z.number().int(),
  shutdownMilliseconds: z.number().int(),
  unit: z.string(),
  variableId: z.string(),
  _id: z.string(),
});
export type anomalySchema = z.infer<typeof anomalySchema>;

export const commentsSummarySchema = z.object({
  total: z.number().int(),
  variables_counts: z.record(z.string(), z.number().int()),
});
export type commentsSummarySchema = z.infer<typeof commentsSummarySchema>;

export const riskSchema = z.object({
  date: z
    .string()
    .datetime()
    .transform((s) => {
      const datePart = s.split("T")[0];
      if (!datePart) throw new Error("Invalid date string");
      return moment(datePart).toDate().toISOString();
    }),
  groupId: z.string(),
  index: z.number().nullable(), // see comment below
  shutdownMilliseconds: z.number().int(),
  unit: z.string(),
  _id: z.string().nullable(), // i guess the api returns "empty" documents instead of null as a whole when risks don't exist for a group&unit
});

export type riskSchema = z.infer<typeof riskSchema>;
