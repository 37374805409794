import React, { useEffect, useRef } from "react";
import * as d3 from "d3-legacy";
import moment from "moment";
import responsify from "./utils/responsify";
import cache from "../../lib/cache";
import "./BarChart.scss";
import _ from "lodash";
import Color from "color";
import { UTCStringToLocalDateObject } from "../../lib/dateUtil";
import { differenceInDays } from "date-fns";
import { z } from "zod";

const DEFAULT_COLORS = [
  "#7FCDBB",
  "#8856A7",
  "#FFCC66",
  "#D0147E",
  "#253494",
  "#c7e9b4",
];

export default function BarChart({
  withStripes = false,
  xAxisLabel,
  yAxisLabel,
  chartTitle,
  classes,
  chartKeys,
  selectedIndex,
  selectedRange,
  showTitle,
  range,
  data,
  unit,
  barColors,
  hiddenData,
  onclick,
}) {
  const SVGRef = useRef();
  const id = useRef(_.uniqueId("BarChart-"));

  const { selection } = d3;

  // prop validation
  [xAxisLabel, yAxisLabel, chartTitle].forEach((thing) => {
    if (!thing) {
      throw new Error(`\`${thing}\` is required for BarChart.`);
    }
  });

  useEffect(() => {
    if (!withStripes) {
      return;
    }

    // we only need this SVG def if we're using stripes
    const svg = d3.select(SVGRef.current);
    svg.select("defs").remove();
    const defs = svg.append("defs");
    barColors.forEach((color, index, arr) => {
      const isWhite = arr.length - 1 === index;
      const pattern = defs
        .append("pattern")
        .attr("id", `grad${index}`)
        .attr("width", 8)
        .attr("height", 10)
        .attr("patternUnits", "userSpaceOnUse")
        .attr("patternTransform", "rotate(45 50 50)");
      pattern
        .append("rect")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("fill", isWhite ? color : "#555");
      pattern
        .append("line")
        .attr("stroke", color)
        .attr("stroke-width", "9px")
        .attr("y2", 10);
    });
  }, [withStripes, JSON.stringify(barColors)]);

  function draw(
    data,
    chartKeys,
    x,
    y,
    xDomain,
    yDomain,
    xAxis,
    yAxis,
    unit,
    barColors,
    hiddenData,
    withStripes
  ) {
    const chart = d3.select(`#${id.current}-chart`);
    let hover = d3.select(`#${id.current}-chartHover`);

    const plot = chart.select(`.BarChart__plot`);
    const datapoints = plot.selectAll(".datapoint").data(data);

    /* Clear and redraw */
    datapoints.exit().remove();

    const update = (d, i, nodes) => {
      let node = nodes[i];
      let selection = d3.select(node);

      /* Clear old values if they exist. */
      selection.selectAll("*").remove();

      let key = d.key;
      let value = _.isArray(d.value) ? d.value : [d.value];
      let hiddenDataValue = hiddenData
        ? hiddenData.find((d) => d.key === key).value
        : 0;

      /* Handle select mode */

      /* Blank bars look kind of bad. To mitigate this, we detect when a
       * given day has no data, and we add a "filler" tiny bar at the bottom
       * just to make the chart looks nice. It's just a small way to
       * signifiy there's no data and it's not just a rendering bug. */
      let sumValue = _.sum(value);
      let total = sumValue - hiddenDataValue;
      let minSize = 0.005 * yDomain[1]; /* 0.5% of the chart height. */
      if (total < minSize) {
        value = _.clone(value); /* Keep data immutable. */
        value[0] = minSize;
      }

      /* Add 1 to be inclusive of the final day. */
      /* Account for daylight savings time. Moment won't do this for us, because
       * we're not including time zones. There are multiple ways to handle this,
       * but adding an hour is the simplest and least reasonable. `days` aren't
       * inclusive by default, so 23 hours will count as 0 days. By the same
       * metric, 25 hours will count as 1 day. So there's no downside to us just
       * including an extra hour, just in case we need one. */
      let dayCount =
        moment(xDomain[1]).add(1, "hours").diff(xDomain[0], "days") + 1;

      let width = 100 / dayCount;
      let margin = width * 0.04;
      let xStart = x(moment.utc(key).startOf("day").subtract(12, "hours"));

      /* reverse order so coloration will go from dark to light. */
      let runningTotal = 0;

      // we can decide to use stripes on a given day, or not to
      const useStripes = withStripes ? !!d.stripes : false;
      for (let i = 0; i < value.length; i++) {
        let height = 100 - y(value[i]);

        runningTotal += height;
        let yStart = 100 - runningTotal;

        const fill = useStripes
          ? `url(#grad${i})`
          : barColors
            ? barColors[i]
            : DEFAULT_COLORS[i];

        selection
          .append("rect")
          .attr("fill", fill)
          .attr("data-index", i + 1) // todo is this still needed?
          .attr("data-key", chartKeys[i] || "")
          .attr("data-value", value[i])
          .attr("data-conv", y(value[i]))
          .attr("x", `${xStart + margin}%`)
          .attr("y", `${yStart}%`)
          .attr("height", `${height}%`)
          .attr("width", `${width - 2 * margin}%`);
      }

      selection
        .attr("data-xStart", `${xStart + margin}`)
        .attr("data-yStart", `${100 - runningTotal}`)
        .attr("data-height", `${runningTotal}`)
        .attr("data-width", `${width - 2 * margin}`);

      hover
        .selectAll(`.BarChart__hover__entry[data-date="${key.valueOf()}"]`)
        .remove();
      hover
        .append("div")
        .attr("data-date", key.valueOf())
        .attr("class", "BarChart__hover__entry")
        .style("left", `${xStart + margin}%`)
        .style("width", `${width - 2 * margin}%`)
        .style("top", `${0}%`)
        .style("height", `${100}%`)
        .on("click", () => {
          onclick?.(key); // if it exists
        })

        .append("div")
        .attr("class", "BarChart__hover__label")
        .style(
          "bottom",
          `calc(${
            sumValue
              ? runningTotal * ((sumValue - hiddenDataValue) / sumValue)
              : 0
          }% + 1.1rem)`
        )
        .text(() => {
          const day = moment.utc(key).format("MMM D");
          if (_.isFunction(unit)) {
            return `${unit(total)} (${day})`;
          }
          /* Max 2 decimal places */
          const value = `${Math.round(total * 100) / 100}`;
          return unit ? `${value}${unit} (${day})` : `${value} (${day})`;
        });

      // .text(`${Math.round(total * 100) / 100}`); /* max 2 decimal places */
    };

    datapoints
      .enter()
      .append("g")
      .attr("class", "datapoint")
      .attr("data-key", (d) => moment.utc(d.key).startOf("day").valueOf())
      .each(update);

    datapoints.each(update);

    chart.select(`.BarChart__xAxis`).call(xAxis);

    chart.select(`.BarChart__yAxis`).call(yAxis);

    chart.call(
      responsify(
        x,
        y,
        `.BarChart__xAxis`,
        `.BarChart__yAxis`,
        `.BarChart__main`
      )
    );
  }
  const computeAxis = useRef(
    cache(function (data, range) {
      let xDomain = d3.extent(data, (d) => d.key);

      /* If the user has passed in a fixed range, use that instead. */
      let yDomain = range || [
        0,
        (() => {
          let max = _.reduce(
            data,
            (result, datum) => {
              return Math.max(result, _.sum(datum.value));
            },
            1
          );

          /* add 1% to the top of the domain to prevent clipping. */
          return max + max * 0.04;
        })(),
      ];

      const getNumTicks = () => {
        const MAX_TICKS = 7;

        if (
          !z.string().datetime().array().length(2).safeParse(xDomain).success
        ) {
          return MAX_TICKS; // default ??
        }
        const [firstDay, lastDay] = xDomain;
        const asDateFirst = UTCStringToLocalDateObject(firstDay);
        const asDateLast = UTCStringToLocalDateObject(lastDay);

        const diff = differenceInDays(asDateLast, asDateFirst);

        return Math.min(MAX_TICKS, diff + 1);
      };

      let x = d3
        .scaleUtc()
        .domain([
          moment.utc(xDomain[0]).startOf("day").subtract(12, "hours"),
          moment.utc(xDomain[1]).startOf("day").add(12, "hours"),
        ])
        .range([0, 100]);

      let y = d3
        .scaleLinear()
        .domain([yDomain[1], yDomain[0]]) /* ascend, not descend */
        .range([0, 100]);

      const xAxis = d3
        .axisBottom(x)
        .ticks(getNumTicks())
        .tickFormat(function (time) {
          return moment.utc(time).format("MM/DD");
        });

      const yAxis = d3
        .axisRight(y)
        .ticks(Math.min(yDomain[1], 8))
        .tickFormat(d3.format("d"));

      return { x, y, xDomain, yDomain, xAxis, yAxis };
    })
  );

  const { x, y, xDomain, yDomain, xAxis, yAxis } = computeAxis.current(
    data,
    range
  );

  /* Chart rendering/DOM manipulation. */
  function updateChart() {
    draw(
      data,
      chartKeys,
      x,
      y,
      xDomain,
      yDomain,
      xAxis,
      yAxis,
      unit,
      barColors,
      hiddenData,
      withStripes
    );

    /* selected index highlighting */
    if (selectedIndex) {
      let selectedIndex = d3.select(
        `${id.current}-chart .BarChart__selectedIndex`
      );

      if (selectedIndex.empty()) {
        let targetIndex = moment.utc(selectedIndex).startOf("day").valueOf();
        let selection = d3.select(
          `#${id.current}-chart .datapoint[data-key="${targetIndex}"]`
        );
        if (selection.empty()) {
          return;
        }

        selectedIndex = selection
          .append("rect")
          .attr("class", "BarChart__selectedIndex");
      }

      selectedIndex
        .attr("x", `${selection.attr("data-xStart")}%`)
        .attr("y", `${selection.attr("data-yStart")}%`)
        .attr("height", `${selection.attr("data-height")}%`)
        .attr("width", `${selection.attr("data-width")}%`);
    }

    if (selectedRange) {
      let start = selectedRange[0];
      let end = selectedRange[1];

      let startX = x(moment.utc(start).startOf("day").subtract(12, "hours"));
      let endX = x(moment.utc(end).startOf("day").add(12, "hours"));

      let selectedRangeEl = d3.select(
        `#${id.current}-chart .BarChart__selectedRange`
      );
      if (selectedRangeEl.empty()) {
        selectedRangeEl = d3
          .select(`#${id.current}-chart .BarChart__plot`)
          .insert("rect", ".datapoint")
          .attr("class", "BarChart__selectedRange");
      }

      selectedRangeEl
        .attr("x", `${startX}%`)
        .attr("y", "0%")
        .attr("width", `${endX - startX}%`)
        .attr("height", "100%");
    }
  }

  useEffect(() => {
    updateChart();
  });

  /**
   * The overflow-x-clip is important. It can cause your page to have horizontal scrollbars
   * if it's too near the edge.
   *
   * We should really fix the x-axis since that's what overflows, but this will probably be
   * addressed later in a Typescript rewrite.
   */
  return (
    <div className={`BarChart overflow-x-clip ${classes?.BarChart || ""}`}>
      {showTitle && (
        <div className={`BarChart__title ${classes?.BarChart__title || ""}`}>
          {chartTitle}
        </div>
      )}

      <div
        className={`BarChart__chart ${classes?.BarChart__chart || ""} ${
          showTitle ? "BarChart__chart--withTitle" : ""
        }`}
        id={`${id.current}-chart`}
        height="100%"
      >
        <svg
          ref={SVGRef}
          title={chartTitle}
          className={`BarChart__plot ${classes?.BarChart__plot || ""}`}
        ></svg>
        <svg
          title={xAxisLabel}
          className={`BarChart__yAxis ${classes?.BarChart__yAxis || ""}`}
        ></svg>
        <svg
          title={yAxisLabel}
          className={`BarChart__xAxis ${classes?.BarChart__xAxis || ""}`}
        />

        {/* Hover effects */}
        <div id={`${id.current}-chartHover`} className={`BarChart__hover`} />
      </div>
    </div>
  );
}
