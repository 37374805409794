import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";
import { createStore } from "zustand";

function createExpressionTagsDrawerStore() {
  return createStore<{
    tagId: string | undefined; // the original tag the expression tags are for
    setTagId: (tagName: string | undefined) => void;
    tags: string[] | undefined;
    setTags: (tags: string[] | undefined) => void;
  }>((set) => ({
    tagId: undefined,
    setTagId: (tagId) => set({ tagId }),
    tags: undefined,
    setTags: (tags) => set({ tags }),
  }));
}

type ExpressionTagsDrawerStore = ReturnType<
  typeof createExpressionTagsDrawerStore
>;

const ExpressionTagsDrawerStoreContext = createContext<
  ExpressionTagsDrawerStore | undefined
>(undefined);

function ExpressionTagsDrawerStoreProvider({ children }: PropsWithChildren) {
  const [store] = useState(createExpressionTagsDrawerStore);
  return (
    <ExpressionTagsDrawerStoreContext.Provider value={store}>
      {children}
    </ExpressionTagsDrawerStoreContext.Provider>
  );
}

function useExpressionTagsDrawerStoreNotRequired() {
  return useContext(ExpressionTagsDrawerStoreContext);
}

function useExpressionTagsDrawerStore() {
  const store = useContext(ExpressionTagsDrawerStoreContext);

  if (!store) {
    throw new Error(
      "useExpressionTagsStore must be used within a ExpressionTagsStoreProvider"
    );
  }

  return store;
}

export {
  ExpressionTagsDrawerStoreProvider,
  useExpressionTagsDrawerStore,
  useExpressionTagsDrawerStoreNotRequired,
};
