import { useStore } from "zustand";
import { TimeseriesChartTooltipStoreProvider } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../time-series/global-tooltip";
import { useExpressionTagsDrawerStore } from "./use-expression-variables-drawer-store";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../../shared-ui/frontend/drawer";
import { useVariablesArrayQuery } from "../../hooks/tanstack-query";
import { VIEW_MODE, ViewModeSelectors } from "../common/view-mode-selectors";
import { useEffect, useState } from "react";
import { AxisRangeButtonGroupAndLabelToLeft } from "../dateSelector/axis-range-button-group";
import { Button } from "../../shared-ui/frontend/button";
import { X } from "lucide-react";
import { cn } from "../../lib/utils";
import { useToggleFullscreen } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import {
  chartDimensionsConfig,
  DRASecondaryVariableViewNeedsProvider,
  DRASecondaryVariableViewProvider,
} from "../time-series/secondary-variable-view/dra-secondary-variable.view";
import { InitialTrendLine } from "../../shared-ui/time-series-2/svv-store/use-svv-store";
import { Badge } from "../../shared-ui/frontend/badge";

function Internal() {
  const store = useExpressionTagsDrawerStore();
  const { tags, setTags, tagId } = useStore(store);
  const [viewMode, setViewMode] = useState<VIEW_MODE>("grid");
  const [numCols, setNumCols] = useState<number>(0);
  const numCharts = tags?.length || 0;
  useEffect(() => {
    setNumCols(numCharts > 9 ? 4 : numCharts <= 5 ? 2 : 3);
  }, [numCharts]);
  const variableObj = useVariablesArrayQuery().data?.find(
    (x) => x._id === tagId
  );
  const tagName = variableObj?.trimmedName;
  const tagExpression = variableObj?.expression;
  const toggleFullscreen = useToggleFullscreen();
  const chartDims = chartDimensionsConfig(
    viewMode === "grid" ? { isGridView: true, numCols } : { isGridView: false }
  );

  const chart = (vars: string[]) => {
    const { width, height, axesFontScale, lineWidthScale } = chartDims;
    return (
      <DRASecondaryVariableViewProvider
        initialBatchVariables={
          vars.map((vid) => ({
            type: "variable",
            bv: "0".repeat(24) + vid,
          })) as [InitialTrendLine, ...InitialTrendLine[]]
        }
        initialExpanded={false}
      >
        <DRASecondaryVariableViewNeedsProvider
          onLineClick={
            toggleFullscreen &&
            (() =>
              ({ jotaiStore: s }) => {
                toggleFullscreen(s);
              })
          }
          commentsOnlyInFullscreen={false}
          respondToSelectedCommentId
          ableToGetTaller
          width={width}
          height={height}
          axesFontScale={axesFontScale}
          lineWidthScale={lineWidthScale}
          padding={{
            left: 45,
          }}
          expandable
          numTicks={undefined}
          closeAndDisableLimitStatusSeries={false}
        />
      </DRASecondaryVariableViewProvider>
    );
  };

  return (
    <Drawer
      dismissible={false}
      direction="right"
      open={!!tags}
      onOpenChange={(open) => {
        if (!open) {
          setTags(undefined);
        }
      }}
    >
      <DrawerContent className="left-auto right-0 h-[100dvh] w-[90dvw] !select-text overflow-scroll rounded-none bg-zinc-100 p-0">
        <div className="overflow-auto p-6">
          {tags && (
            <>
              <div className="mt-1 flex min-w-max justify-between">
                <div className="max-w-xl truncate text-2xl font-semibold tracking-tight">
                  Referenced Tags for {tagName}
                </div>
                <div>
                  <div className="mr-2 inline-flex">
                    <ViewModeSelectors
                      withLabels
                      enabledModes={["grid", "list", "chart"]}
                      viewMode={viewMode}
                      setViewMode={setViewMode}
                      numCols={numCols}
                      setNumCols={setNumCols}
                    />
                  </div>
                  <AxisRangeButtonGroupAndLabelToLeft />
                  <DrawerClose asChild>
                    <Button size="icon" variant={"ghost"}>
                      <X className="size-4" />
                    </Button>
                  </DrawerClose>
                </div>
              </div>

              {viewMode === "chart" ? (
                // overlay view with original and correlated tags
                <>
                  <code>{tagExpression}</code>
                  <Badge variant="primary">{tags.length} tags</Badge>
                  <div className="py-4">{chart([tagId || "", ...tags])}</div>
                </>
              ) : (
                <>
                  {/* original tag at top */}
                  <div
                    className={cn(
                      "grid gap-4 py-4",
                      viewMode === "grid"
                        ? `grid-cols-${numCols}`
                        : "grid-cols-1"
                    )}
                  >
                    {chart([tagId || ""])}
                  </div>
                  <div className="flex items-center gap-2">
                    <code>{tagExpression}</code>
                    <Badge variant="primary">{tags.length} tags</Badge>
                  </div>

                  <div
                    className={cn(
                      "grid gap-4",
                      viewMode === "grid"
                        ? `grid-cols-${numCols}`
                        : "grid-cols-1"
                    )}
                  >
                    {tags.map((vid) => {
                      return (
                        <div key={vid} className="mt-4">
                          {chart([vid])}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
}

function ExpressionVariablesDrawer() {
  return (
    <TimeseriesChartTooltipStoreProvider>
      {() => (
        <>
          <GlobalTooltip />
          <Internal />
        </>
      )}
    </TimeseriesChartTooltipStoreProvider>
  );
}
export { ExpressionVariablesDrawer };
