import { useId } from "react";
import {
  ANOMALY_LEVELS_DESC,
  AnomalyLevelEnum,
} from "../../../types/api/Anomaly";
import { useGetUseDriStore } from "../hooks/create-use-dri-store";
import { produce } from "immer";
import { BsChevronRight, BsChevronDoubleRight } from "react-icons/bs";
import { Collapse, LoadingOverlay, Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import useSearchParamsEnhanced from "../../boundaries/hooks/useSearchParamsEnhanced";
import { useDateState } from "../../../zustand/useDateState";
import { cn } from "../../../lib/utils";
import {
  useAllAnomaliesForDayQuery,
  useClustersQuery,
  useVariablesMappedByIdQuery,
} from "../../../hooks/tanstack-query";
import { Badge } from "../../ui/badge";
import { useGetUseViewModeStore } from "../../../shared-ui/time-series-2/grid-view-store";
import { useTagsFilterStoreContext } from "../../tags/use-tags-filter-store";
import { useTagsFilter } from "../../tags/use-tags-filter";
import {
  LocalSidebarCollapseButton,
  LocalSidebarContainer,
} from "../../common/sidebar/local-sidebar";
import { useMemo } from "use-memo-one";
import { minutesToMilliseconds } from "date-fns";
import { useSelectedGroup } from "../use-selected-group";
import {
  ClusterView,
  PlainCard,
  sortAnomsFn,
  WatchlistGroup,
} from "./cluster-view-folders";

const DEGREE_SYMBOL_UNICODE = "\u00B0";

export function Sidebar() {
  const useDriStore = useGetUseDriStore();
  // const loading = useDriStore((s) => s.loading);
  const localSidebarOpen = useDriStore((s) => s.sidebarOpen);
  const setSidebarOpen = useDriStore((s) => s.setSidebarOpen);
  const toggleSidebar = () => setSidebarOpen(!localSidebarOpen);

  // filter out variables based on tag filters
  const tagsFilterStore = useTagsFilterStoreContext();
  const tagFilters = tagsFilterStore((s) => s.tagFilters);
  const anomalyTimeFilter = tagsFilterStore((s) => s.anomalyTimeFilter);
  const canShowVariable = useTagsFilter(tagFilters, anomalyTimeFilter);

  const viewMode = useDriStore((s) => s.viewMode);

  return (
    <>
      <LocalSidebarContainer open={localSidebarOpen}>
        <ViewSelector />
        <WatchlistGroup canShowVariable={canShowVariable} />
        {viewMode === "plain" ? (
          <>
            {ANOMALY_LEVELS_DESC.map((anomInt, idx, arr) => {
              return (
                <PlainViewGroup
                  level={anomInt}
                  key={anomInt.toString()}
                  isLast={idx === arr.length - 1}
                  canShowVariable={canShowVariable}
                />
              );
            })}
          </>
        ) : (
          <ClusterView canShowVariable={canShowVariable} />
        )}
      </LocalSidebarContainer>
      <LocalSidebarCollapseButton
        onOpenToggle={toggleSidebar}
        open={localSidebarOpen}
      />
    </>
  );
}

function ViewSelector() {
  const useDriStore = useGetUseDriStore();
  const isPlainView = useDriStore((s) => s.viewMode === "plain");
  const clustersQuery = useClustersQuery();

  return (
    <div className="sticky top-0 z-50 flex items-center justify-between border-b-[3px] border-zinc-600 bg-base-100 py-2 pl-4 pr-2">
      <div className="flex flex-row items-center">
        <h3 className="text-[1.1rem] font-bold">Tags</h3>
      </div>
      {clustersQuery.data && clustersQuery.data.length > 0 && (
        <div className="btn-group text-neutral">
          <Button
            size="xs"
            className={cn(isPlainView ? "btn-neutral" : "btn-outline")}
            onClick={() => useDriStore.getState().setViewMode("plain")}
          >
            Plain View
          </Button>
          <Button
            size="xs"
            className={cn(isPlainView ? "btn-outline" : "btn-neutral")}
            onClick={() => useDriStore.getState().setViewMode("cluster")}
          >
            Cluster View
          </Button>
        </div>
      )}
    </div>
  );
}

function PlainViewGroup({
  level,
  isLast,
  canShowVariable,
}: {
  level: AnomalyLevelEnum;
  isLast?: boolean;
  canShowVariable: (vid: string) => boolean;
}) {
  const variablesMapQuery = useVariablesMappedByIdQuery();
  const variablesMap = variablesMapQuery.data;

  const ds = useDateState();
  const group = useSelectedGroup();

  const all = useAllAnomaliesForDayQuery(ds.axisRangeTo.dateString, {
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(10),
  }).data;

  const anomsForLevel = useMemo((): typeof all => {
    if (!all) return undefined;
    if (!group) return undefined;
    if (!variablesMap) return undefined;

    return all
      .filter(
        (x) => group.variables.includes(x.variableId) && x.level === level
      )
      .sort((a, b) => {
        return sortAnomsFn(variablesMap, a, b);
      });
  }, [all, variablesMap, level, group]);

  const [, setSearchParams] = useSearchParamsEnhanced();
  const useDriStore = useGetUseDriStore();
  const isOpen = useDriStore((s) => s.listOpen[level]);

  const viewMode = useDriStore((s) => s.viewMode);
  const useViewModeStore = useGetUseViewModeStore();
  const setChartViewMode = useViewModeStore((s) => s.setViewMode);
  const active = isOpen;
  const title = `${level}${DEGREE_SYMBOL_UNICODE} Anomaly`;
  const id = useId();

  const handleOpenListClick = () => {
    useDriStore.setState((s) => {
      return {
        listOpen: produce(s.listOpen, (curr) => {
          curr[level] = !curr[level];
        }),
      };
    });
  };

  if (!anomsForLevel) return null;

  const hasData = anomsForLevel.length > 0;

  if (!variablesMap) return null;

  return (
    <div>
      <label
        className={cn(
          "tab-label group flex items-center gap-2 p-2 pl-5 tracking-tight",
          hasData
            ? "cursor-pointer hover:bg-xslate-3 hover:font-medium"
            : "cursor-not-allowed",
          active && "bg-zinc-100 text-indigo-800"
        )}
        // watchlist label receives no color on the bottom
        style={{
          borderBottomWidth: "3px",
          borderBottomColor: `rgb(var(--anom${level})`,
        }}
        htmlFor={id}
        onClick={anomsForLevel.length === 0 ? undefined : handleOpenListClick}
      >
        <div className={cn("transition-transform", isOpen && "rotate-90")}>
          <BsChevronRight />
        </div>
        <span className="transition-all group-hover:ml-1">{title}</span>
        {viewMode === "plain" && (
          <Badge variant={"outline"}>
            {anomsForLevel.filter((x) => canShowVariable(x.variableId)).length}
          </Badge>
        )}
        <Tooltip label="Show All" position="left" withArrow>
          <Button
            disabled={!hasData}
            className="btn-ghost ml-auto text-[18px]"
            icon={BsChevronDoubleRight}
            onClick={(e) => {
              e.stopPropagation(); // prevent the list from expand/collapse

              if (hasData) {
                setSearchParams({
                  v: "",
                  dv: anomsForLevel
                    .map((x) => variablesMap[x.variableId]?.short_id.toString())
                    .filter((x) => x !== undefined),
                });
                useDriStore.setState((s) => {
                  return {
                    showAllMode: true,
                  };
                });
                setChartViewMode("grid");
              }
            }}
          />
        </Tooltip>
      </label>
      <Collapse in={isOpen}>
        <div className={cn("tab-content", isLast && "mb-16")}>
          <div className="flex flex-col bg-white text-[13px]">
            {anomsForLevel.map((a) => {
              return (
                <PlainCard
                  anom={a}
                  canShowVariable={canShowVariable}
                  key={a._id}
                />
              );
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
}
