import moment from "moment-timezone";
import "./VariableInfo.scss";
import {
  useClustersQuery,
  useGroupsQuery,
  useOperatingLimitsQuery,
  useVariablesArrayQuery,
  useVariablesMappedByIdQuery,
} from "../../../hooks/tanstack-query";
import { reverse } from "remeda";
import { ComponentProps, Fragment } from "react";
import React from "react";
import { Info } from "lucide-react";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";
import { Button } from "../../../shared-ui/frontend/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../shared-ui/frontend/dropdown-menu";
import { Badge } from "../../../shared-ui/frontend/badge";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { useClusterDrawerStoreNotRequired } from "../../clusters/cluster-drawer/use-cluster-drawer-store";
import { useGroupDrawerStoreNotRequired } from "../../groups/group-drawer/use-group-drawer-store";
import { useShutdownDrawerStoreNotRequired } from "../../shutdowns/shutdown-drawer/use-shutdown-drawer-store";
import { useModeDrawerStoreNotRequired } from "../../om/drawer/use-om-drawer-store";
import { useExpressionTagsDrawerStoreNotRequired } from "../../pb/use-expression-variables-drawer-store";

type Props = { variableId: string } & ComponentProps<
  typeof DropdownMenuContent
> & {
    icon?: React.ReactNode;
  } & PropsWithCn;

export function VariableInfoTooltip({ className, ...props }: Props) {
  if (!props.variableId)
    throw new Error("should not render without variableId");

  return (
    <Tooltip>
      <TooltipContent>Information</TooltipContent>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              onClick={(e) => e.stopPropagation()}
              variant={"ghost"}
              size={"icon-sm"}
              className={cn("hover:text-xindigo-11", className)}
            >
              <Info className="size-3.5" />
            </Button>
          </TooltipTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <TooltipPopoverContent {...props} />
        </DropdownMenuContent>
      </DropdownMenu>
    </Tooltip>
  );
}

function TooltipPopoverContent(props: Pick<Props, "variableId">) {
  const variablesMapQuery = useVariablesMappedByIdQuery();
  const limitsQuery = useOperatingLimitsQuery(props.variableId);
  const clustersQuery = useClustersQuery();
  const groupsQuery = useGroupsQuery();
  const clusterStore = useClusterDrawerStoreNotRequired();
  const groupStore = useGroupDrawerStoreNotRequired();
  const shutdownStore = useShutdownDrawerStoreNotRequired();
  const modeStore = useModeDrawerStoreNotRequired();

  const myClusters = clustersQuery.data?.filter((x) =>
    x.variables.includes(props.variableId)
  );
  const myGroups = groupsQuery.data
    ?.filter((x) => x.variables.includes(props.variableId))
    .sort((a, b) => a.variables.length - b.variables.length);

  const variable = variablesMapQuery.data?.[props.variableId];

  const variableLimits = limitsQuery.data ?? [];

  if (variablesMapQuery.isLoading || variablesMapQuery.isError) return null;

  // we might have duplicates since we are checking from 2 sources
  const shutdownNamesSet: { _id: string; name: string }[] = [];

  if (variable?.type === "ShutdownRule")
    // itself
    shutdownNamesSet.push({ name: variable.trimmedName, _id: variable._id });

  const addShutdownName = (sdid: string) => {
    const name = variablesMapQuery.data[sdid]?.trimmedName;
    name != null && shutdownNamesSet.push({ name, _id: sdid });
  };

  myGroups?.forEach(({ shutdownRuleIds }) => {
    shutdownRuleIds.forEach(addShutdownName);
  }); // source 1

  variable?.sd_rule_ids.forEach(addShutdownName); // source 2

  const shutdownRules = // filter out duplicates by name
    shutdownNamesSet.filter(
      (x, i, a) => a.findIndex((y) => y.name === x.name) === i
    );

  const operatingModes =
    variable?.om_rule_ids.reduce(
      (arr, id) => {
        const mode = variablesMapQuery.data[id];

        /* Double-bindings fail sometimes, we have to accomodate it. */
        if (!mode) {
          return arr;
        }
        const name = mode.trimmedName;
        const _id = mode._id;
        arr.push({ name, _id });
        return arr;
      },
      [] as { name: string; _id: string }[]
    ) ?? [];

  const limits = variableLimits
    .map((limit) => {
      const level = limit.level.toUpperCase();
      const sortedDates = reverse(limit.data.slice(-3)); /* max 3 values */

      let value;

      if (sortedDates.length === 1) {
        value = `${sortedDates[0]!.value}`;
      } else
        value = sortedDates
          .map(
            (data) =>
              `${data.value} (since ${moment
                .utc(data.start)
                .format("MMM DD, YYYY")})`
          )
          .join(", ");

      return `${level}: ${value}`;
    })
    .filter(Boolean);

  if (!variable) return;
  return (
    <>
      <div className="pl-2 text-sm font-semibold text-black">Variable</div>
      <div className="relative flex flex-row items-center justify-between rounded-sm px-2 py-1 text-sm outline-none transition-colors">
        {variable.trimmedName}
      </div>
      <div className="relative flex flex-row items-center justify-between rounded-sm px-2 py-1 text-sm outline-none transition-colors">
        {variable.description}
      </div>
      {variable.expression && (
        <>
          <div className="mt-3 pl-2 text-sm font-semibold text-black">
            Calculated Expression
          </div>
          <div className="relative flex flex-row items-center justify-between rounded-sm px-2 py-1 text-sm outline-none transition-colors">
            <ExpressionVariablesButton
              tagId={variable._id}
              expression={variable.expression}
            />
          </div>
        </>
      )}
      {myClusters?.length ? (
        <>
          <div className="mt-3 pl-2 text-sm font-semibold text-black">
            Clusters
          </div>
          {myClusters.map((x) => {
            return (
              <DropdownMenuItem
                key={x._id}
                onSelect={() => clusterStore?.getState().setClusterId(x._id)}
                className="flex cursor-pointer flex-row justify-between py-1"
              >
                <div className="line-clamp-1 flex flex-row">
                  {x.type === "dynamic" && (
                    <BsFillLightningChargeFill className="relative top-1 size-3" />
                  )}
                  <span className="max-w-80 truncate text-blue-500 underline">
                    {x.name}
                  </span>
                </div>
                <div className="flex flex-row gap-1">
                  <Badge
                    variant={"secondary"}
                    className="relative ml-2 shrink-0"
                  >
                    {x.variables.length} tags
                  </Badge>
                </div>
              </DropdownMenuItem>
            );
          })}
        </>
      ) : null}
      {myGroups?.length ? (
        <>
          <div className="mt-3 pl-2 text-sm font-semibold text-black">
            Groups
          </div>
          {myGroups.map((x) => {
            return (
              <DropdownMenuItem
                key={x._id}
                onSelect={() => groupStore?.getState().setGroupId(x._id)}
                className="flex cursor-pointer flex-row justify-between py-1"
              >
                <div className="line-clamp-1 flex flex-row">
                  <span className="max-w-80 truncate text-blue-500 underline">
                    {x.name}
                  </span>
                </div>
                <div className="flex flex-row gap-1">
                  <Badge
                    variant={"secondary"}
                    className="relative ml-2 shrink-0"
                  >
                    {x.variables.length} tags
                  </Badge>
                </div>
              </DropdownMenuItem>
            );
          })}
        </>
      ) : null}
      {variable.units_of_measurement && (
        <>
          <div className="mt-3 pl-2 text-sm font-semibold text-black">
            Unit of Measurement
          </div>
          <div className="relative flex flex-row items-center justify-between rounded-sm px-2 py-1 text-sm outline-none transition-colors">
            {variable.units_of_measurement}
          </div>
        </>
      )}
      {shutdownRules.length ? (
        <>
          <div className="mt-3 pl-2 text-sm font-semibold text-black">
            Shutdown Rules
          </div>
          {shutdownRules.map((x) => {
            return (
              <DropdownMenuItem
                key={x._id}
                onSelect={() => shutdownStore?.getState().setShutdownId(x._id)}
                className="flex cursor-pointer flex-row justify-between py-1"
              >
                <div className="line-clamp-1 flex flex-row">
                  <span className="max-w-80 truncate text-blue-500 underline">
                    {x.name}
                  </span>
                </div>
              </DropdownMenuItem>
            );
          })}
        </>
      ) : null}
      {operatingModes.length ? (
        <>
          <div className="mt-3 pl-2 text-sm font-semibold text-black">
            Operating Modes
          </div>
          {operatingModes.map((x) => {
            return (
              <DropdownMenuItem
                key={x._id}
                onSelect={() => modeStore?.getState().setModeId(x._id)}
                className="flex cursor-pointer flex-row justify-between py-1"
              >
                <div className="line-clamp-1 flex flex-row">
                  <span className="max-w-80 truncate">{x.name}</span>
                </div>
              </DropdownMenuItem>
            );
          })}
        </>
      ) : null}
      {limits.length ? (
        <>
          <div className="mt-3 pl-2 text-sm font-semibold text-black">
            Limits (Operating Fitness)
          </div>
          {limits.map((x) => (
            <div
              key={`${variable._id}-limit-${x}`}
              className="relative flex flex-row items-center justify-between rounded-sm px-2 py-1 text-sm outline-none transition-colors"
            >
              {x}
            </div>
          ))}
        </>
      ) : null}
    </>
  );
}

function ExpressionVariablesButton({
  tagId,
  expression,
  className,
}: Pick<ComponentProps<typeof Button>, "className"> & {
  tagId: string;
  expression: string;
}) {
  const variables = useVariablesArrayQuery().data;
  const s = useExpressionTagsDrawerStoreNotRequired();
  if (!s) return expression;
  const expressionVariables = extractQuotedSubstrings(expression)
    .map((x) => variables?.find((v) => v.name === x)?._id)
    .filter((x) => x !== undefined);
  return (
    <span
      onClick={() => {
        s.getState().setTags(expressionVariables);
        s.getState().setTagId(tagId);
      }}
      className={cn("cursor-pointer text-blue-500 underline", className)}
    >
      {expression}
    </span>
  );
}

function extractQuotedSubstrings(input: string): string[] {
  const regex = /"([^"]*)"/g;
  const results: string[] = [];
  let match: RegExpExecArray | null;
  while ((match = regex.exec(input)) !== null) {
    results.push(match[1] || "");
  }
  return results;
}

export { TooltipPopoverContent };
