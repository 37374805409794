import { minutesToMilliseconds } from "date-fns";
import {
  MyFitnessLimit,
  useVariablesArrayQuery,
  useVariablesMappedByIdQuery,
} from "../../../hooks/tanstack-query";
import { MyFitnessLimitType } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { Button } from "../../../shared-ui/frontend/button";
import { Download, Pencil } from "lucide-react";
import {
  BaseHighLowTabs,
  BaseMyFitnessLimitValueInput,
  DeleteMyFitnessLimitButton,
  MyColorPicker,
  MyLimitNotificationToggle,
} from "../../time-series/secondary-variable-view/my-fitness-limits";
import { myFitnessLimitSchema } from "../../../lib/api-schema/my-fitness-limit";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  createMyFitnessLimit,
  updateMyFitnessLimitById,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { cn } from "../../../lib/utils";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shared-ui/frontend/select";
import { Input } from "../../../shared-ui/frontend/input";
import AddonRoute from "../../common/AddonRoute";
import downloadXLSX from "../../charts/utils/downloadAsXLSX";
import { MultiSelect } from "../../../shared-ui/frontend/multi-select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import UpdatedAt from "../../common/manager/UpdatedAt";

function MyLimitsManager() {
  const mfLimitsQuery = MyFitnessLimit.useListQuery({
    staleTime: minutesToMilliseconds(10),
    refetchOnMount: false,
  });
  const variables = useVariablesMappedByIdQuery()?.data;
  const mfl = mfLimitsQuery.data || [];
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const filtered = mfl.filter((l) =>
    filter
      ? variables?.[l.variableId]?.nameWithDescription
          .toLowerCase()
          .includes(filter.toLowerCase())
      : true
  );
  const newestMap: Record<string, number> = {};
  for (const item of filtered) {
    newestMap[item.variableId] = Math.max(
      newestMap[item.variableId] || 0,
      new Date(item.updated_at).getTime()
    );
  }
  const sorted = filtered.sort((a, b) => {
    // group by variable with those with newest limits first then by limit descending
    const aNewest = newestMap[a.variableId] || 0;
    const bNewest = newestMap[b.variableId] || 0;
    if (aNewest > bNewest) return -1;
    if (aNewest < bNewest) return 1;
    const diff = a.variableId.localeCompare(b.variableId);
    if (diff !== 0) return diff;
    return b.value - a.value;
  });

  const [creating, setCreating] = useState(false);

  return (
    <AddonRoute addonKey="operatingLimits">
      <div className="pb-10">
        <div className="mb-2 mr-2 flex flex-row justify-between md:mt-6">
          <div className="flex items-center gap-2">
            <h1 className="text-[2rem] sm:text-[1.75rem]">My Fitness</h1>
            <CreateNewEntityButton onClick={() => setCreating(!creating)} />
          </div>
          <div className="flex items-center">
            <p className="italic text-xslate-9">
              Displaying {mfl.length} limits (
              {
                mfl
                  .map((l) => l.variableId)
                  .filter((v, i, a) => a.indexOf(v) === i).length
              }{" "}
              tags)
            </p>
            <DownloadButton />
          </div>
        </div>
        <div
          className={`overflow-hidden transition-all duration-500 ${
            creating ? "max-h-[130px]" : "max-h-0"
          }`}
        >
          <CreationForm close={() => setCreating(false)} />
        </div>
        <Input
          type="text"
          placeholder="Search by Tag ID or Description"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="h-8 w-80"
        />
        {mfl.length > 0 ? (
          sorted.map((limit, i) => (
            <div>
              {i === 0 || sorted[i - 1]?.variableId !== limit.variableId ? (
                <div className="mt-4 flex items-center gap-1 rounded-t-md border-l border-r border-t border-xslate-4 bg-xslate-1 px-1 py-2 pl-3">
                  <div>
                    {variables?.[limit.variableId]?.nameWithDescription}
                  </div>
                </div>
              ) : null}
              <LimitCard
                limit={limit}
                key={limit._id}
                isLast={
                  i === sorted.length - 1 ||
                  sorted[i + 1]?.variableId !== limit.variableId
                }
              />
            </div>
          ))
        ) : (
          <p className="text-center">You have not created any limits.</p>
        )}
      </div>
    </AddonRoute>
  );
}

function LimitCard({
  limit,
  isLast,
}: {
  limit: myFitnessLimitSchema;
  isLast: boolean;
}) {
  const [editing, setEditing] = useState(false);
  const [color, setColor] = useState(limit.color);
  const [type, setType] = useState(limit.type);
  const [value, setValue] = useState(limit.value.toString());
  const b = useBaseUrlExperimental();
  const refresh = MyFitnessLimit.useInvalidateListAndStatsQuery();

  const mut = useMutation({
    mutationFn: async () => {
      const y = parseFloat(value);
      if (isNaN(y)) throw new Error("Value must be a number");
      return await updateMyFitnessLimitById(b, limit._id, {
        value: y,
        type,
        color,
      });
    },
    onSuccess: ({ _id, color, type, value, variableId }) => {
      setEditing(false);
      refresh(variableId);
      addSuccessToast("Limit updated");
    },
    onError: (e) => addUnknownErrorToast(e),
  });

  if (editing) {
    return (
      <form
        className={cn(
          "flex items-center gap-1 border-l border-r border-t border-xslate-4 bg-xslate-1 p-2 pl-2",
          isLast && "rounded-b-md"
        )}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="flex items-center gap-2">
          <MyColorPicker value={color} onChange={setColor}>
            <div className="inline-flex">
              <button
                className="aspect-square h-6 rounded-md"
                style={{
                  backgroundColor: color,
                }}
              />
            </div>
          </MyColorPicker>
          <BaseHighLowTabs value={type} setValue={setType} />
          <BaseMyFitnessLimitValueInput
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </div>
        <div className="ml-auto flex">
          <Button
            variant="ghost"
            size={"xxs"}
            onClick={() => setEditing(false)}
          >
            Cancel
          </Button>
          <Button className="ml-auto" size={"xxs"} onClick={() => mut.mutate()}>
            Save
          </Button>
        </div>
      </form>
    );
  }

  return (
    <div
      className={cn(
        "flex items-center gap-1 border-l border-r border-t border-xslate-4 bg-xslate-1 px-1 py-2 pl-3",
        isLast && "rounded-b-md border-b"
      )}
    >
      <div
        className="size-3 rounded-full"
        style={{ background: limit.color }}
      ></div>
      <div className="mr-4">{MyFitnessLimitType[limit.type]}</div>
      <div>{Math.round(limit.value * 10000) / 10000}</div>
      <div className="ml-auto mr-2 flex justify-end text-[0.7rem] italic text-zinc-400">
        <UpdatedAt timestamp={limit.updated_at} noContainer />
      </div>
      <Tooltip>
        <TooltipContent>Notify me when the limit is crossed</TooltipContent>
        <TooltipTrigger>
          <MyLimitNotificationToggle
            variableId={limit.variableId}
            type={limit.type}
            value={limit.value}
            size="icon"
          />
        </TooltipTrigger>
      </Tooltip>
      <Tooltip>
        <TooltipContent>Edit</TooltipContent>
        <TooltipTrigger asChild>
          <Button size={"icon"} variant={"ghost"}>
            <Pencil className="size-4" onClick={() => setEditing(true)} />
          </Button>
        </TooltipTrigger>
      </Tooltip>
      <Tooltip>
        <TooltipContent>Delete</TooltipContent>
        <TooltipTrigger>
          <DeleteMyFitnessLimitButton
            limitId={limit._id}
            value={limit.value.toFixed(2)}
            size="icon"
          />
        </TooltipTrigger>
      </Tooltip>
    </div>
  );
}

function CreationForm({ close }: { close: () => void }) {
  const [variable, setVariable] = useState<string | undefined>(undefined);
  const [color, setColor] = useState("#049cdb");
  const [type, setType] = useState(MyFitnessLimitType.High);
  const [value, setValue] = useState("0");
  const b = useBaseUrlExperimental();
  const refresh = MyFitnessLimit.useInvalidateListAndStatsQuery();
  const variables = useVariablesArrayQuery()?.data;

  const mut = useMutation({
    mutationFn: async () => {
      if (variable === undefined) throw new Error("A variable is required");
      const y = parseFloat(value);
      if (isNaN(y)) throw new Error("Value must be a number");

      return await createMyFitnessLimit(b, {
        variableId: variable,
        value: y,
        type,
        color,
      });
    },
    onSuccess: ({ _id, type, value, color, variableId }) => {
      refresh(variableId);
      close();
      addSuccessToast(`Limit of ${value} created`);
      setVariable(undefined);
      setColor("#049cdb");
      setType(MyFitnessLimitType.High);
      setValue("0");
    },
    onError: (err) => addUnknownErrorToast(err),
  });

  return (
    <div className="mb-4 flex flex-col gap-2 rounded-md border border-xslate-5 bg-xslate-1 p-2 px-4">
      <div className="flex flex-row gap-2">
        {/* <Select value={variable} onValueChange={(v) => setVariable(v)}>
          <SelectTrigger className="h-6 inline-flex">
            <SelectValue placeholder="Select a tag" />
          </SelectTrigger>
          <SelectContent className="max-w-lg">
            {variables?.map((v) => (
              <SelectItem key={v._id} value={v._id}>
                {v.nameWithDescription}
              </SelectItem>
            ))}
          </SelectContent>
        </Select> */}
        <MultiSelect
          className="w-full"
          maxMenuHeight={300}
          value={// this API is so bad
          // why not just accept the value directly
          // we have to find the whole {value, label} object
          variables
            ?.filter((x) => variable === x._id)
            .map((x) => ({ value: x._id, label: x.nameWithDescription }))}
          onChange={(v) => setVariable(v.at(-1)?.value)}
          menuPlacement="bottom"
          options={
            variables?.map((v) => ({
              value: v._id,
              label: v.nameWithDescription,
              isFixed: true,
            })) || []
          }
        />
      </div>
      <div className="flex flex-row items-center gap-2">
        <MyColorPicker value={color} onChange={setColor}>
          <div className="inline-flex">
            <button
              className="aspect-square h-6 rounded-md"
              style={{
                backgroundColor: color,
              }}
            />
          </div>
        </MyColorPicker>
        <BaseHighLowTabs value={type} setValue={setType} />
        <div className="max-w-40">
          <BaseMyFitnessLimitValueInput
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <Button variant="ghost" size={"xxs"} onClick={close}>
          Cancel
        </Button>
        <Button size={"xxs"} onClick={() => mut.mutate()}>
          Save
        </Button>
      </div>
    </div>
  );
}

function DownloadButton() {
  const mfLimitsQuery = MyFitnessLimit.useListQuery({
    staleTime: minutesToMilliseconds(10),
    refetchOnMount: false,
  });
  const variables = useVariablesMappedByIdQuery()?.data;
  const mfl = mfLimitsQuery.data || [];

  const download = () => {
    const sorted = mfl.sort((a, b) => {
      // sort by variable then limit descending
      const diff = a.variableId.localeCompare(b.variableId);
      if (diff !== 0) return diff;
      return b.value - a.value;
    });
    const rows = sorted.map((limit) => {
      const variable = variables?.[limit.variableId];
      return {
        TAG_ID: variable?.trimmedName,
        "TAG DESCRIPTION": variable?.description,
        TYPE: MyFitnessLimitType[limit.type],
        VALUE: limit.value,
        COLOR: limit.color,
      };
    });
    downloadXLSX(rows, "my_limits.xlsx");
  };

  return (
    <Button variant={"ghost"} size={"sm"} onClick={download}>
      <Download className="mr-1 h-4 w-4" />
      DOWNLOAD
    </Button>
  );
}

export default MyLimitsManager;
