import React, { useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import useBoolean from "../../common/hooks/useBoolean";
import GroupEditCreateForm from "./group-edit-or-create-form";
import { Collapse } from "@mantine/core";
import { Badge } from "../../ui/badge";
import {
  addSuccessToast,
  addToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import {
  deleteGroup,
  editGroup,
} from "../../../frameworks/fetcher/api-routes-experimental";
import {
  useGroupsQuery,
  useInvalidateGroupsQuery,
  useSectionsQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { cn, iife } from "../../../lib/utils";
import { type PropsWithCn } from "../../../shared-ui/frontend/cn";
import { Group } from "../../../lib/api-schema/group";
import { VariableTypeString } from "../../../types/api/Variable";
import { useMutation } from "@tanstack/react-query";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { Button } from "../../ui/button";
import {
  ArrowRightToLine,
  ChevronRight,
  GripVertical,
  Pencil,
  Trash2,
} from "lucide-react";
import { GenericDeleteTriggerWithConfirmationPopup } from "../../../shared-ui/frontend/generic-delete-confirm";
import { useSortable } from "@dnd-kit/sortable";
import UpdatedAt from "../../common/manager/UpdatedAt";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuCheckboxItem,
} from "../../../shared-ui/frontend/dropdown-menu";
import { Sections } from "../../../lib/api-schema/sections";
import { useEditSectionMutation } from "./section-card";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";
import { useMemo } from "use-memo-one";
import { FaEye, FaEyeSlash } from "react-icons/fa";

/**
 * This component will be wrapped with Draggable stuff from dnd kit
 * if the user is able to edit the groups
 */
export function GroupCard({
  group,
  className,
  renderAsDraggable,
  dragDisabled,
}: {
  group: Group;
  renderAsDraggable?: boolean;
  dragDisabled?: boolean;
} & PropsWithCn) {
  const groupId = group._id;

  const variables = useVariablesArrayQuery().data;
  const hasEditAccess = useHasEditPermission();

  const [isOpen, setOpen] = useState(false);
  const [isEditing, , exitEditing, toggleEditing] = useBoolean(false);

  const tagsInThisGroup = useMemo(
    () =>
      variables?.filter(
        (x) =>
          x.type === VariableTypeString.Tag && group.variables.includes(x._id)
      ),
    [group, variables]
  );

  const canUseDragNDrop = hasEditAccess;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: groupId,
    disabled: iife(() => {
      if (!renderAsDraggable) return true;
      if (!canUseDragNDrop) return true;

      return !!dragDisabled;
    }),
  });

  const editMut = useEditMutation(group._id);

  return (
    <div
      /**
       * Must be done this way because we render it as draggable
       * for the lone groups, but if it's rendered inside a section
       * then it should not be draggable. It will interfere with
       * the draggable sections otherwise
       */
      ref={renderAsDraggable ? setNodeRef : undefined}
      {...(renderAsDraggable ? listeners : undefined)}
      {...(renderAsDraggable ? attributes : undefined)}
      style={
        renderAsDraggable
          ? {
              // see https://github.com/clauderic/dnd-kit/issues/44
              transform: CSS.Translate.toString(transform),
              transition,
            }
          : undefined
      }
      className={cn(
        "mt-6 rounded-lg border border-bordgrey2 pb-0",
        renderAsDraggable &&
          isDragging &&
          "opacity-50 ring-2 ring-purple-500 ring-offset-2",
        className
      )}
    >
      <div className="relative">
        {/* hide this btn for User role  */}
        {renderAsDraggable && hasEditAccess && (
          <GripVertical className="absolute left-0 top-1/2 size-5 -translate-x-[130%] -translate-y-1/2 text-xslate-9" />
        )}
        <div className="min-h-12 flex items-center p-2">
          <div className="flex items-center">
            <Button
              variant={"ghost"}
              size={"icon-md"}
              type="button"
              className={cn("transition-transform", isOpen && "rotate-90")}
              onClick={() => setOpen(!isOpen)}
            >
              <ChevronRight className="size-4" />
            </Button>

            <span
              className={cn(
                "select-none font-semibold",
                !renderAsDraggable && "text-xslate-11"
              )}
            >{`${group.name}`}</span>
            <Badge variant={"amber"} className="ml-3">
              {`${group.variables?.length || 0} tag${
                (group.variables?.length || 0) === 1 ? "" : "s"
              }`}
            </Badge>

            {/* <Badge variant={"aria"} className="ml-3">
              {group.display_id}
            </Badge> */}
          </div>
          {hasEditAccess && (
            <div className="ml-auto inline-flex items-center">
              <CreateSectionWithOrAddToExistingDropdownMenu group={group} />
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={"ghost"}
                    size={"icon"}
                    type="button"
                    onClick={() => {
                      editMut.mutate({
                        hidden_overview: !group.hidden_overview,
                      });
                    }}
                  >
                    {group.hidden_overview ? (
                      <FaEyeSlash className="size-4" />
                    ) : (
                      <FaEye className="size-4" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  {group.hidden_overview
                    ? "Show on Overview Pages"
                    : "Hide from Overview Pages"}
                </TooltipContent>
              </Tooltip>
              {group.name !== "Overall" && (
                <>
                  <DeleteGroupButton group={group} />
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant={"ghost"}
                        size={"icon"}
                        type="button"
                        onClick={() => {
                          setOpen(true);
                          toggleEditing();
                        }}
                      >
                        <Pencil className="size-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>Edit</TooltipContent>
                  </Tooltip>
                </>
              )}
            </div>
          )}
        </div>
        <Collapse in={isOpen}>
          <div className="px-[1rem]">
            {isEditing ? (
              <EditGroupForm
                close={exitEditing}
                group={group}
                className="my-4"
              />
            ) : (
              tagsInThisGroup && (
                <>
                  <div className="mb-3 h-[1px] w-full bg-xslate-4" />
                  <div className="flex flex-wrap gap-1">
                    {tagsInThisGroup.map((x) => {
                      return (
                        <Badge key={x._id} variant={"outline"}>
                          {x.trimmedName}
                        </Badge>
                      );
                    })}
                  </div>
                </>
              )
            )}
          </div>
          {!isEditing && <UpdatedAt timestamp={group.updated_at} />}
        </Collapse>
      </div>
    </div>
  );
}

function CreateSectionWithOrAddToExistingDropdownMenu({
  group,
}: {
  group: Group;
}) {
  const groupsQuery = useGroupsQuery();
  const sections = useSectionsQuery().data;
  const hasEditAccess = useHasEditPermission();

  if (!hasEditAccess) return null;

  const allGroups = groupsQuery.data;
  if (!allGroups) return null;

  // dont show button if no section
  if (!sections || sections.sections.length === 0) return null;

  return (
    <Tooltip>
      <TooltipContent>
        <span>Move to a section</span>
      </TooltipContent>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <TooltipTrigger asChild>
            <Button variant={"ghost"} size={"icon"}>
              <ArrowRightToLine className="size-4" />
            </Button>
          </TooltipTrigger>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <DropdownMenuLabel>Sections</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            {sections.sections.map((s) => {
              return (
                <AddToExistingSectionDropdownMenuButton
                  section={s}
                  group={group}
                  key={s._id}
                />
              );
            })}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </Tooltip>
  );
}

/**
 * Rendered in dropdown menu. Used to quickly add a group
 * into a section with one click. No need to use edit form.
 */
function AddToExistingSectionDropdownMenuButton({
  section,
  group,
}: {
  section: Sections["sections"][number];
  group: Group;
}) {
  const alreadyIncluded = section.groups.includes(group._id);

  const editMut = useEditSectionMutation({
    onSuccess: () => {
      addSuccessToast(`${group.name} added to ${section.name}`);
    },
  });

  const groupsQuery = useGroupsQuery();
  if (!groupsQuery.data) return null;

  return (
    <DropdownMenuCheckboxItem
      checked={alreadyIncluded}
      disabled={editMut.isLoading || alreadyIncluded}
      onSelect={() => {
        if (alreadyIncluded)
          return addUnknownErrorToast(
            `${group.name} is already a part of ${section.name}`
          );

        const current = section.groups.slice().sort((a, b) => {
          const aGroup = groupsQuery.data?.find((x) => x._id === a);
          const bGroup = groupsQuery.data?.find((x) => x._id === b);

          if (!aGroup) return 1;
          if (!bGroup) return -1;

          return aGroup.display_id - bGroup.display_id;
        });

        editMut.mutate({
          groupIds: assertMinLen1(current.concat(group._id)),
          name: section.name,
          sectionId: section._id,
        });
      }}
    >
      <span>{section.name}</span>
    </DropdownMenuCheckboxItem>
  );
}

function EditGroupForm({
  group,
  close,
  className,
}: { group: Group; close: () => void } & PropsWithCn) {
  const editMut = useEditMutation(group._id, close);

  return (
    <GroupEditCreateForm
      className={className}
      onSubmit={(payload) => editMut.mutate(payload)}
      close={close}
      defaults={group} // only used for editing
    />
  );
}

function DeleteGroupButton({ group }: { group: Group }) {
  const [openModal, setOpenModal] = useState(false);
  const refreshGroups = useInvalidateGroupsQuery();
  const b = useBaseUrlExperimental();

  const deleteMut = useMutation({
    mutationFn: () => deleteGroup(b, group._id),
    onSuccess: () => {
      addToast({
        title: `${group.name} deleted`,
        variant: "success",
      });
      refreshGroups();
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
  });

  return (
    <>
      <GenericDeleteTriggerWithConfirmationPopup
        title={`Are you sure you want to delete ${group.name} (${
          group.variables?.length || 0
        } tag${(group.variables?.length || 0) === 1 ? "" : "s"})?`}
        open={openModal}
        onOpenChange={(open) => !open && setOpenModal(false)}
        onConfirm={() => deleteMut.mutate()}
        confirmDisabled={deleteMut.isLoading}
      />
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={"ghost"}
            size={"icon"}
            type="button"
            onClick={() => setOpenModal(true)}
          >
            <Trash2 className="size-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Delete</TooltipContent>
      </Tooltip>
    </>
  );
}

function useEditMutation(id: string, cb?: () => void) {
  const refreshGroups = useInvalidateGroupsQuery();

  const b = useBaseUrlExperimental();
  const editMut = useMutation({
    mutationFn: async (payload: {
      variables?: string[];
      name?: string;
      hidden_overview?: boolean;
    }) => {
      return await editGroup(b, id, payload);
    },
    onSuccess: ({ name }) => {
      addToast({
        title: `${name} saved`,
        variant: "success",
      });
      refreshGroups();
      cb?.();
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
  });
  return editMut;
}
