import { ClusterDriftScore } from "../details/cds";
import { ClusterCharts } from "../details/cluster-charts";
import { ClusterSidebar } from "../details/cluster-sidebar";
import DateSelectorsGroupSelect from "../../dateSelector/DateSelectorsGroupSelect";
import {
  useAriaClustersQuery,
  useGroupsQuery,
} from "../../../hooks/tanstack-query";
import { FullscreenProvider } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import Fullscreen from "../details/fullscreen";
import { TimeseriesChartTooltipStoreProvider } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { ClickedLineControlsNeedsTimeseriesTooltipStoreProvider } from "../../time-series/secondary-variable-view/control-buttons";
import { GlobalTooltip } from "../../time-series/global-tooltip";
import { useSelectedGroupOnAriaPages } from "../../of/details/group-select";
import { clusterSchema } from "../../../lib/api-schema/cluster";
import { useNavigate } from "react-router";
import { LinkWithQuery } from "../../nav/LinkWithQuery2";
import { FaArrowLeft } from "react-icons/fa";
import Button from "../../common/Button/Button";
import useBaseUrl from "../../../zustand/useBaseUrl";
import Nav from "../../nav/Nav";
import DateStateNav from "../../dateSelector/date-state-nav";
import {
  globalGroupsSidebarOpenAtom,
  SelectedGroupContextProvider,
  ToggleGlobalSidebarButton,
} from "../../common/sidebar/generic-global-sidebar-with-groups";
import { GlobalAriaSidebar } from "./global-aria-sidebar";
import { Provider, useAtomValue } from "jotai";
import { useCallback } from "use-memo-one";
import { Group } from "../../../lib/api-schema/group";
import { ExpressionVariablesDrawer } from "../../pb/expression-variables-drawer";
import { ExpressionTagsDrawerStoreProvider } from "../../pb/use-expression-variables-drawer-store";

function GroupSelect() {
  const group = useSelectedGroupOnAriaPages();
  const navigate = useNavigate();

  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data;

  const globalOpen = useAtomValue(globalGroupsSidebarOpenAtom);

  return (
    groups &&
    !globalOpen && (
      <DateSelectorsGroupSelect
        action={(groupId) => {
          navigate(`../${groupId}${window.location.search}`, {
            relative: "path",
          });
        }}
        options={(groups ?? []).map((g) => ({
          value: g._id,
          label: g.name,
        }))}
        value={group?._id}
      />
    )
  );
}

function Aria() {
  useDocumentTitle("ARIA > DRA");

  const group = useSelectedGroupOnAriaPages();
  const selectedClustersFilter = (c: clusterSchema) => {
    return (
      (group === undefined ||
        c.variables.some((vid) => group.variables.includes(vid))) &&
      c.last_run
    );
  };
  const clustersQuery = useAriaClustersQuery({
    filterType: "dynamic",
    enabled: true,
  });
  const clusters = clustersQuery.data || [];
  const selectedClusters = clusters
    .filter(selectedClustersFilter)
    .map((c) => c._id);

  const navigate = useNavigate();
  const baseUrl = useBaseUrl();

  return (
    <Provider>
      <Nav />
      <div
        id="interactible-container"
        className={
          "MIN_H_SCREEN relative duration-300 animate-in slide-in-from-bottom-7"
        }
      >
        <div className="flex">
          <SelectedGroupContextProvider
            selectedGroup={group}
            onSelect={useCallback(
              (g: Group) => {
                navigate(`../${g._id}${window.location.search}`, {
                  relative: "path",
                });
              },
              [navigate]
            )}
          >
            <GlobalAriaSidebar />
          </SelectedGroupContextProvider>

          <div className="grow">
            <DateStateNav
              leftmostChildren={
                <>
                  <LinkWithQuery
                    to={`${baseUrl}/aria`}
                    pick={{ d: true, mo: true, y: true, z: true, cd: true }}
                    relative="path"
                  >
                    <Button icon={FaArrowLeft} className="btn-ghost" />
                  </LinkWithQuery>
                  <ToggleGlobalSidebarButton />
                  <GroupSelect />
                </>
              }
            />
            <ClusterDriftScore
              selectedClusters={selectedClusters}
              className="m-4 h-[25dvh]"
            />
            <div className="flex flex-row gap-4 pr-4">
              <ClusterSidebar selectedClusters={selectedClusters} />
              <ClusterCharts selectedClusters={selectedClusters} />
            </div>
          </div>
        </div>
      </div>
    </Provider>
  );
}

export default function Wrapped() {
  return (
    <FullscreenProvider>
      <ExpressionTagsDrawerStoreProvider>
        <TimeseriesChartTooltipStoreProvider>
          {() => (
            <>
              <ClickedLineControlsNeedsTimeseriesTooltipStoreProvider />
              <GlobalTooltip />
              <Fullscreen />
              <ExpressionVariablesDrawer />
              <Aria />
            </>
          )}
        </TimeseriesChartTooltipStoreProvider>
      </ExpressionTagsDrawerStoreProvider>
    </FullscreenProvider>
  );
}
