import "./DynamicRiskIndex.scss";
import useSearchParamsEnhanced from "../boundaries/hooks/useSearchParamsEnhanced";
import { useGetUseDriStore } from "./hooks/create-use-dri-store";
import * as R from "remeda";
import { useVariablesMappedByIdQuery } from "../../hooks/tanstack-query";
import { useTagsFilterStoreContext } from "../tags/use-tags-filter-store";
import { useSelectedGroup } from "./use-selected-group";
import { useTagsFilter } from "../tags/use-tags-filter";
import { minutesToMilliseconds } from "date-fns";
import { useMemo } from "use-memo-one";

export function useVariablesToDisplay() {
  const useDriStore = useGetUseDriStore();

  const [searchParams] = useSearchParamsEnhanced();

  const variables = useVariablesMappedByIdQuery({
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
  }).data;
  const showAllMode = useDriStore((s) => s.showAllMode);

  const selectedGroup = useSelectedGroup();

  const tagsFilterStore = useTagsFilterStoreContext();
  const tagFilters = tagsFilterStore((s) => s.tagFilters);
  const anomTimes = tagsFilterStore((s) => s.anomalyTimeFilter);

  // use this key and the cache at any point to get data for cluster/plain view if you need it

  const dvString = searchParams.get("dv");
  const vString = searchParams.get("v")?.split("-");
  const vStringFirst = vString?.[0];

  const canShowVariable = useTagsFilter(tagFilters, anomTimes);

  const chartsToDisplay = useMemo(() => {
    if (!variables || !dvString || !selectedGroup) {
      return [];
    }

    const shortIdToVariableId = R.mapToObj(
      Object.values(variables),
      (variable) => {
        return [variable.short_id.toString(), variable._id];
      }
    );

    const [showFirst, rest] = R.partition(
      dvString.split(","),
      (shortIdString) => (showAllMode ? false : vStringFirst === shortIdString)
    );

    const [partOfThisGroup, notPartOfThisGroup] = R.partition(
      rest.map((s) => shortIdToVariableId[s]).filter((x) => x !== undefined),
      (vid) => selectedGroup.variables.includes(vid)
    );

    const sortAndMerge = (arr: string[]) => {
      // TODO: Fix this
      return arr;
    };

    const finalPartOf = sortAndMerge(partOfThisGroup);
    const finalNotPartOf = sortAndMerge(notPartOfThisGroup);

    const out: (string | null)[] = [];
    if (showFirst.length) {
      const firstToShow = shortIdToVariableId[showFirst[0]!];

      if (!firstToShow) {
      } else {
        out.push(firstToShow);
      }
    }

    if (finalPartOf.length) {
      out.push(...finalPartOf);
    }
    if (finalNotPartOf.length) {
      out.push(null, ...finalNotPartOf);
    }

    return out.filter((x) => {
      if (x === null) return true;
      return canShowVariable(x);
    });
  }, [
    variables,
    dvString,
    selectedGroup,
    vStringFirst,
    showAllMode,
    canShowVariable,
  ]);

  return chartsToDisplay;
}
